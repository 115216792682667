import { useCallback, useMemo } from 'react'
import { Container, List, Message } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  createSingleQuarterlyTaxEstimateDetail,
  CREATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY,
  QuarterlyTaxEstimateDetail,
  QuarterlyTaxEstimateDetailStatuses,
  updateSingleQuarterlyTaxEstimateDetail,
  UPDATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY,
} from '../quarterlyTaxEstimateDetails.slice'
import { getIsFetching, getFetchError } from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  Button,
  FormikDateInput,
  FormikInput,
  getFieldName,
  makeDateSchema,
} from '../../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import {
  getAllUsersById,
  getCurrentUser,
} from '../../../../selectors/user.selectors'
import {
  createSearchManager,
  UserRecordSearch,
  UserSearchResult,
} from '../../../../components/Admin/shared/UserRecordSearch'
import { useAppDispatch } from '../../../../utils/typeHelpers'

interface Props {
  details?: QuarterlyTaxEstimateDetail
  onCancel: () => void
}

type EstimatePayload = Omit<
  QuarterlyTaxEstimateDetail,
  'status' | 'createdAt' | 'updatedAt' | 'updatedBy' | 'edgeCaseUserIds'
> & { edgeCaseUserIds?: string }
const currentYear = new Date().getFullYear()
const validationSchema: yup.ObjectSchema<EstimatePayload> = yup.object({
  taxYear: yup
    .string()
    .oneOf([currentYear.toString(), (currentYear + 1).toString()])
    .required('Tax year is required'),
  taxQuarter: yup
    .string()
    .oneOf(['1', '2', '3', '4'])
    .required('Tax quarter 1-4 is required'),
  irsQuarterStartsAt: makeDateSchema({
    field: 'irsQuarterStartsAt',
  }).required(),
  irsQuarterEndsAt: makeDateSchema({ field: 'irsQuarterEndsAt' }).required(),
  irsPaymentDueAt: makeDateSchema({ field: 'irsPaymentDueAt' }).required(),
  newUserCutOffAt: makeDateSchema({ field: 'newUserCutOffAt' }).required(),
  calculationStartsAt: makeDateSchema({
    field: 'calculationStartsAt',
  }).required(),
  clientNotifiedAt: makeDateSchema({ field: 'clientNotifiedAt' }).required(),
  bookkeepingPeriodStartsAt: makeDateSchema({
    field: 'bookkeepingPeriodStartsAt',
  }).required(),
  bookkeepingPeriodEndsAt: makeDateSchema({
    field: 'bookkeepingPeriodEndsAt',
  }).required(),
  bookkeepingPeriodTotalMonths: yup
    .number()
    .min(1)
    .max(12)
    .required('Bookkeeping period total months is required'),
  edgeCaseUserIds: yup
    .string()
    .test(
      'edgeCaseUserIds',
      'Should be array of numbers (ie [1, 2, 3])',
      (val) => {
        // undefined or empty string is allowed
        if (!val) {
          return true
        }

        let parsedObject: unknown

        try {
          parsedObject = JSON.parse(val)
        } catch (e) {
          return false
        }

        return (
          Array.isArray(parsedObject) &&
          parsedObject.every((arrayVal) => typeof arrayVal === 'number')
        )
      }
    ),
})

const QuarterlyTaxEstimateDetailsForm = ({ onCancel, details }: Props) => {
  const dispatch = useAppDispatch()
  const isCreating = useReselector(
    getIsFetching,
    CREATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY
  )

  const isUpdating = useReselector(
    getIsFetching,
    UPDATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY +
      details?.taxQuarter +
      details?.taxYear
  )

  const hasCreateErrored = useReselector(
    getFetchError,
    CREATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY
  )
  const hasUpdateErrored = useReselector(
    getFetchError,
    UPDATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY +
      details?.taxQuarter +
      details?.taxYear
  )
  const currentUser = useReselector(getCurrentUser)
  const allUsers = useReselector(getAllUsersById)

  const DATE_FORMAT = DATE_FORMATS.INPUT

  const initialValues: EstimatePayload = {
    taxYear: details?.taxYear || '',
    taxQuarter: details?.taxQuarter || '',
    irsQuarterStartsAt: details?.irsQuarterStartsAt
      ? moment.utc(details?.irsQuarterStartsAt).format(DATE_FORMAT)
      : '',
    irsQuarterEndsAt: details?.irsQuarterEndsAt
      ? moment.utc(details?.irsQuarterEndsAt).format(DATE_FORMAT)
      : '',
    irsPaymentDueAt: details?.irsPaymentDueAt
      ? moment.utc(details?.irsPaymentDueAt).format(DATE_FORMAT)
      : '',
    newUserCutOffAt: details?.newUserCutOffAt
      ? moment.utc(details?.newUserCutOffAt).format(DATE_FORMAT)
      : '',
    calculationStartsAt: details?.calculationStartsAt
      ? moment.utc(details?.calculationStartsAt).format(DATE_FORMAT)
      : '',
    clientNotifiedAt: details?.clientNotifiedAt
      ? moment.utc(details?.clientNotifiedAt).format(DATE_FORMAT)
      : '',
    bookkeepingPeriodStartsAt: details?.bookkeepingPeriodStartsAt
      ? moment.utc(details?.bookkeepingPeriodStartsAt).format(DATE_FORMAT)
      : '',
    bookkeepingPeriodEndsAt: details?.bookkeepingPeriodEndsAt
      ? moment.utc(details?.bookkeepingPeriodEndsAt).format(DATE_FORMAT)
      : '',
    bookkeepingPeriodTotalMonths: details?.bookkeepingPeriodTotalMonths
      ? details?.bookkeepingPeriodTotalMonths
      : 1,
    edgeCaseUserIds: details?.edgeCaseUserIds
      ? JSON.stringify(details?.edgeCaseUserIds)
      : '',
  }

  const searchManager = useMemo(
    () =>
      createSearchManager({
        bookkeepersArray: [],
        allUsers: Object.values(allUsers),
      }),
    [allUsers]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const edgeCaseUserIds = values['edgeCaseUserIds']
        ? JSON.parse(values['edgeCaseUserIds'].toString())
        : null

      let data: QuarterlyTaxEstimateDetail | undefined

      if (details) {
        data = await updateSingleQuarterlyTaxEstimateDetail(
          details.taxQuarter,
          details.taxYear,
          {
            ...values,
            edgeCaseUserIds,
            updatedAt: new Date().toJSON(),
            updatedBy: currentUser?.id,
          }
        )(dispatch)
      } else {
        data = await createSingleQuarterlyTaxEstimateDetail({
          ...values,
          edgeCaseUserIds,
          status: QuarterlyTaxEstimateDetailStatuses.future,
          updatedAt: new Date().toJSON(),
          updatedBy: currentUser?.id,
        })(dispatch)
      }

      if (data) {
        onCancel()
      }
    },
  })

  const {
    setFieldValue,
    submitForm,
    values: { edgeCaseUserIds },
  } = formik

  const onResultsClicked = useCallback(
    (searchResult: UserSearchResult | null) => {
      if (searchResult) {
        // If there are no values yet, create a new array
        if (edgeCaseUserIds === '') {
          setFieldValue(
            getFieldName<EstimatePayload>('edgeCaseUserIds'),
            `[${[searchResult.id].toString()}]`
          )
        } else {
          // Otherwise parse array and add
          let prevArray: number[] = []
          try {
            prevArray = JSON.parse(edgeCaseUserIds || '')
            setFieldValue(
              getFieldName<EstimatePayload>('edgeCaseUserIds'),
              `[${[...prevArray, searchResult.id]}]`
            )
          } catch (e) {
            // Don't add if we can't parse
            console.log('error parsing edge case user ids')
          }
        }
      }
    },
    [edgeCaseUserIds, setFieldValue]
  )

  const error = hasCreateErrored || hasUpdateErrored
  const buttonsDisabled = !error && (isCreating || isUpdating)

  return (
    <Container>
      <FormikProvider value={formik}>
        <List relaxed="very" divided verticalAlign="middle">
          <List.Item>
            <List.Content floated="right">
              <FormikInput
                name={getFieldName<EstimatePayload>('taxYear')}
                required
                disabled={Boolean(details)}
              />
            </List.Content>
            <List.Header>Tax Year, e.g. 2023</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikInput
                name={getFieldName<EstimatePayload>('taxQuarter')}
                required
                disabled={Boolean(details)}
              />
            </List.Content>
            <List.Header>Tax Quarter, e.g. 1</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <UserRecordSearch
                datasource={searchManager.datasource}
                onResultSelectedCallback={onResultsClicked}
              />
              <FormikInput
                name={getFieldName<EstimatePayload>('edgeCaseUserIds')}
                style={{ width: '425px' }}
              />
            </List.Content>
            <List.Header>Edge case userIds array, e.g. [1, 2, 3]</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>('newUserCutOffAt')}
                required
              />
            </List.Content>
            <List.Header>Heard New User Cut-off Date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>('irsQuarterStartsAt')}
                required
              />
            </List.Content>
            <List.Header>IRS quarter start date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>('irsQuarterEndsAt')}
                required
              />
            </List.Content>
            <List.Header>IRS quarter end date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>('irsPaymentDueAt')}
                required
              />
            </List.Content>
            <List.Header>IRS quarter payment date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>('calculationStartsAt')}
                required
              />
            </List.Content>
            <List.Header>QTE calculation date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>('clientNotifiedAt')}
                required
              />
            </List.Content>
            <List.Header>QTE notification date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>(
                  'bookkeepingPeriodStartsAt'
                )}
                required
              />
            </List.Content>
            <List.Header>Bookkeeping period start date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikDateInput
                dateFormat={DATE_FORMAT}
                name={getFieldName<EstimatePayload>('bookkeepingPeriodEndsAt')}
                required
              />
            </List.Content>
            <List.Header>Bookkeeping period end date</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <FormikInput
                name={getFieldName<EstimatePayload>(
                  'bookkeepingPeriodTotalMonths'
                )}
                required
              />
            </List.Content>
            <List.Header>Bookkeeping period total # months</List.Header>
          </List.Item>
        </List>

        {error && <Message content={error?.message} />}
        <Button
          disabled={buttonsDisabled}
          onClick={onCancel}
          floated="left"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={buttonsDisabled}
          loading={isUpdating || isCreating}
          floated="right"
          onClick={submitForm}
        >
          Save
        </Button>
      </FormikProvider>
    </Container>
  )
}

export default QuarterlyTaxEstimateDetailsForm
