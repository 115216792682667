import React from 'react'
import { Divider, List } from 'semantic-ui-react'

const SummedAmountPopup = ({
  individualsAmount,
  spousesAmount,
  anyOtherAmount,
  anyOtherAmountTitle,
  totalAmount,
  title,
}: {
  individualsAmount: string | React.ReactElement
  spousesAmount: string | React.ReactElement
  anyOtherAmount?: string | React.ReactElement | null
  anyOtherAmountTitle?: string | React.ReactElement | null
  totalAmount: string | React.ReactElement
  title: string
}) => (
  <List>
    <List.Item>
      <List.Content verticalAlign="middle" floated="right">
        {individualsAmount}
      </List.Content>
      <List.Header style={{ fontWeight: 'normal' }} verticalAlign="middle">
        {title}
      </List.Header>
    </List.Item>
    <List.Item>
      <List.Content verticalAlign="middle" floated="right">
        {spousesAmount}
      </List.Content>
      <List.Header style={{ fontWeight: 'normal' }} verticalAlign="middle">
        Spouse&apos;s {title}
      </List.Header>
    </List.Item>
    <Divider />
    {anyOtherAmount && (
      <List.Item>
        <List.Content verticalAlign="middle" floated="right">
          {anyOtherAmount}
        </List.Content>
        <List.Header style={{ fontWeight: 'normal' }} verticalAlign="middle">
          {anyOtherAmountTitle || `Other ${title}`}
        </List.Header>
      </List.Item>
    )}
    <List.Item>
      <List.Content verticalAlign="middle" floated="right">
        <b>{totalAmount}</b>
      </List.Content>
      <List.Header verticalAlign="middle">Total {title}</List.Header>
    </List.Item>
  </List>
)

export default SummedAmountPopup
