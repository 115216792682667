import { useParams } from 'react-router-dom'
import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'
import {
  FormikInput,
  GridRowColumn,
  Text,
  getFieldName,
  makeNumberSchema,
} from '../../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'

export const ownedFarmQuestionIds = [TaxListQuestionId.number_of_farms]

const OwnedFarmPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const questionNumberOfFarms = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.number_of_farms,
    taxYear
  )
  const responseNumberOfFarms = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_farms,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      numberOfFarms: responseNumberOfFarms?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (values.numberOfFarms !== responseNumberOfFarms?.[0]?.value) {
        responseData.push({
          id: responseNumberOfFarms?.[0]?.id,
          value: Number(values.numberOfFarms ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_farms,
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.farm],
      })
    },
  })

  const { values, submitForm, isSubmitting, isValid } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Farm
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg" textAlign="left">
            Your tax preparer will need additional information about your
            farm(s) to determine what deductions you may be eligible for.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikInput
            name={getFieldName<typeof values>('numberOfFarms')}
            label={questionNumberOfFarms.question?.text}
            schema={makeNumberSchema({
              field: 'number of farms',
            })}
            fullWidth
            placeholder="Enter number"
          />
        </GridRowColumn>
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default OwnedFarmPanel
