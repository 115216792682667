import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Container, Icon } from 'semantic-ui-react'

import { Card, Link, Table, Text } from '../../BaseComponents'
import { fetchUserTransactions } from '../../../features/Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../../../features/Reports/reports.slice'
import UserViewOnlyTransactionRow from '../../shared/UserViewOnlyTransactionRow'
import { getTransactionCategorySelector } from '../../../features/Reports/reports.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { selectTransactionsByCategoryAndYear } from '../../../features/Transactions/transactions.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UserTransactionsByCategory = () => {
  const dispatch = useAppDispatch()
  const { transactionCategoryId } = useParams()
  const [searchParams] = useSearchParams()

  const transactions = useReselector(
    selectTransactionsByCategoryAndYear,
    transactionCategoryId,
    searchParams.get('year') || 'all'
  )
  const category = useReselector(
    getTransactionCategorySelector,
    transactionCategoryId
  )

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(fetchUserTransactions())
  }, [dispatch])

  return (
    <Container>
      <Card>
        <Text as="h2">Expenses Transactions for: {category?.name}</Text>
        <Link
          as="bodyMd"
          to="/trends/expenses"
          style={{ margin: '16px 0 8px', width: 'fit-content' }}
        >
          <Icon
            name="chevron left"
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: '0 0 0 4px',
            }}
          />
          <b>Back to Expenses Breakdown</b>
        </Link>
        <Table basic className="userTransactionsTable backgroundWhite">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Date</Table.HeaderCell>
              <Table.HeaderCell width={6}>Description</Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="right">
                Amount
              </Table.HeaderCell>
              <Table.HeaderCell width={6}>Category</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {transactions.map((transaction) => (
              <UserViewOnlyTransactionRow
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Container>
  )
}

export default UserTransactionsByCategory
