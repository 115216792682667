import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import {
  CREATE_SINGLE_STATE_INCOME_TAX_DETAILS_KEY,
  StateIncomeTaxDetailsStates,
  StateIncomeTaxEstimateDetails,
  TaxRateType,
  createSingleStateIncomeTaxDetails,
} from '../stateIncomeTaxDetailsActions'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../../../../utils/dateHelpers'
import moment from 'moment'
import * as yup from 'yup'
import {
  Alert,
  Button,
  FormikCheckbox,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  FormikTextArea,
  GridRowColumn,
  Modal,
  getFieldName,
  makeDateSchema,
  makeNumberSchema,
  makeReqStringSchema,
  Text,
} from '../../../../../../components/BaseComponents'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import {
  hasCorrectFilingStatuses,
  isCorrectStandardDeductionFormat,
} from '../../IncomeTaxDetailsHelpers'
import { selectErrorsForKeys } from '../../../../../../reducers/fetch'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

interface Props {
  modalOpen: boolean
  onClose: (newDetails?: StateIncomeTaxEstimateDetails) => void
}

export const StateIncomeTaxDetailsModal = ({ onClose, modalOpen }: Props) => {
  const dispatch = useAppDispatch()
  const error = useReselector(selectErrorsForKeys, [
    CREATE_SINGLE_STATE_INCOME_TAX_DETAILS_KEY,
  ])[0]
  const [submitting, setSubmitting] = useState(false)

  const currentUser = useReselector(getCurrentUser)

  const formik = useFormik({
    initialValues: {
      effectiveFrom: '',
      current: false,
      state: undefined as StateIncomeTaxDetailsStates | undefined,
      standardDeduction: '',
      taxRateType: undefined as TaxRateType | undefined,
      taxRateFlat: '',
      taxRateBrackets: '',
    },
    onSubmit: async ({
      effectiveFrom,
      current,
      state,
      standardDeduction,
      taxRateType,
      taxRateFlat,
      taxRateBrackets,
    }) => {
      if (!state || !taxRateType || !currentUser) {
        return
      }

      //make sure the JSON parsing works properly
      try {
        setSubmitting(true)
        const data = await createSingleStateIncomeTaxDetails({
          effectiveFrom: moment.utc(effectiveFrom, DATE_FORMATS.INPUT).toDate(),
          current,
          state,
          standardDeduction: standardDeduction
            ? JSON.parse(standardDeduction)
            : undefined,
          taxRateType,
          taxRateFlat: Number(taxRateFlat),
          taxRateBrackets: taxRateBrackets
            ? JSON.parse(taxRateBrackets)
            : undefined,
          updatedBy: currentUser.id,
        })(dispatch)
        setSubmitting(false)
        if (data) {
          onClose(data)
          formik.resetForm()
        }
      } catch (err) {
        console.error(err)
      }
    },
  })

  const { submitForm, values } = formik

  return (
    <FormikProvider value={formik}>
      <Modal open={modalOpen} closeIcon onClose={() => onClose()}>
        <Modal.Header>Create new state income tax details</Modal.Header>
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              {error && <Alert type="error" title={error.message} />}
            </GridRowColumn>
            <GridRowColumn>
              <FormikDropdown
                label="State"
                optionValues={Object.keys(StateIncomeTaxDetailsStates)}
                name={getFieldName<typeof values>('state')}
                schema={makeReqStringSchema()}
                required
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikCheckbox
                label="Use in current QTE calculations"
                name={getFieldName<typeof values>('current')}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikDateInput
                label="Effective From"
                name={getFieldName<typeof values>('effectiveFrom')}
                schema={makeDateSchema()}
                required
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikTextArea
                label={
                  <>
                    <Text as="h3">
                      Standard Deduction(in cents), in JSON format
                    </Text>
                    <Text as="bodyXs">
                      {' '}
                      Example format:
                      <br />{' '}
                      <code>
                        {`{"single": 298000,
                          "marriedFilingJointly": 298000,
                          "marriedFilingSeparately": 298000,
                          "headOfHousehold": 298000,
                          "qualifyingWidow": 298000}`}
                      </code>
                    </Text>
                  </>
                }
                name={getFieldName<typeof values>('standardDeduction')}
                schema={yup
                  .string()
                  .nullable()
                  .test(
                    'standardDeduction check',
                    'Standard Deduction must be in the proper format',
                    (val) => {
                      // undefined or empty string is allowed
                      if (!val) {
                        return true
                      }

                      try {
                        const parsedObject = JSON.parse(val)
                        return isCorrectStandardDeductionFormat(parsedObject)
                      } catch (e) {
                        return false
                      }
                    }
                  )}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikDropdown
                label="Tax Rate Type"
                optionValues={[TaxRateType.flat, TaxRateType.bracket]}
                name={getFieldName<typeof values>('taxRateType')}
                schema={makeReqStringSchema()}
                required
              />
            </GridRowColumn>
            {values.taxRateType === TaxRateType.flat && (
              <GridRowColumn>
                <FormikInput
                  label="Flat Tax Rate"
                  name={getFieldName<typeof values>('taxRateFlat')}
                  componentType="number"
                  schema={makeNumberSchema({
                    maxNumber: 1,
                    allowedDecimals: 4,
                  })}
                  required
                  prefix=""
                  decimalScale={4}
                />
              </GridRowColumn>
            )}
            {values.taxRateType === TaxRateType.bracket && (
              <GridRowColumn>
                <FormikTextArea
                  label={
                    <>
                      <Text as="h3">
                        Tax Rate Brackets(min/max in cents, rate less than 1),
                        in JSON format
                      </Text>
                      <Text as="bodyXs">
                        {' '}
                        Example format:
                        <br />{' '}
                        <code>
                          {
                            '{ "single": [ { "rate": 0.02, "maxInCents": 50000, "minInCents": 0 }, { "rate": 0.04, "maxInCents": 300000, "minInCents": 50000 }, { "rate": 0.05, "minInCents": 300000 } ], "headOfHousehold": [ { "rate": 0.02, "maxInCents": 50000, "minInCents": 0 }, { "rate": 0.04, "maxInCents": 300000, "minInCents": 50000 }, { "rate": 0.05, "minInCents": 300000 } ], "qualifyingWidow": [ { "rate": 0.02, "maxInCents": 50000, "minInCents": 0 }, { "rate": 0.04, "maxInCents": 300000, "minInCents": 50000 }, { "rate": 0.05, "minInCents": 300000 } ], "marriedFilingJointly": [ { "rate": 0.02, "maxInCents": 100000, "minInCents": 0 }, { "rate": 0.04, "maxInCents": 600000, "minInCents": 100000 }, { "rate": 0.05, "minInCents": 600000 } ], "marriedFilingSeparately": [ { "rate": 0.02, "maxInCents": 50000, "minInCents": 0 }, { "rate": 0.04, "maxInCents": 300000, "minInCents": 50000 }, { "rate": 0.05, "minInCents": 300000 } ] }'
                          }
                        </code>
                      </Text>
                    </>
                  }
                  name={getFieldName<typeof values>('taxRateBrackets')}
                  required
                  schema={yup
                    .string()
                    .nullable()
                    .test(
                      'taxBracket check',
                      'Tax brackets must be in proper format',
                      (val) => {
                        // If this field shows up, it must be populated
                        if (!val) {
                          return false
                        }

                        let parsedObject: unknown

                        try {
                          parsedObject = JSON.parse(val)
                          //There are backend validations for the shape, so just do basic
                          //checks around filing statuses here
                          return hasCorrectFilingStatuses(parsedObject)
                        } catch (e) {
                          return false
                        }
                      }
                    )}
                />
              </GridRowColumn>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button disabled={submitting || !formik.isValid} onClick={submitForm}>
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}
