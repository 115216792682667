import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'

import {
  fetchSingleEmployee,
  POST_CREATE_EMPLOYEE_JOB_KEY,
  postCreateEmployeeJob,
  PUT_UPDATE_EMPLOYEE_JOB_KEY,
  PUT_UPDATE_JOB_COMPENSATION_KEY,
  putUpdateEmployeeJob,
  putUpdateJobCompensation,
} from '../payrollActions'
import {
  selectCompensationByEmployeeUuid,
  selectEmployeeByUuid,
  selectJobByEmployeeUuid,
} from '../payroll.selectors'
import { flsaDropdownOptions, useEmployeeWorkLocations } from '../helpers'
import WorkAddressModal from '../Settings/WorkAddressModal'
import PayrollError from '../PayrollError'
import {
  Button,
  makeDateSchema,
  makeNumberSchema,
  makeReqStringSchema,
  Card,
  Text,
  FormikInput,
  FormikDropdown,
  FormikDateInput,
  Link,
  GridRowColumn,
  getFieldName,
} from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { LabelDescription } from '../../../components/BaseComponents/Input'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { EmployeeWithoutJob } from '../employee.slice'
import { FlsaStatus, PaymentUnit } from '../employeeJob.slice'

const VAL_END = 'for this employee'

const JobForm = ({ employee }: { employee: EmployeeWithoutJob }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)

  const { uuid } = employee

  const job = useReselector(selectJobByEmployeeUuid, uuid)
  const compensation = useReselector(selectCompensationByEmployeeUuid, uuid)

  const { locationOptions, setWorkLocation, defaultAddress } =
    useEmployeeWorkLocations(employee.uuid)

  const formik = useFormik({
    initialValues: {
      hire_date: job?.hire_date
        ? moment(job.hire_date, DATE_FORMATS.GUSTO_SUBMIT).format(
            DATE_FORMATS.INPUT
          )
        : '',
      title: job?.title || '',
      rate: job?.compensations[0]?.rate || '0',
      location_uuid: defaultAddress || '',
      flsa_status: compensation?.flsa_status || FlsaStatus.Exempt,
      payment_unit: compensation?.payment_unit || PaymentUnit.Year,
    },
    enableReinitialize: true,
    onSubmit: async ({
      hire_date,
      title,
      rate,
      payment_unit,
      flsa_status,
      location_uuid,
    }) => {
      const setWorkAddressSuccess = await setWorkLocation(location_uuid)

      if (!setWorkAddressSuccess) {
        return
      }

      let jobRes

      if (job?.uuid && job.version) {
        jobRes = await putUpdateEmployeeJob(job.uuid, {
          version: job.version,
          hire_date: moment(hire_date, DATE_FORMATS.INPUT).format(
            DATE_FORMATS.GUSTO_SUBMIT
          ),
          title,
        })(dispatch)
      } else {
        jobRes = await postCreateEmployeeJob(uuid, {
          hire_date: moment(hire_date, DATE_FORMATS.INPUT).format(
            DATE_FORMATS.GUSTO_SUBMIT
          ),
          title,
        })(dispatch)
      }

      const currentComp = jobRes?.compensations.find(
        (comp) => comp.uuid === jobRes?.current_compensation_uuid
      )

      if (!jobRes || !currentComp) {
        return
      }

      const compRes = await putUpdateJobCompensation(currentComp.uuid, {
        rate,
        payment_unit,
        flsa_status,
        version: currentComp.version,
      })(dispatch)

      if (compRes) {
        navigate(`/payroll/employee/${uuid}`)
      }
    },
  })

  const { isSubmitting, submitForm, values } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="h1">Add a new team member</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="h2">Enter Employee Information</Text>
        </GridRowColumn>
        <Divider />
        <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_JOB_KEY} />
        <PayrollError fetchKey={POST_CREATE_EMPLOYEE_JOB_KEY} />
        <PayrollError fetchKey={PUT_UPDATE_JOB_COMPENSATION_KEY} />
        <GridRowColumn width={6}>
          <FormikInput
            label="Job Title"
            required
            fullWidth
            name={getFieldName<typeof values>('title')}
            schema={makeReqStringSchema({ field: 'job title', end: VAL_END })}
          />
        </GridRowColumn>
        <GridRowColumn width={6}>
          <FormikDropdown
            name={getFieldName<typeof values>('flsa_status')}
            label="Employment Type"
            description={
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/7479624872855"
                newPage
              >
                Help me with this
              </Link>
            }
            required
            options={flsaDropdownOptions}
            fullWidth
            schema={makeReqStringSchema({
              field: 'employee type',
              end: VAL_END,
            })}
          />
        </GridRowColumn>
        <GridRowColumn width={6}>
          <FormikDateInput
            name={getFieldName<typeof values>('hire_date')}
            label="Start Date"
            required
            schema={makeDateSchema({ field: 'start date' })}
          />
        </GridRowColumn>
        <GridRowColumn>
          <LabelDescription label="Compensation" required />
        </GridRowColumn>
        <Grid.Row className="short" verticalAlign="middle">
          <Grid.Column width={6}>
            <FormikInput
              name={getFieldName<typeof values>('rate')}
              componentType="currency"
              fullWidth
              schema={makeNumberSchema({
                field: 'rate',
                allowedDecimals: 2,
                end: VAL_END,
              })}
            />
          </Grid.Column>
          <Grid.Column width={1}>
            <Text>Per</Text>
          </Grid.Column>
          <Grid.Column width={5}>
            <FormikDropdown
              name={getFieldName<typeof values>('payment_unit')}
              optionValues={Object.values(PaymentUnit).filter(
                (name) => name !== 'Paycheck'
              )}
              fullWidth
              schema={makeReqStringSchema({
                field: 'payment unit',
                end: VAL_END,
              })}
            />
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn width={12}>
          <FormikDropdown
            name={getFieldName<typeof values>('location_uuid')}
            options={locationOptions}
            label="Work Address"
            required
            description="This is where the employee primarily works."
            fullWidth
          />
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            Location missing?{' '}
            <Button variant="link" onClick={() => setModalOpen(true)}>
              Add work address here
            </Button>
          </Text>
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              variant="secondary"
              onClick={() => navigate(`/payroll/employee/${uuid}`)}
              fullWidth
            >
              Cancel
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button onClick={submitForm} disabled={isSubmitting} fullWidth>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
      {modalOpen && <WorkAddressModal closeModal={() => setModalOpen(false)} />}
    </FormikProvider>
  )
}

const CreateEmployeeJob = () => {
  const dispatch = useAppDispatch()
  const { uuid } = useParams()
  const employee = useReselector(selectEmployeeByUuid, uuid)

  useEffect(() => {
    uuid && fetchSingleEmployee(uuid)(dispatch)
  }, [dispatch, uuid])

  return <Card>{employee && <JobForm employee={employee} />}</Card>
}

export default CreateEmployeeJob
