import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { orderBy } from 'lodash'

import {
  useLoadedFlagValue,
  useReselector,
  useTimeoutRef,
} from '../../utils/sharedHooks'
import {
  FETCH_CONVERSATIONS_KEY,
  FETCH_CONVERSATIONS_PAGE_KEY,
  fetchConversationPage,
  fetchConversations,
  fetchTicketTopics,
} from './actions'
import {
  clearTickets,
  selectArchivedTickets,
  selectInProgressTickets,
  selectTicketTopics,
  selectYourTurnTickets,
  Ticket,
  TicketReviewStatus,
} from './conversation.slice'
import {
  Card,
  Dropdown,
  Label,
  Loader,
  Tab,
  Text,
  Icon,
  Button,
  GridRowColumn,
  Alert,
} from '../../components/BaseComponents'
import ConversationListItem from './ConversationListItem'
import { getFetchError, getIsFetching } from '../../reducers/fetch'
import {
  DeviceWidth,
  useDeviceWidth,
  useIsDeviceWidth,
} from '../../utils/deviceWidthHelpers'
import { Accordion, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../utils/typeHelpers'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'

const sortFilterTickets = ({
  tickets,
  sort,
  topicFilter,
  statusFilter,
}: {
  tickets: Ticket[]
  sort?: 'Newest' | 'Oldest'
  topicFilter?: string
  statusFilter?: TicketReviewStatus
}) =>
  orderBy(
    tickets.filter(
      (ticket) =>
        (!topicFilter || ticket.topic === topicFilter) &&
        (!statusFilter || ticket.reviewStatus === statusFilter)
    ),
    'updatedAt',
    sort === 'Oldest' ? 'asc' : 'desc'
  )

const TicketPanel = ({
  tickets,
  emptyMessage,
  filtersApplied,
  loadPage,
  nextPage,
}: {
  tickets: Ticket[]
  emptyMessage: string
  filtersApplied: boolean
  loadPage: (fetchPage?: string | null) => void
  nextPage?: string | null
}) => {
  const [loadButtonDisabled, setLoadButtonDisabled] = useState(false)
  const timeoutRef = useTimeoutRef()
  const isLoadingNonPaginated = useReselector(
    getIsFetching,
    FETCH_CONVERSATIONS_KEY
  )
  const isLoadingPaginated = useReselector(
    getIsFetching,
    FETCH_CONVERSATIONS_PAGE_KEY
  )

  const loadingError = useReselector(
    getFetchError,
    FETCH_CONVERSATIONS_PAGE_KEY
  )

  useEffect(() => {
    // if rate limit is hit, disable load button
    if (loadingError?.statusCode === 429) {
      setLoadButtonDisabled(true)
      // re-enable after 1 minute
      timeoutRef.current = setTimeout(() => {
        setLoadButtonDisabled(false)
      }, 60000)
    }
  }, [loadingError, timeoutRef])

  if (isLoadingNonPaginated || (!tickets.length && isLoadingPaginated)) {
    return <Loader loading />
  }

  if (!tickets.length) {
    return loadingError?.message ? (
      <Alert type="error">{loadingError?.message}</Alert>
    ) : (
      <Card backgroundColor="stone40">
        <Text as="bodyLg" textAlign="center">
          {filtersApplied
            ? 'You have no conversations that match your chosen filter(s).'
            : emptyMessage}
        </Text>
      </Card>
    )
  }

  return (
    <>
      {tickets.map((ticket) => (
        <ConversationListItem key={ticket.id} ticket={ticket} />
      ))}
      {nextPage && (
        <Grid>
          {!isLoadingPaginated && loadingError?.message && (
            <GridRowColumn>
              <Alert type="error">{loadingError?.message}</Alert>
            </GridRowColumn>
          )}
          <GridRowColumn
            textAlign="center"
            columnStyle={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              disabled={loadButtonDisabled || isLoadingPaginated}
              loading={isLoadingPaginated}
              onClick={() => loadPage(nextPage)}
            >
              Load More
            </Button>
          </GridRowColumn>
        </Grid>
      )}
    </>
  )
}

const FilterAccordionList = ({ content }: { content: ReactNode }) => {
  const [active, setActive] = useState(false)
  return (
    <Accordion fluid style={{ marginLeft: 10 }}>
      <Accordion.Title
        active={active}
        index={0}
        onClick={() => setActive(!active)}
      >
        <Text as="link">
          Filters{' '}
          <Icon
            size="sm"
            color="green"
            icon={active ? regular('angle-up') : regular('angle-down')}
          />
        </Text>
      </Accordion.Title>

      <Accordion.Content active={active}>{content}</Accordion.Content>
    </Accordion>
  )
}

const ConversationsList = () => {
  const dispatch = useAppDispatch()
  const inProgressTickets = useReselector(selectInProgressTickets)
  const yourTurnTickets = useReselector(selectYourTurnTickets)
  const archivedTickets = useReselector(selectArchivedTickets)
  const ticketTopics = useReselector(selectTicketTopics)

  const [sort, setSort] = useState<'Newest' | 'Oldest'>()
  const [topicFilter, setTopicFilter] = useState<string>()
  const [statusFilter, setStatusFilter] = useState<TicketReviewStatus>()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const deviceWidth = useDeviceWidth()
  const loadPaginated = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.usePaginatedConversations,
    false
  )
  const [nextPage, setNextPage] = useState<string | null>(null)

  const filteredInProgressTickets = useMemo(
    () =>
      sortFilterTickets({
        tickets: inProgressTickets,
        sort,
        statusFilter,
        topicFilter,
      }),
    [inProgressTickets, sort, statusFilter, topicFilter]
  )

  const filteredArchivedTickets = useMemo(
    () =>
      sortFilterTickets({
        tickets: archivedTickets,
        sort,
        statusFilter,
        topicFilter,
      }),
    [archivedTickets, sort, statusFilter, topicFilter]
  )

  const loadPage = useCallback(
    async (fetchPage?: string | null) => {
      const response = await dispatch(
        fetchConversationPage({ nextPage: fetchPage })
      )
      if (response?.nextPage) {
        setNextPage(response.nextPage)
        // If all fetched tickets were filtered out of the return on BE, auto fetch more
        if (response.tickets?.length === 0) {
          loadPage(response.nextPage)
        }
      }
      if (response?.nextPage === null) {
        setNextPage(null)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (loadPaginated !== null) {
      if (loadPaginated) {
        dispatch(clearTickets())
        loadPage()
      } else {
        dispatch(fetchConversations())
      }
    }

    dispatch(fetchTicketTopics())
  }, [dispatch, loadPaginated, loadPage])

  const ticketTopicOptions = useMemo(
    () => [
      { text: 'Topic', value: undefined },
      ...ticketTopics.map(({ name, identifier }) => ({
        text: `Topic: ${name}`,
        value: identifier,
      })),
    ],
    [ticketTopics]
  )

  const panes = useMemo(
    () => [
      {
        menuItem: 'In Progress',
        ornament: Boolean(yourTurnTickets.length) && (
          <Label color="darkGreen" $circular>
            {yourTurnTickets.length}
          </Label>
        ),
        render: () => (
          <TicketPanel
            tickets={filteredInProgressTickets}
            emptyMessage="You have no in-progress conversations."
            filtersApplied={Boolean(topicFilter || statusFilter)}
            loadPage={loadPage}
            nextPage={nextPage}
          />
        ),
      },
      {
        menuItem: isMobile ? 'Archived' : 'Archived Conversations',
        render: () => (
          <TicketPanel
            tickets={filteredArchivedTickets}
            emptyMessage="You have no archived conversations."
            filtersApplied={Boolean(topicFilter || statusFilter)}
            loadPage={loadPage}
            nextPage={nextPage}
          />
        ),
      },
    ],
    [
      filteredArchivedTickets,
      filteredInProgressTickets,
      isMobile,
      statusFilter,
      topicFilter,
      yourTurnTickets.length,
      nextPage,
      loadPage,
    ]
  )

  const headerStyle = useMemo(() => {
    const baseStyle = { display: 'flex', justifyContent: 'space-around' }
    switch (deviceWidth) {
      case DeviceWidth.tablet:
        return { ...baseStyle, maxWidth: '70%' }
      case DeviceWidth.mobile:
        return { display: 'flex', flexDirection: 'column' as const, gap: 17 }
      default:
        return { ...baseStyle, maxWidth: 500 }
    }
  }, [deviceWidth])

  const dropdowns = useMemo(
    () => (
      <div style={headerStyle}>
        <Dropdown
          variant="text"
          options={[
            { text: 'Date', value: undefined },
            { text: 'Date: Newest', value: 'Newest' },
            { text: 'Date: Oldest', value: 'Oldest' },
          ]}
          value={sort}
          onChange={setSort}
          placeholder="Date"
        />
        <Dropdown
          variant="text"
          options={ticketTopicOptions}
          value={topicFilter}
          onChange={setTopicFilter}
          placeholder="Topic"
        />
        <Dropdown
          variant="text"
          options={[
            { text: 'Status', value: undefined },
            {
              text: 'Status: In Review',
              value: TicketReviewStatus.inReview,
            },
            {
              text: 'Status: Your Turn',
              value: TicketReviewStatus.yourTurn,
            },
          ]}
          value={statusFilter}
          onChange={setStatusFilter}
          placeholder="Status"
        />
      </div>
    ),
    [headerStyle, sort, statusFilter, ticketTopicOptions, topicFilter]
  )

  return (
    <Tab
      panes={panes}
      header={
        isMobile ? <FilterAccordionList content={dropdowns} /> : dropdowns
      }
    />
  )
}

export default ConversationsList
