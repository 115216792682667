import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import {
  FederalIncomeTaxEstimateDetails,
  createSingleFederalIncomeTaxDetails,
} from '../federalIncomeTaxDetailsActions'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../../../../utils/dateHelpers'
import moment from 'moment'
import * as yup from 'yup'
import {
  Button,
  FormikCheckbox,
  FormikDateInput,
  FormikInput,
  FormikTextArea,
  GridRowColumn,
  Modal,
  getFieldName,
  makeDateSchema,
  makeNumberSchema,
} from '../../../../../../components/BaseComponents'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import {
  hasCorrectFilingStatuses,
  isCorrectStandardDeductionFormat,
} from '../../IncomeTaxDetailsHelpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

interface Props {
  modalOpen: boolean
  onClose: (newDetails?: FederalIncomeTaxEstimateDetails) => void
}

export const FederalIncomeTaxDetailsModal = ({ onClose, modalOpen }: Props) => {
  const dispatch = useAppDispatch()

  const currentUser = useReselector(getCurrentUser)

  const formik = useFormik({
    initialValues: {
      effectiveFrom: '',
      current: false,
      standardDeduction: '',
      dependentDeductionInCents: '',
      deductibleSelfEmploymentRate: '',
      qbiDeductionRate: '',
      qbiDeductionIncomeLimitInCents: '',
      selfEmploymentReductionRate: '',
      socialSecurityTaxMaxIncomeInCents: '',
      socialSecurityTaxRate: '',
      medicareTaxRate: '',
      taxRateBrackets: '',
    },
    onSubmit: async ({
      effectiveFrom,
      current,
      standardDeduction,
      dependentDeductionInCents,
      deductibleSelfEmploymentRate,
      qbiDeductionRate,
      qbiDeductionIncomeLimitInCents,
      selfEmploymentReductionRate,
      socialSecurityTaxMaxIncomeInCents,
      socialSecurityTaxRate,
      medicareTaxRate,
      taxRateBrackets,
    }) => {
      if (
        !standardDeduction ||
        !deductibleSelfEmploymentRate ||
        !deductibleSelfEmploymentRate ||
        !qbiDeductionRate ||
        !qbiDeductionIncomeLimitInCents ||
        !selfEmploymentReductionRate ||
        !socialSecurityTaxMaxIncomeInCents ||
        !socialSecurityTaxRate ||
        !medicareTaxRate ||
        !taxRateBrackets ||
        !currentUser
      ) {
        return
      }

      //make sure the JSON parsing works properly
      try {
        const data = await createSingleFederalIncomeTaxDetails({
          effectiveFrom: moment.utc(effectiveFrom, DATE_FORMATS.INPUT).toDate(),
          current,
          standardDeduction: standardDeduction
            ? JSON.parse(standardDeduction)
            : undefined,
          dependentDeductionInCents: Number(dependentDeductionInCents),
          deductibleSelfEmploymentRate: Number(deductibleSelfEmploymentRate),
          qbiDeductionRate: Number(qbiDeductionRate),
          qbiDeductionIncomeLimitInCents: Number(
            qbiDeductionIncomeLimitInCents
          ),
          selfEmploymentReductionRate: Number(selfEmploymentReductionRate),
          socialSecurityTaxMaxIncomeInCents: Number(
            socialSecurityTaxMaxIncomeInCents
          ),
          socialSecurityTaxRate: Number(socialSecurityTaxRate),
          medicareTaxRate: Number(medicareTaxRate),
          taxRateBrackets: taxRateBrackets
            ? JSON.parse(taxRateBrackets)
            : undefined,
          updatedBy: currentUser.id,
        })(dispatch)

        if (data) {
          onClose(data)
        }
      } catch (err) {
        console.error(err)
      }
    },
  })

  const { submitForm, values } = formik

  return (
    <FormikProvider value={formik}>
      <Modal open={modalOpen} closeIcon onClose={() => onClose()}>
        <Modal.Header>Create new federal income tax details</Modal.Header>
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              <FormikCheckbox
                label="Use in current QTE calculations"
                name={getFieldName<typeof values>('current')}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikDateInput
                label="Effective From"
                name={getFieldName<typeof values>('effectiveFrom')}
                schema={makeDateSchema()}
                required
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikTextArea
                label="Standard Deduction"
                name={getFieldName<typeof values>('standardDeduction')}
                required
                schema={yup
                  .string()
                  .nullable()
                  .test(
                    'standardDeduction check',
                    'Standard Deduction must be in the proper format',
                    (val) => {
                      // undefined or empty string is not allowed
                      if (!val) {
                        return false
                      }

                      try {
                        const parsedObject = JSON.parse(val)
                        return isCorrectStandardDeductionFormat(parsedObject)
                      } catch (e) {
                        return false
                      }
                    }
                  )}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="Dependent Deduction in Cents"
                name={getFieldName<typeof values>('dependentDeductionInCents')}
                componentType="number"
                schema={makeNumberSchema({ allowedDecimals: 0 })}
                prefix=""
                decimalScale={0}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="Deductible Self Employment Rate"
                name={getFieldName<typeof values>(
                  'deductibleSelfEmploymentRate'
                )}
                componentType="number"
                schema={makeNumberSchema({
                  maxNumber: 1,
                  allowedDecimals: 4,
                })}
                required
                prefix=""
                decimalScale={4}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="QBI Deduction Income Limit in Cents"
                name={getFieldName<typeof values>(
                  'qbiDeductionIncomeLimitInCents'
                )}
                componentType="number"
                schema={makeNumberSchema({ allowedDecimals: 0 })}
                required
                prefix=""
                decimalScale={0}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="QBI Deduction Rate"
                name={getFieldName<typeof values>('qbiDeductionRate')}
                componentType="number"
                schema={makeNumberSchema({
                  maxNumber: 1,
                  allowedDecimals: 4,
                })}
                required
                prefix=""
                decimalScale={4}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="Self Employment Reduction Rate"
                name={getFieldName<typeof values>(
                  'selfEmploymentReductionRate'
                )}
                componentType="number"
                schema={makeNumberSchema({
                  maxNumber: 1,
                  allowedDecimals: 4,
                })}
                required
                prefix=""
                decimalScale={4}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="Social Security Tax Max Income in Cents"
                name={getFieldName<typeof values>(
                  'socialSecurityTaxMaxIncomeInCents'
                )}
                componentType="number"
                schema={makeNumberSchema({ allowedDecimals: 0 })}
                required
                prefix=""
                decimalScale={0}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="Social Security Tax Rate"
                name={getFieldName<typeof values>('socialSecurityTaxRate')}
                componentType="number"
                schema={makeNumberSchema({
                  maxNumber: 1,
                  allowedDecimals: 4,
                })}
                required
                prefix=""
                decimalScale={4}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                label="Medicare Tax Rate"
                name={getFieldName<typeof values>('medicareTaxRate')}
                componentType="number"
                schema={makeNumberSchema({
                  maxNumber: 1,
                  allowedDecimals: 4,
                })}
                required
                prefix=""
                decimalScale={4}
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikTextArea
                label="Tax Rate Brackets"
                name={getFieldName<typeof values>('taxRateBrackets')}
                required
                schema={yup
                  .string()
                  .nullable()
                  .test(
                    'taxBracket check',
                    'Tax brackets must be in proper format',
                    (val) => {
                      // If this field shows up, it must be populated
                      if (!val) {
                        return false
                      }

                      let parsedObject: unknown

                      try {
                        parsedObject = JSON.parse(val)
                        //There are backend validations for the shape, so just do basic
                        //checks around filing statuses here
                        return hasCorrectFilingStatuses(parsedObject)
                      } catch (e) {
                        return false
                      }
                    }
                  )}
              />
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button onClick={submitForm}>Submit</Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}
