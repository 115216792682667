import { Accordion } from '../components/BaseComponents'

export const AccordionDefault = () => (
  <>
    <Accordion
      title="First one"
      content="Here is some content"
      variant="default"
    />
    <Accordion
      title="second one"
      content="Here is more content"
      variant="default"
    />
    <Accordion
      title="third one"
      content="Here is even more content"
      variant="default"
    />
  </>
)

export const AccordionOld = () => (
  <Accordion
    title="Here is an old accordion"
    content="Here is some content"
    variant="old"
  />
)

export const AccordionText = () => (
  <Accordion
    title="Here is an text accordion"
    content="Here is some content"
    variant="text"
  />
)
