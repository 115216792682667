import { Button as SemanticButton, StrictButtonProps } from 'semantic-ui-react'
import styled from 'styled-components'
import { omit } from 'lodash'

import { Colors, Fonts, FontWeight } from '../../styles/theme'
import { CSSProperties } from 'react'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondaryWhite'
  | 'toggle'
  | 'link'
  | 'secondaryLink'
  | 'actionLink'
  | 'selector'
  | 'tooltipLink'
  | 'warning'

export interface ButtonProps
  extends Omit<
    StrictButtonProps,
    'fluid' | 'color' | 'inverted' | 'primary' | 'secondary' | 'basic'
  > {
  color?: keyof typeof Colors
  dark?: boolean
  fullWidth?: boolean
  id?: string
  inline?: boolean
  rounded?: boolean
  size?: 'large' | 'medium' | 'small'
  style?: CSSProperties
  variant?: ButtonVariant

  // For toggle and selector buttons only
  active?: boolean
}

const primaryStyles = (dark: boolean) => ({
  backgroundColor: dark ? Colors.white : Colors.green,
  color: dark ? Colors.black : Colors.white,
  borderColor: Colors.transparent,
})

const secondaryStyles = (dark: boolean, whiteBackground = false) => ({
  backgroundColor: whiteBackground ? Colors.white : Colors.transparent,
  color: dark ? Colors.white : Colors.black,
  borderColor: dark ? Colors.white : Colors.black,
})

const primaryHoverStyles = (_dark: boolean) => ({
  backgroundColor: Colors.green,
  color: Colors.white,
  borderColor: Colors.white,
  svg: {
    color: Colors.white,
  },
})

const secondaryHoverStyles = (dark: boolean) => ({
  backgroundColor: dark ? Colors.white : Colors.green,
  color: dark ? Colors.black : Colors.white,
  borderColor: Colors.transparent,
  svg: {
    color: dark ? Colors.black : Colors.white,
  },
})

const warningStyles = {
  backgroundColor: Colors.red,
  color: Colors.white,
  borderColor: Colors.transparent,
}

const warningHoverStyles = secondaryStyles(false)

const basicSelectorStyles = {
  backgroundColor: 'transparent',
  color: Colors.black,
  borderColor: Colors.gray,
}

const Button = styled((props: ButtonProps) => (
  <SemanticButton
    {...omit(props, 'dark', 'fullWidth', 'inline', 'color', 'rounded')}
  />
))(
  ({
    color,
    dark = false,
    variant = 'primary',
    size = 'large',
    fullWidth,
    disabled,
    active,
    inline = false,
    rounded = false,
  }) => ({
    '&&&': {
      alignItems: 'center',
      borderRadius: rounded ? '24px !important' : '8px !important',
      color: color && Colors[color],
      display: inline ? 'inline-flex' : 'flex',
      height: size === 'large' ? 48 : 40,
      justifyContent: 'center',
      width: fullWidth ? '100%' : 'initial',
      borderWidth: 1,
      borderStyle: 'solid',
      opacity: '1!important',
      margin: 0,

      ...(size === 'large' && Fonts.buttonLg),
      ...(size === 'medium' && Fonts.buttonMd),
      ...(size === 'small' && Fonts.buttonSm),

      ...(variant === 'primary' && {
        ...primaryStyles(dark),
        '&:hover': primaryHoverStyles(dark),
        ...(disabled && {
          color: dark ? Colors.lightGray : Colors.mediumGray,
          backgroundColor: dark ? Colors.mediumGray : Colors.lightGray,
        }),
      }),

      ...(['secondary', 'secondaryWhite'].includes(variant) && {
        ...secondaryStyles(dark, variant === 'secondaryWhite'),
        '&:hover': secondaryHoverStyles(dark),
        ...(disabled && {
          borderColor: Colors.mediumGray,
          color: Colors.mediumGray,
          backgroundColor: Colors.transparent,
        }),
      }),

      ...(variant === 'toggle' && {
        backgroundColor: active ? Colors.lightGreen : Colors.white,
        borderColor: active ? Colors.green : Colors.gray,
        borderWidth: 1,
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        color: active ? Colors.green : Colors.black,
        svg: {
          color: active ? Colors.green : Colors.oak,
        },
      }),

      ...(variant === 'link' && {
        ...Fonts.link,
        '&:hover': {
          color: Colors.black,
        },
        display: 'inline-flex!important',
      }),

      ...(variant === 'secondaryLink' && {
        ...Fonts.secondaryLink,
        '&:hover': {
          textDecorationLine: 'inherit',
        },
      }),

      ...(variant === 'actionLink' && {
        ...(size === 'large' ? Fonts.actionLink : Fonts.actionLinkMd),
        '&:hover': {
          textDecorationLine: 'underline',
        },
      }),

      ...(['link', 'secondaryLink', 'actionLink', 'tooltipLink'].includes(
        variant
      ) && {
        backgroundColor: Colors.transparent,
        padding: 0,
        margin: 0,
        minHeight: 0,
        height: 'inherit',
        borderWidth: 0,
        ...(disabled && {
          color: Colors.mediumGray,
        }),
      }),

      ...(variant === 'selector' && {
        ...basicSelectorStyles,
        ...(active && primaryStyles(dark)),
        '&:hover': {
          ...primaryHoverStyles(dark),
        },
      }),

      ...(variant === 'tooltipLink' && {
        ...basicSelectorStyles,
        ...(active && primaryStyles(dark)),
        ...(disabled && {
          borderColor: Colors.mediumGray,
          color: Colors.mediumGray,
          backgroundColor: Colors.transparent,
        }),
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        textDecorationColor: Colors.green,
        textUnderlineOffset: 7,
        textDecorationThickness: 2.5,
        fontWeight: FontWeight.REGULAR,
        lineHeight: '30px',
        textAlign: 'left',
      }),

      ...(variant === 'warning' && {
        ...warningStyles,
        '&:hover': warningHoverStyles,
        ...(disabled && {
          color: dark ? Colors.lightGray : Colors.mediumGray,
          backgroundColor: dark ? Colors.mediumGray : Colors.lightGray,
        }),
      }),
    },
  })
)

export default Button
