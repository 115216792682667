import { Divider, Grid, Image } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  DEDUCTIONS_AND_CREDITS_SCREENS,
  DeductionsAndCreditsStepProps,
} from '.'
import {
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import ReviewSubSection, {
  displayResponse,
} from '../../Shared/ReviewSubSection'
import { businessMileageQuestionIds } from './HadBusinessMileagePanel'
import { ownedFarmQuestionIds } from './OwnedFarmPanel'
import { charitableContributionsQuestionIds } from './CharitableContributionsPanel'
import { deductionsAndCreditsQuestionIds } from './DeductionsAndCreditsPanel'
import { ownedHomeQuestionIds } from './OwnedHomePanel'
import { educationQuestionIds } from './EducationPanel'
import { dependentCareQuestionIds } from './DependentCareExpensesPanel'
import {
  directHomeOfficeStandardQuestionIds,
  homeOfficeSimplifiedQuestionIds,
  homeOfficeStandardQuestionIds,
  indirectHomeOfficeStandardQuestionIds,
} from './HomeOfficePanel'
import { homeOfficeStandardStep1QuestionIds } from './HomeOfficeStandardStep1Panel'
import { useSetScreen } from '../../../../../../components/FormFlow/formFlow'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionsByIds,
} from '../../taxChecklist.selectors'
import { TaxListOptionId, TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useNavigateWithLocation } from '../../../../../../utils/routeHelpers'
import { selectTqIsReadOnly } from '../../../annualTaxFilings.selector'

const DeductionsAndCreditsReviewPanel = ({
  goBack,
  previousScreen,
}: DeductionsAndCreditsStepProps) => {
  const navigate = useNavigateWithLocation()
  const { formId } = useParams()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const readOnly = useReselector(selectTqIsReadOnly, formId)
  const { setReviewScreen } = useSetScreen()

  //the design for standard method step 2 is slightly different than the other review subsections, so we need
  //to create a bespoke review sub section
  const homeOfficeStandardQuestions = useReselector(
    selectTaxListQuestionsByIds,
    homeOfficeStandardQuestionIds
  )

  const totalAmountQuestion = Object.values(homeOfficeStandardQuestions).find(
    ({ question }) =>
      question?.id ===
      TaxListQuestionId.home_office_total_standard_amount_in_cents
  )

  const indirectExpenseQuestions = Object.values(
    homeOfficeStandardQuestions
  ).filter(
    ({ question }) =>
      question && indirectHomeOfficeStandardQuestionIds.includes(question.id)
  )

  const directExpenseQuestions = Object.values(
    homeOfficeStandardQuestions
  ).filter(
    ({ question }) =>
      question && directHomeOfficeStandardQuestionIds.includes(question.id)
  )

  const responseHomeOfficeMethod = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_deduction_method,
    Number(formId)
  )?.[0]?.value

  const ownedFarmResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.owned_farm,
    Number(formId)
  )?.[0]?.value

  const ownedHomeResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.owned_home,
    Number(formId)
  )?.[0]?.value

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            Review your answers for accuracy. Click edit to go back to the
            previous section.
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            You can no longer edit your Personal Tax Questionnaire responses,
            but you can view them anytime.
          </Text>
        </GridRowColumn>
      )}
      <Grid.Row />
      <ReviewSubSection
        title="deductions and credits"
        questionIds={deductionsAndCreditsQuestionIds}
        editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.deductionsAndCredits}
      />
      {ownedHomeResponse && (
        <ReviewSubSection
          title="home owner"
          questionIds={ownedHomeQuestionIds}
          editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.ownedHome}
        />
      )}
      <ReviewSubSection
        title={'home office'}
        questionIds={[TaxListQuestionId.home_office_deduction_method]}
        editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice}
      />
      {responseHomeOfficeMethod ===
        TaxListOptionId.home_office_method_simplified && (
        <ReviewSubSection
          title="home office: simplified method"
          questionIds={homeOfficeSimplifiedQuestionIds}
          editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeSimplified}
        />
      )}
      {responseHomeOfficeMethod ===
        TaxListOptionId.home_office_method_standard && (
        <>
          <ReviewSubSection
            title="home office: standard method step 1"
            questionIds={homeOfficeStandardStep1QuestionIds}
            editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep1}
          />
          {totalAmountQuestion?.responses?.[0] && (
            <>
              <Grid.Row className="short" style={{ justifyContent: 'center' }}>
                <Grid.Column width={4}>
                  <Text as="eyebrow">
                    {'home office: standard method step 2'}
                  </Text>
                </Grid.Column>
                <Grid.Column
                  width={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="link"
                    onClick={() =>
                      setReviewScreen(
                        DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep2
                      )
                    }
                  >
                    <Icon
                      size="1x"
                      icon={regular('pen')}
                      style={{ marginRight: 4 }}
                    />
                    Edit
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="short" />
              {totalAmountQuestion && (
                <Grid.Row
                  className="short"
                  key={totalAmountQuestion.question?.id}
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <Grid.Column width={4}>
                    {totalAmountQuestion.question?.text && (
                      <Text as="actionLinkMd">
                        {totalAmountQuestion.question?.text}
                      </Text>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    key={totalAmountQuestion.responses?.[0].id}
                    width={4}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      height: '100%',
                    }}
                  >
                    <Text as="bodyMd">
                      {totalAmountQuestion &&
                        displayResponse(
                          totalAmountQuestion.question,
                          totalAmountQuestion.options,
                          totalAmountQuestion.responses?.[0]
                        )}
                    </Text>
                  </Grid.Column>
                  {totalAmountQuestion.responses.length === 0 && (
                    <Grid.Column
                      key={`${totalAmountQuestion.question?.id}-no-response`}
                      width={4}
                    />
                  )}
                </Grid.Row>
              )}
              <GridRowColumn short {...makeGridConfig([8, 14], true)}>
                <Text as="eyebrow" color="darkGray">
                  {taxYear} INDIRECT EXPENSES
                </Text>
              </GridRowColumn>
              {indirectExpenseQuestions?.map(
                ({ question, responses, options }) => (
                  <Grid.Row
                    className="short"
                    key={question?.id}
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Grid.Column width={4}>
                      {question?.text && (
                        <Text as="actionLinkMd">{question?.text}</Text>
                      )}
                    </Grid.Column>
                    {responses.map((response) => (
                      <Grid.Column
                        key={response.id}
                        width={4}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          height: '100%',
                        }}
                      >
                        <Text as="bodyMd">
                          {displayResponse(question, options, response)}
                        </Text>
                      </Grid.Column>
                    ))}
                    {responses.length === 0 && (
                      <Grid.Column
                        key={`${question?.id}-no-response`}
                        width={4}
                      />
                    )}
                  </Grid.Row>
                )
              )}
              <GridRowColumn short {...makeGridConfig([8, 14], true)}>
                <Text as="eyebrow" color="darkGray">
                  {taxYear} DIRECT EXPENSES
                </Text>
              </GridRowColumn>
              {directExpenseQuestions?.map(
                ({ question, responses, options }) =>
                  Boolean(responses.length) && (
                    <Grid.Row
                      className="short"
                      key={question?.id}
                      style={{ justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Grid.Column width={4}>
                        {question?.text && (
                          <Text as="actionLinkMd">{question?.text}</Text>
                        )}
                      </Grid.Column>
                      {responses.map((response) => (
                        <Grid.Column
                          key={response.id}
                          width={4}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '100%',
                          }}
                        >
                          <Text as="bodyMd">
                            {displayResponse(question, options, response)}
                          </Text>
                        </Grid.Column>
                      ))}
                      {responses.length === 0 && (
                        <Grid.Column
                          key={`${question?.id}-no-response`}
                          width={4}
                        />
                      )}
                    </Grid.Row>
                  )
              )}
              <GridRowColumn {...makeGridConfig([8, 14], true)}>
                <Divider />
              </GridRowColumn>
            </>
          )}
        </>
      )}
      <ReviewSubSection
        title="business mileage"
        questionIds={businessMileageQuestionIds}
        editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.businessMileage}
      />
      {ownedFarmResponse && (
        <ReviewSubSection
          title="farm"
          questionIds={ownedFarmQuestionIds}
          editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.farm}
        />
      )}
      <ReviewSubSection
        title="education"
        questionIds={educationQuestionIds}
        editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.education}
      />
      <ReviewSubSection
        title="dependent care expense"
        questionIds={dependentCareQuestionIds}
        editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.dependentCare}
        group="childcareProviderId"
      />
      <ReviewSubSection
        title="charitable contributions"
        questionIds={charitableContributionsQuestionIds}
        editScreen={DEDUCTIONS_AND_CREDITS_SCREENS.charitableContributions}
      />
      {!readOnly && (
        <FormFlowFooter
          isSubmit
          onBack={() => goBack(previousScreen ?? null)}
          onForward={() => navigate(`/taxes/annual/tax_checklist/${formId}`)}
        />
      )}
    </Grid>
  )
}

export default DeductionsAndCreditsReviewPanel
