import { ReactNode, useCallback } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { isNil } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useFlyout, useReselector } from '../../../../utils/sharedHooks'
import { selectUserTaxEstimateById } from '../userTaxEstimates.selector'
import {
  Accordion,
  Button,
  Card,
  CircularWrapper,
  GridRowColumn,
  Icon,
  Link,
  ParsedHtml,
  Text,
} from '../../../../components/BaseComponents'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import { splitTaxYearQuarter } from '../helpers'
import { selectQuarterlyTaxEstimateDetailsByYearAndQuarter } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { shortNames } from '../../../../constants/statesShortNamesConstants'
import { STATE_QUARTERLY_TAX_PAYMENTS } from '../../taxConstants'
import { SafeHarborType } from '../userTaxEstimates.slice'

const StepCard = ({
  stepNumber,
  content,
  expandContent,
}: {
  stepNumber: number
  content: ReactNode
  expandContent?: ReactNode
}) => (
  <GridRowColumn>
    <Grid verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={3}>
          <CircularWrapper height={48} wrapperColor="stone">
            <Text>{stepNumber}</Text>
          </CircularWrapper>
        </Grid.Column>
        <Grid.Column width={13}>{content}</Grid.Column>
      </Grid.Row>
      <GridRowColumn spacer={3} width={13} short>
        {expandContent ? (
          <Accordion title="Show me" content={expandContent} variant="text" />
        ) : null}
      </GridRowColumn>
    </Grid>
  </GridRowColumn>
)

const TaxPaymentInfoFlyout = ({
  estimateId,
  stateHelpContent,
}: {
  estimateId?: number
  stateHelpContent?: string | null
}) => {
  const estimate = useReselector(selectUserTaxEstimateById, estimateId)

  const { quarter, year } = splitTaxYearQuarter(estimate?.taxQuarter)

  const estimateDetails = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    year,
    quarter
  )

  if (!estimate) {
    return null
  }

  const { filingState, estimateInCents } = estimate

  const longStateName =
    filingState && shortNames[filingState]
      ? shortNames[filingState]
      : estimate?.filingState

  const helpArticle = filingState
    ? STATE_QUARTERLY_TAX_PAYMENTS[filingState]?.helpArticle
    : null

  const estimateHeaderText = estimate?.safeHarborType
    ? estimate?.safeHarborType === SafeHarborType.ES
      ? `Q${quarter} 1040-ES Estimate`
      : `Q${quarter} Federal Safe Harbor Estimate`
    : `Q${quarter} ${estimate?.filingState ? longStateName : 'Federal'} Tax Estimate`

  return (
    <Grid>
      <GridRowColumn>
        <Card backgroundColor="natural" type="subsection">
          <Grid>
            <GridRowColumn>
              <Text as="h3">{estimateHeaderText}</Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text as="display">
                {!isNil(estimateInCents)
                  ? formatCurrencyFromCents(estimateInCents)
                  : '$ ---'}
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text as="bodySm" color="darkGray">
                Estimated Payment due{' '}
                {estimateDetails?.irsPaymentDueAt &&
                  isoToUTCDateTime(estimateDetails.irsPaymentDueAt).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_LONG
                  )}
              </Text>
            </GridRowColumn>
          </Grid>
        </Card>
      </GridRowColumn>
      <GridRowColumn>
        <Card backgroundColor="stone" type="subsection">
          <Text as="eyebrow" color="darkGray">
            <Icon icon={regular('ribbon')} style={{ marginRight: 10 }} />
            Reminder
          </Text>

          <Text as="h3" style={{ marginTop: 10 }}>
            Quarterly taxes are <i>personal</i> income taxes. Pay from your{' '}
            <i>personal</i> account.
          </Text>

          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4416233114647-Should-I-pay-quarterly-taxes-from-my-personal-or-business-bank-account"
            newPage
            style={{ marginTop: 5 }}
          >
            Why?
          </Link>
        </Card>
      </GridRowColumn>

      <GridRowColumn>
        <Text as="h3">How to pay</Text>
      </GridRowColumn>

      {filingState ? (
        <>
          {/* The flyout is a state estimate */}
          <GridRowColumn>
            <StepCard
              stepNumber={1}
              content={
                <Text>
                  You’ll be directed to the state portal to submit your tax
                  payment. You can pay using your bank account or credit card.
                  (Note: paying by credit card incurs fees).
                </Text>
              }
            />
          </GridRowColumn>
          <GridRowColumn>
            <StepCard
              stepNumber={2}
              content={
                <Text>
                  Save your receipts and upload to Heard using “Mark as Paid.”
                  We need this to keep an accurate record of your taxes.
                </Text>
              }
              expandContent={
                <Image src="https://heard-images.s3.amazonaws.com/assets/FederalPayments3.svg" />
              }
            />
          </GridRowColumn>

          {stateHelpContent ? (
            <GridRowColumn>
              <Accordion
                title="View detailed instructions"
                content={
                  <>
                    <ParsedHtml>{stateHelpContent}</ParsedHtml>
                    {helpArticle && (
                      <div style={{ marginTop: 15 }}>
                        <Link href={helpArticle} newPage>
                          <Icon
                            icon={regular('up-right-from-square')}
                            style={{ paddingRight: 10 }}
                          />
                          View help article
                        </Link>
                      </div>
                    )}
                  </>
                }
                variant="text"
              />
            </GridRowColumn>
          ) : null}
        </>
      ) : (
        <>
          {/* The flyout is a federal estimate */}
          <GridRowColumn>
            <StepCard
              stepNumber={1}
              content={
                <Text>
                  Pay online through the IRS portal using your bank account or
                  credit card. If paying by personal bank account, click “Direct
                  Pay.” (Note: paying by credit card incurs fees).{' '}
                  <Link
                    href="https://support.joinheard.com/hc/en-us/articles/4402339575319-Paying-Federal-Quarterly-Taxes"
                    newPage
                  >
                    View help for other payment methods
                  </Link>
                </Text>
              }
              expandContent={
                <>
                  You will be redirected to a secure portal and prompted to
                  answer several questions to confirm your tax information and
                  identity. On the first page of the portal, be sure to note
                  your Reason for Payment as “Estimated Tax”.
                  <Image src="https://heard-images.s3.amazonaws.com/assets/FederalPayments1.png" />
                </>
              }
            />
          </GridRowColumn>
          <GridRowColumn>
            <StepCard
              stepNumber={2}
              content={
                <Text>
                  If paying by Direct Pay (from your personal bank account),
                  select “Estimated Tax” for “Reason for Payment.” This will
                  automatically fill the rest of the fields for you with
                  “1040ES” and the current year.
                </Text>
              }
              expandContent={
                <Image src="https://heard-images.s3.amazonaws.com/assets/FederalPayments2.png" />
              }
            />
          </GridRowColumn>
          <GridRowColumn>
            <StepCard
              stepNumber={3}
              content={
                <Text>
                  Save your receipts and upload to Heard using “Mark as Paid.”
                  We need this to keep an accurate record of your taxes.
                </Text>
              }
              expandContent={
                <Image src="https://heard-images.s3.amazonaws.com/assets/FederalPayments3.svg" />
              }
            />
          </GridRowColumn>
        </>
      )}
    </Grid>
  )
}

const TaxPaymentInfoFlyoutFooter = ({
  estimateId,
  openMarkAsPaid,
}: {
  estimateId?: number
  openMarkAsPaid: () => void
}) => {
  const estimate = useReselector(selectUserTaxEstimateById, estimateId)

  if (!estimate) {
    return null
  }

  const paymentLink = estimate?.filingState
    ? STATE_QUARTERLY_TAX_PAYMENTS[estimate.filingState]?.paymentLink
    : 'https://www.irs.gov/payments'

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={9}>
          <Link newPage href={paymentLink}>
            <Button size="medium">
              <Icon
                icon={regular('square-arrow-up-right')}
                style={{ marginRight: 8 }}
              />
              Continue to payment
            </Button>
          </Link>
        </Grid.Column>

        <Grid.Column width={7}>
          <Button
            variant="secondary"
            fullWidth
            onClick={openMarkAsPaid}
            size="medium"
          >
            Mark as paid
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const useTaxPaymentInfoFlyout = ({
  estimateId,
  openQTEModal,
}: {
  estimateId?: number
  openQTEModal: () => void
}) => {
  const estimate = useReselector(selectUserTaxEstimateById, estimateId)
  const { openFlyout, closeFlyout } = useFlyout()

  const helpArticleId = estimate?.filingState
    ? STATE_QUARTERLY_TAX_PAYMENTS[estimate?.filingState]?.helpArticleId
    : null

  const fetchArticleContent = useCallback(async () => {
    if (!helpArticleId) {
      return null
    }

    try {
      // Fetch the article content via the zendesk api
      const response = await fetch(
        `https://joinheard.zendesk.com/api/v2/help_center/en-us/articles/${helpArticleId}.json`
      )

      const json = await response.json()

      // This should always come back as string but do check just in case to prevent crashes
      if (typeof json?.article?.body === 'string') {
        return json.article.body
      }

      return null
    } catch (e) {
      return null
    }
  }, [helpArticleId])

  return useCallback(async () => {
    const content = await fetchArticleContent()

    const longStateName =
      estimate?.filingState && shortNames[estimate?.filingState]
        ? shortNames[estimate?.filingState]
        : estimate?.filingState

    openFlyout({
      content: (
        <TaxPaymentInfoFlyout
          estimateId={estimateId}
          stateHelpContent={content}
        />
      ),
      title: `Pay ${estimate?.filingState ? longStateName : 'federal'} quarterly taxes`,
      direction: 'right',
      footer: (
        <TaxPaymentInfoFlyoutFooter
          estimateId={estimateId}
          openMarkAsPaid={() => {
            closeFlyout()
            openQTEModal()
          }}
        />
      ),
    })
  }, [
    fetchArticleContent,
    estimate?.filingState,
    openFlyout,
    estimateId,
    closeFlyout,
    openQTEModal,
  ])
}

export default useTaxPaymentInfoFlyout
