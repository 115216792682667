import { DateTime } from 'luxon'
import { adminFetchSubscriptionsBySubscriptionId } from '../../../../actions/adminActions'
import { UserWithAdminInfo } from '../../../../reducers/admin/allUsersReducer'
import { selectPrimaryMembershipForUser } from '../../../../selectors/user.selectors'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { useFetchResponse, useReselector } from '../../../../utils/sharedHooks'
import { Alert } from '../../../BaseComponents'

const UserAccountCanceledAlert = ({ user }: { user?: UserWithAdminInfo }) => {
  const membership = useReselector(selectPrimaryMembershipForUser, user?.id)
  const subscription = useFetchResponse(
    adminFetchSubscriptionsBySubscriptionId,
    user?.id,
    membership?.stripeSubscription
  )
  const endDate = subscription?.current_period_end
    ? DateTime.fromSeconds(subscription?.current_period_end).toFormat(
        DATE_FORMATS_LUXON.DISPLAY_SHORT
      )
    : 'N/A'

  if (membership?.membershipStatus === 'cancelling') {
    return (
      <Alert type="warn">
        <b>Please continue servicing this user.</b>
        <br />
        This user has canceled their plan, but still has service days left in
        their billing cycle.
        <br />
        <b>Subscription Ends On: {endDate}</b>
      </Alert>
    )
  } else if (
    membership?.membershipStatus &&
    !['trial', 'paid'].includes(membership?.membershipStatus)
  ) {
    return (
      <Alert type="error">
        <b>Do Not Service this User</b>
        <br />
        This User is not a paying User
      </Alert>
    )
  } else return null
}

export default UserAccountCanceledAlert
