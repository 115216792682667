/*
  User facing transaction category label
*/
import { useState, useEffect, useMemo } from 'react'
import { Form } from 'semantic-ui-react'

import { EXPENSES_COLORS } from '../../constants/colorConstants'
import { updateUserTransaction } from '../../features/Transactions/transactions.slice'
import OnboardingPopup from '../Login/OnboardingPopup'

import './TransactionLabels.scss'
import TransactionCategoryDropdown from './TransactionCategoryDropdown'
import {
  TransactionOrSplit,
  TransactionType,
} from '../../reducers/admin/allTransactions.slice'
import RecategorizationModal from '../../features/Transactions/RecategorizationModal'
import { Icon, Label, Text } from '../BaseComponents'
import { selectIsLockedTransaction } from '../../features/Transactions/transactions.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { Colors, Fonts } from '../../styles/theme'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { fetchChangeLogsForUserTransaction } from '../../features/Admin/TransactionChangeLogs/transactionChangeLog.slice'
import { categoryLastChangedByBookkeeper } from '../../features/Transactions/helpers'
import { getTransactionCategorySelector } from '../../features/Reports/reports.selectors'
import { LabelColorMap } from '../BaseComponents/Label'
import { TransactionCategory } from '../../features/Reports/reports.slice'
import { useAppDispatch } from '../../utils/typeHelpers'
/*
  Editable— if set to true, a user can click and update
*/

interface Props {
  transaction: TransactionOrSplit
  editable?: boolean
  review?: boolean
  useRecategorizationModal?: boolean
  variant?: 'default' | 'text'
  filtered?: boolean
}

type ReadOnlyLabelProps = {
  colorByCategory: keyof typeof LabelColorMap
  text: string
} & Pick<Props, 'variant'>

const categoryText = (
  transactionType: TransactionType,
  category?: TransactionCategory | null
) => (transactionType === 'personal' ? 'N/A' : category?.name || 'Unselected')

const ReadOnlyLabel = ({
  variant = 'default',
  colorByCategory,
  text,
}: ReadOnlyLabelProps) => {
  return (
    <>
      {variant === 'default' && <Label color={colorByCategory}>{text}</Label>}
      {variant !== 'default' && <Text style={{ fontWeight: 500 }}>{text}</Text>}
    </>
  )
}

export const TransactionCategoryLabelReadOnly = ({
  transactionType,
  transactionCategoryId,
  variant = 'default',
}: {
  transactionType: TransactionType
  transactionCategoryId: number | null
} & Pick<Props, 'variant'>) => {
  const category = useReselector(
    getTransactionCategorySelector,
    transactionCategoryId
  )

  const colorByCategory =
    EXPENSES_COLORS[(transactionCategoryId || 0) % EXPENSES_COLORS.length]

  return (
    <ReadOnlyLabel
      colorByCategory={colorByCategory}
      text={categoryText(transactionType, category)}
      variant={variant}
    />
  )
}

const TransactionCategoryLabel = ({
  transaction,
  editable,
  review,
  useRecategorizationModal = false,
  variant = 'default',
  filtered = false,
}: Props) => {
  const dispatch = useAppDispatch()
  const [editableDropdown, setEditableDropdown] = useState(review)
  const [updating, setUpdating] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const locked = useReselector(
    selectIsLockedTransaction,
    transaction.splitFrom || transaction.id
  )
  const [transactionCategoryId, setTransactionCategoryId] = useState<
    number | null
  >(null)
  const category = useReselector(
    getTransactionCategorySelector,
    transaction.transactionCategoryId
  )

  useEffect(() => {
    setTransactionCategoryId(transaction.transactionCategoryId)
  }, [transaction])

  const colorByCategory =
    EXPENSES_COLORS[
      (transaction.transactionCategoryId || 0) % EXPENSES_COLORS.length
    ]

  const text =
    transaction.type === 'personal' ? 'N/A' : category?.name || 'Unselected'

  const labelColor = useMemo(() => {
    if (text === 'Unselected') {
      return Colors.mediumGray
    }
    if (category?.isTaxCompliant && transaction.type !== 'personal') {
      return Colors.orange
    }
    return Colors.black
  }, [category?.isTaxCompliant, text, transaction.type])

  const updateTransactionCategory = async (value: number | null) => {
    setUpdating(true)
    const data = {
      transactionCategoryId: value,
    }
    const updated = await updateUserTransaction(transaction.id, data)(dispatch)
    if (updated) {
      setUpdating(false)
      setEditableDropdown(false)
    }
  }

  const confirmChange = async (value: number | null) => {
    setTransactionCategoryId(value)
    if (useRecategorizationModal) {
      /**
       * Opens modal if showRecategorizationModal is true and there is a recategorization explainer for the category it's being changed from
       */
      const changeLogs = await fetchChangeLogsForUserTransaction({
        transactionId: transaction.id,
      })(dispatch)
      const categoryWasLastChangedByBookkeeper =
        categoryLastChangedByBookkeeper(transaction.userId, changeLogs)
      if (categoryWasLastChangedByBookkeeper) {
        setOpenModal(true)
        return
      }
    }
    updateTransactionCategory(value)
    return
  }

  const popupContent = useMemo(() => {
    if (transaction.transactionCategoryId) {
      return 'Did we miscategorize this transaction? Click to recategorize.'
    } else {
      return 'No action needed right now! Our team reviews your transactions on a monthly basis and will categorize this for you.'
    }
  }, [transaction?.transactionCategoryId])

  const labelStyle = useMemo(() => {
    if (variant === 'default') {
      return {
        color: colorByCategory,
      }
    } else {
      return {
        color: labelColor,
        backgroundColor: 'inherit',
        ...Fonts.bodyMd,
        fontWeight: 500,
        display: 'flex',
        gap: 6,
        paddingRight: 0,
        paddingLeft: 0,
      }
    }
  }, [variant, colorByCategory, labelColor])

  if (editable && !locked) {
    const personal = transaction.type === 'personal'
    return (
      <>
        {/* We don't want to encourage any actions re:categorization for personal, so we don't prompt user to
        categorize or do anything */}
        {personal && <Label style={labelStyle}>{text}</Label>}
        {!personal && !review && !editableDropdown && (
          <OnboardingPopup
            popupContent={popupContent}
            trigger={
              <Label
                $hoverable
                onClick={() => setEditableDropdown(true)}
                style={labelStyle}
              >
                {text}
                {variant !== 'default' && <Icon icon={solid('caret-down')} />}
              </Label>
            }
          />
        )}

        {(editableDropdown || review) && !personal && (
          <Form>
            <TransactionCategoryDropdown
              placeholder="Search categories"
              icon="search"
              optionStyle="normal"
              onChange={(value) => {
                confirmChange(value || null)
              }}
              isTaxCompliant={
                variant === 'text' ? category?.isTaxCompliant : undefined
              }
              onClose={() => setEditableDropdown(false)}
              closeOnBlur
              value={transactionCategoryId || undefined}
              disabled={updating}
              fluid
              clearable={false}
              transactionAmount={
                filtered ? transaction.amountInCents : undefined
              }
              open={
                variant === 'text' && !review ? editableDropdown : undefined
              }
            />
          </Form>
        )}
        <RecategorizationModal
          oldCategoryId={transaction.transactionCategoryId}
          newCategoryId={transactionCategoryId}
          open={openModal}
          close={() => {
            setOpenModal(false)
            setTransactionCategoryId(transaction.transactionCategoryId)
          }}
          updateAction={() => updateTransactionCategory(transactionCategoryId)}
        />
      </>
    )
  } else {
    return (
      <ReadOnlyLabel
        colorByCategory={colorByCategory}
        text={text}
        variant={variant}
      />
    )
  }
}

export default TransactionCategoryLabel
