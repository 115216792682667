import { ReactNode, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'
import { capitalize } from 'lodash'

import {
  selectTicketCommentById,
  Ticket,
  TicketComment,
  TicketReviewStatus,
} from './conversation.slice'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import {
  Button,
  Card,
  GridRowColumn,
  Label,
  Text,
} from '../../components/BaseComponents'
import TeamMemberIcon from './TeamMemberIcon'
import { LabelColorMap } from '../../components/BaseComponents/Label'
import { StyledConversationMessage } from './ConversationThread/ConversationMessages'
import { selectTaxTeamMemberById } from '../../reducers/taxTeam.slice'
import {
  DEFAULT_HEARD_ADMIN_NAME,
  DEFAULT_SUBJECT,
  getRoleNameText,
} from './helpers'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../utils/deviceWidthHelpers'
import ArchiveConversationModal from './ArchiveConversationModal'

const LastUpdated = ({ updatedAt }: { updatedAt: string }) => (
  <Text style={{ justifyContent: 'center' }}>
    {moment(updatedAt).format(DATE_FORMATS.DISPLAY_SIMPLE)}
  </Text>
)

const Subject = ({
  subject,
  style,
}: {
  subject: string | null
  style?: React.CSSProperties
}) => (
  <Text style={style} as="h3">
    {subject || DEFAULT_SUBJECT}
  </Text>
)

const StatusLabel = ({
  archivedAt,
  reviewStatus,
}: {
  archivedAt: string | undefined
  reviewStatus: TicketReviewStatus | null
}) => {
  let labelColor: keyof typeof LabelColorMap
  let labelText: ReactNode

  if (archivedAt || reviewStatus === null) {
    labelColor = 'gray'
    labelText = 'Resolved'
  } else {
    labelColor =
      reviewStatus === TicketReviewStatus.inReview ? 'dark' : 'darkYellow'
    labelText =
      reviewStatus === TicketReviewStatus.inReview ? 'In Review' : 'Your Turn'
  }
  return (
    <Label color={labelColor} style={{ borderRadius: 100 }}>
      {labelText}
    </Label>
  )
}

const AuthorIcon = ({ teamMemberId }: { teamMemberId?: number }) => (
  <TeamMemberIcon id={teamMemberId} style={{ marginRight: 4 }} />
)

const Author = ({
  authorText,
  topic,
}: {
  authorText: string
  topic: string
}) => (
  <Text as="bodySm" color="darkGray">
    {authorText} / {capitalize(topic)}
  </Text>
)

const Message = ({
  newestComment,
}: {
  newestComment: TicketComment | undefined
}) => <StyledConversationMessage htmlBody={newestComment?.htmlBody} />

const ConversationListItem = ({
  ticket: { id, updatedAt, comments, subject, topic, reviewStatus, archivedAt },
}: {
  ticket: Ticket
}) => {
  const navigate = useNavigate()
  const newestComment = useReselector(
    selectTicketCommentById,
    comments?.[comments.length - 1]
  )
  const currentUser = useReselector(getCurrentUser)
  const teamMember = useReselector(
    selectTaxTeamMemberById,
    newestComment?.authorUserId
  )
  const [closeConvoModalOpen, setCloseConvoModalOpen] = useState(false)
  const isDesktop = useIsDeviceWidth(DeviceWidthCombo.desktop)

  const userIsLastToRespond = newestComment?.authorUserId === currentUser?.id

  const authorText = useMemo(() => {
    if (currentUser?.firstName && userIsLastToRespond) {
      return `${currentUser.firstName} ${currentUser.lastName}`
    } else if (teamMember?.firstName && !userIsLastToRespond) {
      return `${teamMember.firstName}, ${getRoleNameText(teamMember.roleName)}`
    }

    return DEFAULT_HEARD_ADMIN_NAME
  }, [
    currentUser?.firstName,
    currentUser?.lastName,
    teamMember?.firstName,
    teamMember?.roleName,
    userIsLastToRespond,
  ])

  return (
    <Card
      backgroundColor={
        archivedAt || reviewStatus !== TicketReviewStatus.yourTurn
          ? 'stone40'
          : 'natural'
      }
    >
      <Grid>
        {isDesktop ? (
          <Grid.Row>
            <Grid.Column width={2}>
              <LastUpdated updatedAt={updatedAt} />
            </Grid.Column>
            <Grid.Column width={12}>
              <Grid>
                <GridRowColumn
                  columnStyle={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Subject subject={subject} style={{ marginRight: 16 }} />
                  <StatusLabel
                    archivedAt={archivedAt}
                    reviewStatus={reviewStatus}
                  />
                </GridRowColumn>
                <GridRowColumn
                  short
                  columnStyle={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <AuthorIcon teamMemberId={teamMember?.id} />
                  <Author authorText={authorText} topic={topic} />
                </GridRowColumn>
                <GridRowColumn short>
                  <Message newestComment={newestComment} />
                </GridRowColumn>
              </Grid>
            </Grid.Column>

            <Grid.Column width={2}>
              <Button
                variant={
                  archivedAt || reviewStatus !== TicketReviewStatus.yourTurn
                    ? 'secondary'
                    : 'primary'
                }
                fullWidth
                onClick={() => navigate(`/conversations/${id}`)}
              >
                {archivedAt || reviewStatus !== TicketReviewStatus.yourTurn
                  ? 'View'
                  : 'Reply'}
              </Button>
              {!archivedAt && (
                <Button
                  fullWidth
                  onClick={() => setCloseConvoModalOpen(true)}
                  variant="secondary"
                  style={{ marginTop: 10 }}
                >
                  Archive
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column width={8}>
                <LastUpdated updatedAt={updatedAt} />
              </Grid.Column>
              <Grid.Column tablet={3} mobile={7} floated="right">
                <StatusLabel
                  archivedAt={archivedAt}
                  reviewStatus={reviewStatus}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="short" style={{ paddingBottom: '0.3rem' }}>
              <Grid.Column>
                <Subject subject={subject} />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn short>
              <Author authorText={authorText} topic={topic} />
            </GridRowColumn>
            <GridRowColumn short>
              <Message newestComment={newestComment} />
            </GridRowColumn>
            <Grid.Row>
              {!archivedAt && (
                <Grid.Column short width={8}>
                  <Button
                    fullWidth
                    onClick={() => setCloseConvoModalOpen(true)}
                    variant="secondary"
                  >
                    Archive
                  </Button>
                </Grid.Column>
              )}
              <Grid.Column short width={8}>
                <Button
                  variant={
                    archivedAt || reviewStatus !== TicketReviewStatus.yourTurn
                      ? 'secondary'
                      : 'primary'
                  }
                  fullWidth
                  onClick={() => navigate(`/conversations/${id}`)}
                >
                  {archivedAt || reviewStatus !== TicketReviewStatus.yourTurn
                    ? 'View'
                    : 'Reply'}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
      <ArchiveConversationModal
        open={closeConvoModalOpen}
        close={() => setCloseConvoModalOpen(false)}
        convoId={id}
      />
    </Card>
  )
}

export default ConversationListItem
