import Card from '../components/BaseComponents/Card'

export const DefaultCard = () => <Card>Body</Card>

export const SectionCard = () => <Card type="section">Section</Card>

export const SubSectionCard = () => <Card type="subsection">SubSection</Card>

export const DisabledCard = () => <Card variant="disabled">Disabled</Card>

export const EmphasisCard = () => (
  <Card type="section" variant="emphasis">
    Emphasis
  </Card>
)

export const BlushCard = () => <Card backgroundColor="blush">Blush</Card>

export const FullWidthCard = () => <Card fullWidth>Full Width</Card>

export const CustomPaddingCard = () => (
  <Card padding={8}>Custom Padding 8px</Card>
)
