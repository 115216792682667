import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PersonalReview from './Personal/Review'
import CombinedReview from './Combined/Review'
import { fetchAnnualTaxFilingsIfNeeded } from '../annualTaxFilings.slice'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../annualTaxFilingForms.slice'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchUserTaxEstimatesIfNeeded } from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'
import { fetchTaxUserDocumentsIfNeeded } from '../taxUserDocuments.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import PageHeader from '../../../../components/shared/PageHeader'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../annualTaxFilingForms.selector'
import { fetchUserTaxQuestionnaire } from '../TaxChecklist/taxChecklistQuestion.actions'
import BusinessReviewReadOnly from './Business/BusinessReviewReadOnly'

const ExtensionSurveyReadOnly = () => {
  const dispatch = useAppDispatch()
  const { taxYear } = useParams()

  const form1040 = useReselector(select1040FormForYear, taxYear)
  const form1120s = useReselector(select1120sFormForYear, taxYear)
  const needsExtended1040 = form1040?.isExtended
  const needsExtended1120S = form1120s?.isExtended
  const hasTwoExtendedForms = needsExtended1040 && needsExtended1120S

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchUserTaxEstimatesIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    taxYear && dispatch(fetchUserTaxQuestionnaire(taxYear))
  }, [dispatch, taxYear])

  const title = useMemo(() => {
    if (hasTwoExtendedForms) {
      return ''
    }
    return needsExtended1040 ? 'Personal' : 'Business'
  }, [hasTwoExtendedForms, needsExtended1040])

  const reviewContent = useMemo(() => {
    const props = {
      isReadOnly: true,
      taxYear: taxYear || '',
      // These options are hidden so just stub out
      goToNextStep: () => Promise.resolve(true),
      goBack: () => null,
    }

    if (hasTwoExtendedForms) {
      return <CombinedReview {...props} />
    }
    return needsExtended1040 ? (
      <PersonalReview {...props} />
    ) : (
      <BusinessReviewReadOnly {...props} />
    )
  }, [hasTwoExtendedForms, needsExtended1040, taxYear])

  return (
    <>
      <PageHeader
        header={`${taxYear} ${title} Tax Return Extension Request: Review`}
        backControl={{
          link: '/taxes/annual',
          text: 'Back to Annual Tax Center',
        }}
        // This really isn't a multistep but it's made to look like the review page on flows
        isMultiStep
      />
      {reviewContent}
    </>
  )
}

export default ExtensionSurveyReadOnly
