import { useEffect } from 'react'
import { Divider, Grid, Container } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(yup)

import {
  Button,
  FormikDropdown,
  FormikInput,
  getFieldName,
  makeReqStringSchema,
  Text,
  Alert,
  Link,
  makeReqPhoneNumberSchema,
  GridRowColumn,
  makePasswordSchema,
  FormikCheckbox,
  makeReqEmailSchema,
} from '../../components/BaseComponents'
import {
  REGISTER_FINANCES_USER_KEY,
  registerFinancesUser,
} from '../../actions/authActions'
import { STATES } from '../../constants/locationConstants'
import SignupHeader from './SignupHeader'
import { SIGNUP_PATHS, isFreeTrialEnabled } from './helpers'
import { getFetchError } from '../../reducers/fetch'
import { useSignupLastPageNavigate } from '../../services/onboardingService'
import { useAnalyticsSetUserId, useAnalyticsView } from '../Amplitude'
import {
  useNavigateWithPersistParams,
  useReselector,
  useScrollRef,
} from '../../utils/sharedHooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { getFreeTrialStatus } from '../../selectors/user.selectors'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import WhatYouGetCard from './WhatYouGetCard'
import ReferralWelcomeMessage from '../Referrals/WelcomeMessage'
import { useReferralFetch } from '../Referrals/referrals.helpers'
import { Colors } from '../../styles/theme'
import { TosVersion } from '../../constants/businessConstants'
import { useAppDispatch } from '../../utils/typeHelpers'

const TermsOfUseText = ({
  textAlign,
}: {
  textAlign: 'right' | 'left' | 'center' | 'inherit' | undefined
}) => (
  <Text textAlign={textAlign}>
    By selecting <b>Continue</b>, you&apos;re creating a Heard account, and you
    agree to our{' '}
    <Link href="https://www.joinheard.com/terms-of-service" newPage>
      Terms of Use
    </Link>{' '}
    and{' '}
    <Link href="https://www.joinheard.com/privacy" newPage>
      Privacy Policy.
    </Link>
  </Text>
)

const validationSchema = yup.object({
  firstName: makeReqStringSchema({ field: 'first name' }),
  lastName: makeReqStringSchema({ field: 'last name' }),
  email: makeReqEmailSchema({ field: 'email' }),
  phoneNumber: makeReqPhoneNumberSchema(),
  state: makeReqStringSchema({ field: 'practicing state' }),
  password: makePasswordSchema(),
  confirmPassword: makeReqStringSchema({ field: 'confirm password' }).test(
    'Password Test',
    'Passwords do not match',
    (confirmPassword, context) => {
      const password = context.parent['password']
      return password === confirmPassword
    }
  ),
  smsContactPhoneNumber: yup
    .string()
    .when('smsConsent', ([smsConsent], schema) =>
      smsConsent === true ? makeReqPhoneNumberSchema() : schema.nullable()
    ),
})

const AboutYou = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithPersistParams()
  const { scrollRef, scrollToRef } = useScrollRef()
  const pageView = useAnalyticsView()
  const setAnalyticsUserId = useAnalyticsSetUserId()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  useEffect(() => {
    pageView('sign up about you')
  }, [pageView])

  const signupError = useReselector(getFetchError, REGISTER_FINANCES_USER_KEY)

  useSignupLastPageNavigate()

  const [searchParams] = useSearchParams()
  const basicPlanEnabled = searchParams.get('subscription') === 'new'
  const userFreeTrialStatus = useReselector(getFreeTrialStatus)
  const freeTrialEnabled = isFreeTrialEnabled(
    searchParams.get('trial'),
    userFreeTrialStatus,
    basicPlanEnabled
  )
  const isReferral = searchParams.get('trial') === 'referral'
  const partnerIsHeadway =
    searchParams.get('partner')?.toLowerCase() === 'headway'
  const { recipientCode } = useParams()
  const consult = new URLSearchParams(location.search).get('consult')
  const { recipient, loading: recipientLoading } =
    useReferralFetch(recipientCode)

  const formik = useFormik({
    initialValues: {
      firstName: recipient?.firstName || '',
      lastName: recipient?.lastName || '',
      email: recipient?.email || '',
      phoneNumber: '',
      state: '',
      password: '',
      confirmPassword: '',
      smsConsent: false,
      smsContactPhoneNumber: '',
      sameAsBusinessPhoneNumber: false,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      state,
      smsConsent,
      smsContactPhoneNumber,
    }) => {
      const res = await registerFinancesUser({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        state,
        smsContactPhoneNumber: smsConsent ? smsContactPhoneNumber : undefined,
        hadConsult: consult === 'true',
        tosVersion: TosVersion.tos_01_2024,
        tosAcceptedOn: Date.now(),
      })(dispatch)

      if (res) {
        setAnalyticsUserId(res.user.uuid)
        navigate(SIGNUP_PATHS.aboutYourPractice)
      } else {
        scrollToRef()
      }
    },
  })

  const { values, submitForm, isSubmitting, isValid, setFieldValue } = formik

  return (
    <>
      <Container style={{ paddingTop: 64 }}>
        <Grid stackable>
          <SignupHeader currentStep={0} />
          {signupError && (
            <GridRowColumn>
              <span ref={scrollRef} />
              <Alert type="error">
                <Text as="h2">{signupError.message}</Text>
              </Alert>
            </GridRowColumn>
          )}
          {recipientCode && (
            <ReferralWelcomeMessage
              freeTrialEnabled={freeTrialEnabled}
              recipient={recipient}
              recipientLoading={recipientLoading}
            />
          )}
          {!recipientCode && (
            <GridRowColumn>
              <Text as="h1">
                {isReferral ? 'Welcome' : 'Tell us about yourself'}
              </Text>
              <Text>
                {freeTrialEnabled
                  ? `Let's get you started with your free 30 day trial!
                    ${
                      // We only want to send gift cards for non-headway referrals,
                      // this may expand to any partner referral in the future, but for now we're specifically checking headway
                      isReferral && !partnerIsHeadway
                        ? ` Once you complete your trial and become a Heard customer, we’ll send you a $${process.env.VITE_REFERRAL_FACTORY_REWARD_DOLLARS_RECIPIENT} gift card as a thank you for joining our community.`
                        : ''
                    }`
                  : "We're all ears."}
              </Text>
            </GridRowColumn>
          )}
          <Grid.Row>
            <Grid.Column width={8}>
              <FormikProvider value={formik}>
                <Grid stackable style={isMobile ? { padding: '0 1rem' } : null}>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FormikInput
                        required
                        label="First Name"
                        fullWidth
                        name={getFieldName<typeof values>('firstName')}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <FormikInput
                        required
                        label="Last Name"
                        fullWidth
                        name={getFieldName<typeof values>('lastName')}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <GridRowColumn>
                    <FormikInput
                      style={{ border: `1px solid ${Colors.gray}` }}
                      required
                      label="Business Email Address"
                      fullWidth
                      disabled={Boolean(recipient?.email)}
                      name={getFieldName<typeof values>('email')}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FormikInput
                      required
                      label="Business Phone"
                      placeholder="555-555-5555"
                      fullWidth
                      componentType="phone"
                      name={getFieldName<typeof values>('phoneNumber')}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FormikCheckbox
                      label="Get important tax and bookeeping reminders via SMS"
                      name={getFieldName<typeof values>('smsConsent')}
                      variant="default"
                    />
                  </GridRowColumn>
                  {values.smsConsent && (
                    <GridRowColumn>
                      <Grid style={{ marginLeft: 10 }}>
                        {!values.sameAsBusinessPhoneNumber && (
                          <GridRowColumn>
                            <FormikInput
                              required
                              label="What mobile phone do you want text reminders sent
                                  to?"
                              placeholder="555-555-5555"
                              fullWidth
                              componentType="phone"
                              name={getFieldName<typeof values>(
                                'smsContactPhoneNumber'
                              )}
                            />
                          </GridRowColumn>
                        )}
                        <GridRowColumn short>
                          <FormikCheckbox
                            label="Same as business phone"
                            name={getFieldName<typeof values>(
                              'sameAsBusinessPhoneNumber'
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                setFieldValue(
                                  'smsContactPhoneNumber',
                                  values.phoneNumber
                                )
                              }
                            }}
                            variant="default"
                          />
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Text as="bodyXs" style={{ color: 'gray' }}>
                            You agree to receive recurring text messages from
                            Heard about account updates and service reminders at
                            the mobile phone number provided. Messages frequency
                            varies. Message and date rates may apply. Reply STOP
                            to cancel. View our{' '}
                            <Link
                              as="bodyXs"
                              newPage
                              style={{
                                textDecoration: 'underline',
                              }}
                              href="https://www.joinheard.com/terms-of-service"
                            >
                              Terms of Service
                            </Link>{' '}
                            and{' '}
                            <Link
                              as="bodyXs"
                              newPage
                              style={{
                                textDecoration: 'underline',
                              }}
                              href="https://www.joinheard.com/privacy"
                            >
                              Privacy Policy
                            </Link>
                            .
                          </Text>
                        </GridRowColumn>
                      </Grid>
                    </GridRowColumn>
                  )}
                  <GridRowColumn>
                    <FormikDropdown
                      required
                      label="Home State"
                      placeholder="Select State"
                      description="Your primary residence is in this state."
                      options={STATES}
                      fullWidth
                      name={getFieldName<typeof values>('state')}
                      search
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FormikInput
                      required
                      label="Password"
                      description="Your password needs to have more than 8 characters, and must include a number and a symbol."
                      fullWidth
                      name={getFieldName<typeof values>('password')}
                      type="password"
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FormikInput
                      required
                      label="Confirm Password"
                      fullWidth
                      name={getFieldName<typeof values>('confirmPassword')}
                      type="password"
                    />
                  </GridRowColumn>
                </Grid>
              </FormikProvider>
            </Grid.Column>
            {!isMobile && (
              <>
                <Grid.Column width={2} />
                <Grid.Column width={6} verticalAlign="top">
                  <WhatYouGetCard style={{ padding: 48 }} />
                </Grid.Column>
              </>
            )}
          </Grid.Row>
          {isMobile && (
            <>
              <GridRowColumn>
                <Button
                  fullWidth
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  id="btn-signup-create-account"
                >
                  Continue
                </Button>
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => navigate('/')}
                >
                  Back
                </Button>
              </GridRowColumn>
              <GridRowColumn spacer={8} width={8}>
                <TermsOfUseText textAlign="center" />
              </GridRowColumn>
            </>
          )}
          {!isMobile && (
            <>
              <Divider />
              <Grid.Row>
                <Grid.Column width={2}>
                  <Button
                    variant="secondary"
                    fullWidth
                    onClick={() => navigate('/')}
                  >
                    Back
                  </Button>
                </Grid.Column>
                <Grid.Column width={12} />
                <Grid.Column width={2}>
                  <Button
                    fullWidth
                    onClick={submitForm}
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                    id="btn-signup-create-account"
                  >
                    Continue
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <GridRowColumn width={8} spacer={8}>
                <TermsOfUseText textAlign="right" />
              </GridRowColumn>
            </>
          )}
        </Grid>
      </Container>
      {isMobile && <WhatYouGetCard />}
    </>
  )
}

export default AboutYou
