import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Grid, GridColumn, GridRow, Image } from 'semantic-ui-react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  light,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { CSSProperties } from 'styled-components'

import { FETCH_TAX_FILINGS_KEY } from '../annualTaxFilings.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectAnnualTaxFilingFormById,
  selectIsTwoFormFiler,
} from '../annualTaxFilingForms.selector'
import {
  getIsFetching,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../reducers/fetch'
import {
  Accordion,
  Alert,
  Button,
  Card,
  GridRowColumn,
  Icon,
  Link,
  Loader,
  Pill,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import {
  AnnualTaxFilingForm,
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../annualTaxFilingForms.slice'
import PageHeader from '../../../../components/shared/PageHeader'
import { ANNUAL_TAX_FILING_FORM_TYPES } from '../Questionnaires/constants'
import { Colors, FontWeight } from '../../../../styles/theme'
import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { getUnpaidTaxEstimatesByYear } from '../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import {
  formatTaxQuarter,
  sortAndGroupEstimatesByTaxQuarter,
} from '../../QuarterlyTaxEstimates/helpers'
import {
  FETCH_TAX_ESTIMATES_KEY,
  fetchUserTaxEstimatesIfNeeded,
} from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import {
  StepStatus,
  fetchUserEoyReviewProgress,
  FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
} from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { fetchAllEoyReviewStepsIfNeeded } from './Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import {
  selectReviewBooksCompleted,
  selectUserEoyReviewProgressForSubstepIdentifier,
  selectUserEoyReviewProgressStatusForChecklistSteps,
} from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import {
  CHANGES_IN_YEAR,
  CHECK_YOUR_DETAILS_1120S,
  CHECK_YOUR_DETAILS_1040,
  COMPLETE_PAYMENT,
  DEDUCTIONS_AND_CREDITS,
  ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
  NON_THERAPY_INCOME_AND_LIABILITIES,
  REVIEW_INCOME_AND_EXPENSES_FINAL,
  REVIEW_INCOME_AND_EXPENSES_INITIAL,
  SubStepIdentifiers,
  UPDATE_YOUR_BOOKS,
  UPLOAD_DOCUMENTS_1040,
  UPLOAD_DOCUMENTS_1120S,
} from './Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectIsNewScorpSubscription2023 } from '../../../../reducers/subscription.slice'
import {
  selectAllRequiredDocsUploaded,
  selectAwaiting1120SCompletion,
  selectDocsUploadedOrSkippedAndTotalCount,
  selectFinalReviewEnabled,
  selectTaxChecklistReadyToSubmit,
  selectTaxQuestionnaireSectionsCompleteAndTotalCount,
} from './taxChecklist.selectors'
import ApproveAndSubmitModal from './ApproveAndSubmitModal'
import {
  getAnnualTaxFilingById,
  selectFormTQStatus,
  selectTqIsReadOnly,
} from '../annualTaxFilings.selector'
import {
  ADDITIONAL_1040_COST,
  AnnualTaxFilingStepStatus,
  TaxChecklistPaths,
} from '../constants'
import useProgressSteps from './Shared/ReviewStepsandProgresses/useProgressSteps'
import {
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  fetchUserTaxQuestionnaire,
  submitBooksComplete,
} from './taxChecklistQuestion.actions'
import { selectIsCatchupBookkeepingCompleted } from '../../../../selectors/user.selectors'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../UserDocuments/userDocuments.slice'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { selectUserBooksLockedForCurrentTaxYear } from '../../../../reducers/auth/user.selectors'
import useTaxChecklistPayment from './Form1040/payment'
import {
  selectIsLateJoinerByYear,
  selectTaxDetailsByYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import ExtensionAlert from '../ExtensionSurvey/ExtensionAlert'

interface StepCardProps {
  title: ReactNode
  key: string
  icon: IconDefinition
  content: ReactNode
  path?: TaxChecklistPaths
  buttonText?: string
  buttonIcon?: IconDefinition
  disabled?: boolean
  status?: StepStatus
  action?: () => void
  loading?: boolean
  hideStep?: boolean
  readOnly?: boolean
}

const StepCard = ({
  title,
  icon,
  content,
  path,
  buttonText,
  buttonIcon,
  disabled,
  status,
  action,
  loading,
  hideStep,
  readOnly,
}: StepCardProps) => {
  const navigate = useNavigate()
  const { formId } = useParams()

  const isFetchingProgress = useReselector(
    getIsFetching,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY
  )
  const toPage =
    path && formId
      ? `/taxes/annual/tax_checklist/${formId}/${path}`
      : '/taxes/annual'

  if (hideStep) {
    return null
  }

  return (
    <Card
      backgroundColor={readOnly ? 'stone' : 'natural'}
      style={{ marginTop: 15 }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={2} verticalAlign="middle">
            <Icon icon={icon} size="2x" />
          </Grid.Column>
          <Grid.Column width={10}>
            <Text as="h3">{title}</Text>
            <Text style={{ marginTop: 5 }}>{content}</Text>
          </Grid.Column>
          <Grid.Column width={4} verticalAlign="middle">
            <Button
              fullWidth
              onClick={() => (action ? action() : navigate(toPage))}
              disabled={disabled || isFetchingProgress}
              variant={
                isFetchingProgress || status === StepStatus.completed
                  ? 'secondary'
                  : 'primary'
              }
              loading={loading || isFetchingProgress}
            >
              {status === StepStatus.completed ? (
                <>
                  <Icon icon={regular('check')} style={{ marginRight: 15 }} />
                  {buttonText || 'View'}
                </>
              ) : status === StepStatus.inProgress ? (
                <>
                  {buttonIcon && (
                    <Icon icon={buttonIcon} style={{ marginRight: 8 }} />
                  )}
                  {buttonText || 'Continue'}
                </>
              ) : (
                <>
                  {buttonIcon && (
                    <Icon icon={buttonIcon} style={{ marginRight: 8 }} />
                  )}
                  {buttonText || 'Start'}
                </>
              )}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const SectionCard = ({
  title,
  titleStyle,
  imageUrl,
  steps,
  extraContent,
  stepsComplete,
  headerContent,
  readOnly,
}: {
  title: ReactNode
  titleStyle?: CSSProperties
  imageUrl: string
  steps: StepCardProps[]
  extraContent?: ReactNode
  stepsComplete: number
  headerContent?: ReactNode
  readOnly?: boolean
}) => {
  const allStepsComplete = steps.every(
    (step) => step.status === StepStatus.completed
  )

  return (
    <Card
      type="section"
      backgroundColor={allStepsComplete ? 'stone40' : 'white'}
      style={{
        border: allStepsComplete ? 'none' : `1px solid ${Colors.lightGray}`,
      }}
    >
      <Accordion
        title={
          <Grid style={{ width: '100%' }}>
            <Grid.Row>
              <Grid.Column width={2} verticalAlign="middle">
                <Image src={imageUrl} style={{ height: 80, width: 80 }} />
              </Grid.Column>
              <Grid.Column width={14} verticalAlign="middle">
                <Text
                  as="h2"
                  style={{
                    marginBottom: 5,
                    textDecoration: allStepsComplete ? 'line-through' : 'none',
                  }}
                  color={allStepsComplete ? 'mediumGray' : 'black'}
                >
                  {title}
                </Text>
                <Text color="darkGray">
                  {stepsComplete}/{steps.length} complete
                </Text>
              </Grid.Column>
            </Grid.Row>
            {headerContent}
          </Grid>
        }
        titleStyle={titleStyle}
        content={[
          ...steps.map((stepProps) => (
            <StepCard {...stepProps} key={stepProps.key} readOnly={readOnly} />
          )),
          extraContent,
        ]}
        style={{
          backgroundColor: allStepsComplete ? Colors.transparent : Colors.white,
        }}
        initialOpen={!allStepsComplete}
      />
    </Card>
  )
}

const BookkeeperReviewStatusNotice = () => {
  const updateYourBooksProgress = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    UPDATE_YOUR_BOOKS
  )
  const firstReviewStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    REVIEW_INCOME_AND_EXPENSES_INITIAL
  )

  const booksLocked = useReselector(selectUserBooksLockedForCurrentTaxYear)

  if (
    updateYourBooksProgress !== StepStatus.completed ||
    firstReviewStatus !== StepStatus.completed
  ) {
    return null
  }

  return (
    <Card backgroundColor="stone" type="subsection">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon icon={regular('thumbs-up')} style={{ marginRight: 16 }} />
        <div>
          <Text as="h3" style={{ marginBottom: 5 }}>
            Bookkeeper Review
          </Text>
          <Text color="forest">
            {!booksLocked
              ? 'Your books are under review. We’ll let you know when it’s time to come back and approve changes.'
              : 'Your books are ready! Do one final review of any changes that may have been made.'}
          </Text>
        </div>
      </div>
    </Card>
  )
}

const CloseBookSection = ({ form }: { form: AnnualTaxFilingForm }) => {
  const dispatch = useAppDispatch()
  const firstReviewStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    REVIEW_INCOME_AND_EXPENSES_INITIAL
  )
  const firstReviewComplete = firstReviewStatus === StepStatus.completed
  const updateYourBooksProgress = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    UPDATE_YOUR_BOOKS
  )
  const updateYourBooksComplete =
    updateYourBooksProgress === StepStatus.completed
  const taxFiling = useReselector(
    getAnnualTaxFilingById,
    form.annualTaxFilingId
  )
  const booksLocked = useReselector(selectUserBooksLockedForCurrentTaxYear)
  const isLateJoiner = useReselector(selectIsLateJoinerByYear, form.year)

  const isCatchupBookkeepingCompleted = useReselector(
    selectIsCatchupBookkeepingCompleted,
    form.year
  )
  const readOnly = useReselector(selectTqIsReadOnly, form.id)
  const bookUpdatesComplete = useReselector(selectReviewBooksCompleted, false)
  // let BKs know books are ready for review
  const shouldSendZendeskTicket = useMemo(() => {
    return (
      taxFiling &&
      !taxFiling.endOfYearReviewCompletedAt &&
      bookUpdatesComplete &&
      !readOnly
    )
  }, [taxFiling, bookUpdatesComplete, readOnly])
  const isCurrentForm1120s = form.formType.name === TAX_ENTITY_TYPES.form_1120_s

  useEffect(() => {
    if (shouldSendZendeskTicket) {
      submitBooksComplete()(dispatch)
    }
  }, [shouldSendZendeskTicket, dispatch])

  const completedSteps = useMemo(
    () =>
      [updateYourBooksComplete, firstReviewComplete].filter((val) => val)
        .length,
    [updateYourBooksComplete, firstReviewComplete]
  )

  return (
    <SectionCard
      title={
        (isLateJoiner && isCatchupBookkeepingCompleted) || !isLateJoiner ? (
          `Close Your ${form.year} Books`
        ) : (
          <>
            {`Close Your ${form.year} Books`}{' '}
            <Pill
              style={{
                display: 'inline',
                backgroundColor: Colors.stone,
                color: Colors.oak,
              }}
              variant={'solid'}
            >
              coming soon
            </Pill>
          </>
        )
      }
      imageUrl="https://heard-images.s3.amazonaws.com/assets/books_calculator.svg"
      headerContent={
        isLateJoiner &&
        completedSteps !== 2 && (
          <GridRowColumn>
            <Card type={'subsection'}>
              <Grid>
                <GridRow>
                  <GridColumn
                    width={1}
                    style={{ alignItems: 'center', display: 'flex' }}
                  >
                    {isCatchupBookkeepingCompleted ? (
                      <Icon icon={regular('calendar')} />
                    ) : (
                      <Icon icon={solid('info-circle')} />
                    )}
                  </GridColumn>
                  <GridColumn width={15}>
                    <Text>
                      {isCatchupBookkeepingCompleted ? (
                        <>
                          Please complete the following two tasks
                          <Text
                            style={{
                              fontWeight: FontWeight.SEMIBOLD,
                              display: 'inline',
                            }}
                          >
                            {' '}
                            before March 15
                          </Text>
                        </>
                      ) : (
                        `You’ll be able to start this section once your catchup bookkeeping is complete. In the meantime, make sure that all your bank statements have been uploaded for ${form.year}.`
                      )}
                    </Text>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Card>
          </GridRowColumn>
        )
      }
      titleStyle={{ height: 'auto' }}
      steps={[
        {
          disabled: !isCatchupBookkeepingCompleted && isLateJoiner,
          icon: light('book-open-cover'),
          title: 'Update your books',
          key: 'Update your books',
          content: (
            <ul style={{ paddingLeft: 10, marginTop: 0 }}>
              <li>Clarify transactions</li>
              <li>Add business income</li>
              {isCurrentForm1120s && (
                <li>Review owner’s investments and distributions</li>
              )}
            </ul>
          ),
          path: TaxChecklistPaths.updateYourBooks,
          status: updateYourBooksProgress,
          hideStep: booksLocked && updateYourBooksComplete,
        },
        {
          disabled: !isCatchupBookkeepingCompleted && isLateJoiner,
          icon: light('chart-pie-simple-circle-dollar'),
          title: 'Review income and expenses',
          key: 'Review income and expenses',
          content:
            'Check your final numbers for tax reporting and to maximize your deductions.',
          path: TaxChecklistPaths.reviewIncomeExpense,
          status: firstReviewStatus,
          hideStep: booksLocked && firstReviewComplete,
        },
      ]}
      stepsComplete={completedSteps}
    />
  )
}

const ApproveSubmitSection = ({ form }: { form: AnnualTaxFilingForm }) => {
  const { makePayment, paymentLoading, paymentPending } =
    useTaxChecklistPayment({
      formId: form.id,
    })
  const booksLocked = useReselector(selectUserBooksLockedForCurrentTaxYear)
  const finalReviewEnabled = useReselector(
    selectFinalReviewEnabled,
    form.year,
    form.id
  )
  const formStatus = useReselector(selectFormTQStatus, form.id)
  const [modalOpen, setModalOpen] = useState(false)
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, form.year)

  const secondReviewStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    REVIEW_INCOME_AND_EXPENSES_FINAL
  )
  const paymentStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    COMPLETE_PAYMENT
  )
  const isNewSubscription = useReselector(selectIsNewScorpSubscription2023)
  const readyToSubmit = useReselector(
    selectTaxChecklistReadyToSubmit,
    form.year,
    form.id
  )
  const awaiting1120SCompletion = useReselector(
    selectAwaiting1120SCompletion,
    form.year,
    form.id
  )

  const submissionSteps = useMemo(() => {
    let finalizeTaxPrepContent

    if (finalReviewEnabled) {
      finalizeTaxPrepContent =
        'Let us know when you’re finished and ready for us to send your information to your tax preparer.'

      if (awaiting1120SCompletion) {
        finalizeTaxPrepContent +=
          " Your business tax return (Form 1120-S) must be filed before you can approve your Personal Tax Checklist. Once it's been filed, return here to approve the checklist."
      }
    } else if (booksLocked) {
      finalizeTaxPrepContent = awaiting1120SCompletion
        ? "Once you've completed all of the above remaining steps, your business tax-return (Form 1120-S) is fully filed and you've uploaded your K-1, you can let us know that you're finished and ready for us to send your information to your tax preparer."
        : "Once you've completed all of the above steps, you can let us know that you're finished and ready for us to send your information to your tax preparer."
    } else {
      finalizeTaxPrepContent = awaiting1120SCompletion
        ? "Once your bookkeeper has reviewed your books, you've completed all of the above remaining steps, and your business tax-return (Form 1120-S) is fully filed and you've uploaded your K-1, you can let us know that you're finished and ready for us to send your information to your tax preparer."
        : "Once your bookkeeper has reviewed your books, and you've completed all of the above steps, you can let us know that you're finished and ready for us to send your information to your tax preparer."
    }

    const steps: StepCardProps[] = [
      {
        icon: light('box-check'),
        title: 'Finalize for tax preparation',
        key: 'Finalize for tax preparation',
        content: finalizeTaxPrepContent,
        buttonText: 'Approve',
        action: () => setModalOpen(true),
        disabled:
          !readyToSubmit ||
          formStatus === AnnualTaxFilingStepStatus.completeLocked,
      },
    ]

    if (
      !isTwoFormFiler ||
      form.formType.name === TAX_ENTITY_TYPES.form_1120_s
    ) {
      let content

      if (finalReviewEnabled) {
        content = 'Make sure any changes made to your books are correct.'
      } else if (booksLocked) {
        content =
          "Please complete all of the above steps, and then you'll have a chance to review any changes that were made to your books."
      } else {
        content =
          'Once your bookkeeper has reviewed your books, and you’ve completed all of the above steps, you’ll have a chance to review any changes that were made to your books.'
      }

      steps.unshift({
        icon: light('chart-pie-simple-circle-dollar'),
        title: 'Review income and expenses',
        key: 'Review income and expenses',
        content,
        buttonText:
          secondReviewStatus === StepStatus.completed
            ? 'View'
            : secondReviewStatus === StepStatus.inProgress
              ? 'Continue'
              : 'Review',
        status: secondReviewStatus,
        disabled: !finalReviewEnabled,
        path: TaxChecklistPaths.reviewIncomeExpense,
      })
    }

    if (
      isTwoFormFiler &&
      !isNewSubscription &&
      form.formType.name === TAX_ENTITY_TYPES.form_1040
    ) {
      const paymentCompleted = paymentStatus === StepStatus.completed
      let buttonText = 'Pay'

      if (paymentCompleted) {
        buttonText = 'Done'
      } else if (paymentPending) {
        buttonText = 'Processing'
      }

      steps.unshift({
        icon: light('file-invoice-dollar'),
        title: 'Complete payment',
        key: 'Complete payment',
        content: paymentCompleted
          ? 'Thank you for your payment.'
          : `You’ll checkout with Stripe for the $${ADDITIONAL_1040_COST} fee of filing your personal tax return. once payment page is added`,
        buttonText,
        buttonIcon: paymentCompleted
          ? regular('check')
          : regular('arrow-up-right-from-square'),
        status: paymentStatus,
        action: makePayment,
        loading: paymentLoading,
        disabled: paymentLoading || paymentCompleted || paymentPending,
      })
    }
    return steps
  }, [
    finalReviewEnabled,
    booksLocked,
    readyToSubmit,
    formStatus,
    isTwoFormFiler,
    form.formType.name,
    isNewSubscription,
    awaiting1120SCompletion,
    secondReviewStatus,
    paymentStatus,
    paymentPending,
    makePayment,
    paymentLoading,
  ])

  const sectionProgressCount = useMemo(() => {
    let count = 0
    if (
      (!isTwoFormFiler ||
        form.formType.name === TAX_ENTITY_TYPES.form_1120_s) &&
      secondReviewStatus === StepStatus.completed
    ) {
      count++
    }
    if (
      isTwoFormFiler &&
      !isNewSubscription &&
      form.formType.name === TAX_ENTITY_TYPES.form_1040 &&
      paymentStatus === StepStatus.completed
    ) {
      count++
    }
    if (formStatus === AnnualTaxFilingStepStatus.completeLocked) {
      count++
    }
    return count
  }, [
    isTwoFormFiler,
    form.formType.name,
    isNewSubscription,
    paymentStatus,
    secondReviewStatus,
    formStatus,
  ])

  const title = useMemo(() => {
    return isTwoFormFiler &&
      !isNewSubscription &&
      form.formType.name === TAX_ENTITY_TYPES.form_1040
      ? 'Pay, Approve and Submit'
      : 'Approve and Submit'
  }, [isTwoFormFiler, isNewSubscription, form.formType.name])
  return (
    <>
      <SectionCard
        title={title}
        imageUrl="https://heard-images.s3.amazonaws.com/assets/circle_check.svg"
        steps={submissionSteps}
        stepsComplete={sectionProgressCount}
      />
      <ApproveAndSubmitModal
        formId={form.id}
        open={modalOpen}
        close={() => setModalOpen(false)}
      />
    </>
  )
}

const BusinessChecklist = ({ form }: { form: AnnualTaxFilingForm }) => {
  const checkYourDetailsStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHECK_YOUR_DETAILS_1120S
  )
  const docsSectionCounts = useReselector(
    selectDocsUploadedOrSkippedAndTotalCount,
    form.id
  )
  const readOnly = useReselector(selectTqIsReadOnly, form.id)

  const uploadDocumentStatus = useMemo(() => {
    if (docsSectionCounts.completeCount === docsSectionCounts.totalCount) {
      return StepStatus.completed
    } else if (docsSectionCounts.completeCount > 0) {
      return StepStatus.inProgress
    }
    return StepStatus.notStarted
  }, [docsSectionCounts])

  const annualTaxDetails = useReselector(selectTaxDetailsByYear, form.year)
  const checklistDueIso = form.isExtended
    ? annualTaxDetails?.irsFormDueDates.form_1120_s.internal.extendedDueDate
    : annualTaxDetails?.irsFormDueDates.form_1120_s.internal.dueDate
  const checklistDue = formatISOFromUTC(
    checklistDueIso,
    DATE_FORMATS_LUXON.DISPLAY_LONG
  )

  const { updateProgressData } = useProgressSteps({
    steps: UPLOAD_DOCUMENTS_1120S,
  })
  const allRequiredDocsUploaded = useReselector(
    selectAllRequiredDocsUploaded,
    form.id
  )
  const uploadDocsProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.uploadDocuments1120s
  )

  const completedStepCountTQ = useReselector(
    selectTaxQuestionnaireSectionsCompleteAndTotalCount,
    form.id
  )
  const itemCompleteTQ = useMemo(() => {
    return (
      completedStepCountTQ.completeCount === completedStepCountTQ.totalCount
    )
  }, [completedStepCountTQ])
  const itemCompleteCloseBooks = useReselector(
    selectReviewBooksCompleted,
    false
  )
  const businessTQSection = useMemo(
    () => (
      <SectionCard
        title="Business Tax Questionnaire (Form 1120-S)"
        imageUrl="https://heard-images.s3.amazonaws.com/assets/check_list.svg"
        steps={[
          {
            icon: light('memo-circle-check'),
            title: 'Check your details',
            key: 'Check your details',
            content: readOnly
              ? 'Review your personal and business information.'
              : 'Review your personal, business, and financial information and make any necessary updates.',
            path: readOnly
              ? TaxChecklistPaths.checkYourDetailsReadOnly
              : TaxChecklistPaths.checkYourDetails,
            status: checkYourDetailsStatus,
          },
          {
            icon: light('cloud-arrow-up'),
            title: 'Upload documents',
            key: 'Upload documents',
            content: readOnly
              ? 'Upload the tax documents needed for your return.'
              : `We’ll list which tax forms you need. You’ve uploaded ${docsSectionCounts.completeCount}/${docsSectionCounts.totalCount} documents.`,
            path: TaxChecklistPaths.documents,
            status: uploadDocumentStatus,
          },
        ]}
        stepsComplete={completedStepCountTQ.completeCount}
        readOnly={readOnly}
      />
    ),
    [
      checkYourDetailsStatus,
      completedStepCountTQ.completeCount,
      docsSectionCounts.completeCount,
      docsSectionCounts.totalCount,
      uploadDocumentStatus,
      readOnly,
    ]
  )

  const renderChecklistItems = useMemo(() => {
    // we want to render the checklist items in the following order
    // incomplete items first, then complete items
    const checklistItems = []
    // add incomplete
    if (!itemCompleteCloseBooks && !readOnly) {
      checklistItems.push(<CloseBookSection form={form} />)
    }
    if (!itemCompleteTQ) {
      checklistItems.push(businessTQSection)
    }
    if (!readOnly) {
      checklistItems.push(<ApproveSubmitSection form={form} />)
    }

    // add complete
    if (!readOnly && itemCompleteCloseBooks) {
      checklistItems.push(<CloseBookSection form={form} />)
    }
    if (itemCompleteTQ) {
      checklistItems.push(businessTQSection)
    }
    return checklistItems.map((item, index) => {
      return (
        <GridRowColumn key={index} spacer={1} width={14}>
          {item}
        </GridRowColumn>
      )
    })
  }, [
    itemCompleteCloseBooks,
    itemCompleteTQ,
    businessTQSection,
    form,
    readOnly,
  ])

  useEffect(() => {
    if (uploadDocsProgress?.id) {
      if (allRequiredDocsUploaded && !uploadDocsProgress.completedAt) {
        updateProgressData({
          completedSteps: [SubStepIdentifiers.uploadDocuments1120s],
        })
      }
      if (!allRequiredDocsUploaded && uploadDocsProgress.completedAt) {
        updateProgressData({
          incompleteSteps: [SubStepIdentifiers.uploadDocuments1120s],
        })
      }
    }
  }, [allRequiredDocsUploaded, updateProgressData, uploadDocsProgress])

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/tq_1120_s.svg"
          style={{ width: 212, height: 162 }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8}>
        <Text as="display2" textAlign="center">
          Business Tax Checklist (Form 1120-S)
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn spacer={4} width={8}>
          <Text textAlign="center">
            In order to prepare your tax return, we need additional information
            and documents from you. This checklist takes about <b>30 minutes</b>
            .
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn spacer={4} width={8}>
          <Text textAlign="center">
            You can no longer edit your Business Tax Questionnaire responses,
            but you can view them at anytime.
          </Text>
        </GridRowColumn>
      )}
      {!readOnly && (
        <GridRowColumn spacer={4} width={8}>
          <Text textAlign="center">
            <Icon
              color={'oak'}
              style={{ paddingRight: 8 }}
              icon={regular('calendar-check')}
            />{' '}
            Checklist Due <b>{checklistDue}</b>
          </Text>
        </GridRowColumn>
      )}
      {!readOnly && <ExtensionAlert is1040={false} inChecklist />}
      {!readOnly && (
        <GridRowColumn spacer={1} width={14}>
          <BookkeeperReviewStatusNotice />
        </GridRowColumn>
      )}
      {renderChecklistItems}
    </Grid>
  )
}

const PersonalChecklist = ({ form }: { form: AnnualTaxFilingForm }) => {
  //Missing QTE payments related
  const unpaidEstimates = useReselector(getUnpaidTaxEstimatesByYear, form.year)
  const qteProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.addMissingQTEPayments
  )
  const qteStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    ENTER_MISSING_QUARTERLY_TAX_PAYMENTS
  )

  const qteSecCompleted = qteStatus === StepStatus.completed

  const readOnly = useReselector(selectTqIsReadOnly, form.id)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, form.year)
  const checklistDueIso = form.isExtended
    ? annualTaxDetails?.irsFormDueDates.form_1040.internal.extendedDueDate
    : annualTaxDetails?.irsFormDueDates.form_1040.internal.dueDate
  const checklistDue = formatISOFromUTC(
    checklistDueIso,
    DATE_FORMATS_LUXON.DISPLAY_LONG
  )

  const unpaidEstimatesByTaxQuarter = useMemo(
    () => sortAndGroupEstimatesByTaxQuarter(unpaidEstimates),
    [unpaidEstimates]
  )
  const { updateProgressData, isFetching: isFetchingProgress } =
    useProgressSteps({
      steps: [
        SubStepIdentifiers.addMissingQTEPayments,
        ...UPLOAD_DOCUMENTS_1040,
      ],
    })

  const qtePaymentsCompleted = useMemo(
    () => Object.keys(unpaidEstimates).length === 0,
    [unpaidEstimates]
  )

  useEffect(() => {
    const checkAndSetQTEProgress = async () => {
      if (qteProgress?.id) {
        if (!qtePaymentsCompleted && qteProgress.completedAt) {
          await updateProgressData({
            incompleteSteps: [SubStepIdentifiers.addMissingQTEPayments],
          })
        }
      }
    }
    checkAndSetQTEProgress()
  }, [updateProgressData, qtePaymentsCompleted, qteProgress])

  const allRequiredDocsUploaded = useReselector(
    selectAllRequiredDocsUploaded,
    form.id
  )

  const uploadDocsProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.uploadDocuments1040
  )

  useEffect(() => {
    if (uploadDocsProgress?.id) {
      if (allRequiredDocsUploaded && !uploadDocsProgress.completedAt) {
        updateProgressData({
          completedSteps: [SubStepIdentifiers.uploadDocuments1040],
        })
      }
      if (!allRequiredDocsUploaded && uploadDocsProgress.completedAt) {
        updateProgressData({
          incompleteSteps: [SubStepIdentifiers.uploadDocuments1040],
        })
      }
    }
  }, [allRequiredDocsUploaded, updateProgressData, uploadDocsProgress])

  const checkYourDetailsStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHECK_YOUR_DETAILS_1040
  )
  const lifeChangesStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHANGES_IN_YEAR
  )
  const deductionsAndCreditsStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    DEDUCTIONS_AND_CREDITS
  )
  const incomeAndLiabilitiesStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    NON_THERAPY_INCOME_AND_LIABILITIES
  )
  const docsSectionCounts = useReselector(
    selectDocsUploadedOrSkippedAndTotalCount,
    form.id
  )

  const uploadDocumentStatus = useMemo(() => {
    if (docsSectionCounts.completeCount === docsSectionCounts.totalCount) {
      return StepStatus.completed
    } else if (docsSectionCounts.completeCount > 0) {
      return StepStatus.inProgress
    }
    return StepStatus.notStarted
  }, [docsSectionCounts])

  const personalTQStepsComplete = useMemo(() => {
    return [
      deductionsAndCreditsStatus,
      checkYourDetailsStatus,
      lifeChangesStatus,
      incomeAndLiabilitiesStatus,
      uploadDocumentStatus,
    ].filter((s) => s === StepStatus.completed).length
  }, [
    deductionsAndCreditsStatus,
    checkYourDetailsStatus,
    lifeChangesStatus,
    incomeAndLiabilitiesStatus,
    uploadDocumentStatus,
  ])

  const qteStepContent = useMemo(() => {
    if (qteSecCompleted) {
      return 'Thanks for updating your payments'
    }

    const unpaidQuarters = Object.keys(unpaidEstimatesByTaxQuarter)

    if (!unpaidQuarters.length) {
      return `Review and update your ${form.year} quarterly tax payments`
    }

    return (
      <>
        We are missing payments for the following quarters:
        {unpaidQuarters.map((taxQuarter) => (
          <li key={taxQuarter}>{formatTaxQuarter(taxQuarter)}</li>
        ))}
      </>
    )
  }, [form.year, qteSecCompleted, unpaidEstimatesByTaxQuarter])

  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, form.year)
  const completedStepCountTQ = useReselector(
    selectTaxQuestionnaireSectionsCompleteAndTotalCount,
    form.id
  )
  const itemCompleteTQ = useMemo(() => {
    return (
      completedStepCountTQ.completeCount === completedStepCountTQ.totalCount
    )
  }, [completedStepCountTQ])
  const itemCompleteCloseBooks = useReselector(
    selectReviewBooksCompleted,
    false
  )

  const qtePaymentsSection = useMemo(
    () => (
      <SectionCard
        title="Quarterly tax payments"
        imageUrl="https://heard-images.s3.amazonaws.com/assets/check_book.svg"
        steps={[
          {
            loading: isFetchingProgress,
            icon: light('circle-quarter-stroke'),
            title: qteSecCompleted
              ? 'Review quarterly tax payments'
              : 'Enter missing quarterly tax payments',
            key: 'Enter missing quarterly tax payments',
            status: qteStatus,
            path: TaxChecklistPaths.addMissingQtePayments,
            content: qteStepContent,
          },
        ]}
        stepsComplete={qteSecCompleted ? 1 : 0}
        readOnly={readOnly}
      />
    ),
    [isFetchingProgress, qteSecCompleted, qteStatus, qteStepContent, readOnly]
  )

  const personalTQSection = useMemo(
    () => (
      <SectionCard
        title={'Personal Tax Questionnaire (Form 1040)'}
        imageUrl="https://heard-images.s3.amazonaws.com/assets/check_list.svg"
        steps={[
          {
            icon: light('memo-circle-check'),
            title: 'Check your details',
            key: 'Check your details',
            content: 'Review your personal and business information.',
            path: readOnly
              ? TaxChecklistPaths.checkYourDetailsPersonalReadOnly
              : TaxChecklistPaths.checkYourDetailsPersonal,
            status: checkYourDetailsStatus,
          },
          {
            icon: light('sparkles'),
            title: `Changes in ${form.year}`,
            key: `Changes in ${form.year}`,
            content: 'Fill us in on any big life changes.',
            path: readOnly
              ? TaxChecklistPaths.lifeChangesReadOnly
              : TaxChecklistPaths.lifeChanges,
            status: lifeChangesStatus,
          },
          {
            icon: light('magnifying-glass-dollar'),
            title: 'Deductions and credits',
            key: 'Deductions and credits',
            status: deductionsAndCreditsStatus,
            path: readOnly
              ? TaxChecklistPaths.deductionsAndCreditsReadOnly
              : TaxChecklistPaths.deductionsAndCredits,
            content: 'Answer a few questions about your personal expenses.',
          },
          {
            icon: light('hands-holding-dollar'),
            title: (
              <>
                Non-therapy income and liabilities
                <Popup
                  content={
                    <>
                      <Text as="h3">Non-therapy income and liabilities</Text>
                      <br />
                      <Text as="bodyLg">
                        This refers to any income you earn outside of your
                        private practice (i.e. investments, other businesses,
                        bank account interest) as well as any personal financial
                        situations (i.e. retirement contributions, donating to
                        charities, etc.)
                      </Text>
                    </>
                  }
                />
              </>
            ),
            key: 'Non-therapy income and liabilities',
            status: incomeAndLiabilitiesStatus,
            content:
              'Answer a few questions about your personal income and finances.',
            path: readOnly
              ? TaxChecklistPaths.incomeAndLiabilitiesReadOnly
              : TaxChecklistPaths.incomeAndLiabilities,
          },
          {
            icon: light('cloud-arrow-up'),
            title: 'Upload documents',
            key: 'Upload documents',
            content: `We'll build a list of documents to upload based on your answers to the above sections. You’ve uploaded ${docsSectionCounts.completeCount}/${docsSectionCounts.totalCount} documents.`,
            path: readOnly
              ? TaxChecklistPaths.documentsReadOnly
              : TaxChecklistPaths.documents,
            status: uploadDocumentStatus,
          },
        ]}
        stepsComplete={personalTQStepsComplete}
        readOnly={readOnly}
      />
    ),
    [
      checkYourDetailsStatus,
      deductionsAndCreditsStatus,
      docsSectionCounts.completeCount,
      docsSectionCounts.totalCount,
      form.year,
      incomeAndLiabilitiesStatus,
      lifeChangesStatus,
      personalTQStepsComplete,
      uploadDocumentStatus,
      readOnly,
    ]
  )

  const renderChecklistItems = useMemo(() => {
    // we want to render the checklist items in the following order
    // incomplete items first, then complete items
    const checklistItems = []
    // add incomplete
    if (!qteSecCompleted) {
      checklistItems.push(qtePaymentsSection)
    }
    if (!readOnly && !isTwoFormFiler && !itemCompleteCloseBooks) {
      checklistItems.push(<CloseBookSection form={form} />)
    }
    if (!itemCompleteTQ) {
      checklistItems.push(personalTQSection)
    }
    if (!readOnly) {
      checklistItems.push(<ApproveSubmitSection form={form} />)
    }
    // add complete
    if (qteSecCompleted) {
      checklistItems.push(qtePaymentsSection)
    }
    if (!readOnly && !isTwoFormFiler && itemCompleteCloseBooks) {
      checklistItems.push(<CloseBookSection form={form} />)
    }
    if (itemCompleteTQ) {
      checklistItems.push(personalTQSection)
    }

    return checklistItems.map((item, index) => {
      return (
        <GridRowColumn key={index} spacer={1} width={14}>
          {item}
        </GridRowColumn>
      )
    })
  }, [
    form,
    isTwoFormFiler,
    itemCompleteCloseBooks,
    itemCompleteTQ,
    personalTQSection,
    qtePaymentsSection,
    qteSecCompleted,
    readOnly,
  ])

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/tq_1040.svg"
          style={{ width: 212, height: 162 }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8}>
        <Text as="display2" textAlign="center">
          Personal Tax Checklist (Form 1040)
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn spacer={4} width={8}>
          <Text textAlign="center">
            In order to prepare your tax return, we need additional information
            and documents from you. This checklist takes about <b>1 hour</b>.
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn spacer={4} width={8}>
          <Text textAlign="center">
            You can no longer edit your responses, but you can still view
            answers for your Personal Tax Questionnaire and quarterly tax
            payments.
          </Text>
        </GridRowColumn>
      )}
      {!readOnly && (
        <>
          <GridRowColumn spacer={4} width={8}>
            <Text textAlign="center">
              <Icon
                color={'oak'}
                style={{ paddingRight: 8 }}
                icon={regular('calendar-check')}
              />{' '}
              Checklist Due <b>{checklistDue}</b>
            </Text>
          </GridRowColumn>
          <ExtensionAlert is1040 inChecklist />
        </>
      )}
      {!readOnly && !isTwoFormFiler && (
        <GridRowColumn spacer={1} width={14}>
          <BookkeeperReviewStatusNotice />
        </GridRowColumn>
      )}
      {renderChecklistItems}
    </Grid>
  )
}

const TaxChecklist = () => {
  const dispatch = useAppDispatch()
  const { formId } = useParams()
  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
    FETCH_USER_TAX_QUESTIONNAIRE_KEY(formId),
  ])
  const error = useReselector(selectErrorsForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
  ])
  const form = useReselector(selectAnnualTaxFilingFormById, formId)
  const readOnly = useReselector(selectTqIsReadOnly, formId)

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchUserTaxEstimatesIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    form?.year && dispatch(fetchUserEoyReviewProgress(form?.year))
  }, [dispatch, form?.year])

  useEffect(() => {
    form?.year && dispatch(fetchUserTaxQuestionnaire(form.year))
  }, [dispatch, form?.year])

  return (
    <>
      <PageHeader
        header=""
        backControl={{ link: '/taxes/annual', text: 'Back to Tax Center' }}
      />
      <Grid>
        {isLoading && (
          <GridRowColumn>
            <Loader loading={isLoading} />
          </GridRowColumn>
        )}
        {!isLoading && (error.length > 0 || !form) && (
          <GridRowColumn>
            <Alert type="error">Something went wrong</Alert>
          </GridRowColumn>
        )}
        {form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s && (
          <GridRowColumn>
            <BusinessChecklist form={form} />
          </GridRowColumn>
        )}
        {form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040 && (
          <GridRowColumn>
            <PersonalChecklist form={form} />
          </GridRowColumn>
        )}
        <Grid.Row />
        {!readOnly && (
          <GridRowColumn spacer={4} width={8}>
            <Accordion
              title="What do I need to complete this checklist?"
              content={
                <Text>
                  <ul style={{ paddingLeft: 10, marginTop: 0 }}>
                    <li>Access to all business bank accounts</li>
                    <li>All the tax forms you’ve received so far</li>
                    <li>
                      Additional details around your business expenses and
                      owner’s investments
                    </li>
                  </ul>
                </Text>
              }
              variant="default"
            />
            <Accordion
              title="Is my progress saved?"
              content="Yes, your progress is automatically saved, so feel free to exit and come back."
              variant="default"
            />
            <Accordion
              title="What if I don’t know how to answer something?"
              content="If you have questions or feel stuck, please reach out to the Heard Tax Team via Conversations— they’ll help you move forward."
              variant="default"
            />
            <Accordion
              title="What if I don’t have a form or document?"
              content={
                <Text>
                  In order to move through your tax return without any pauses or
                  hiccups, we require all tax documents to be uploaded. If you
                  need help locating a document, please reach out to the Heard
                  Tax Team or check out our{' '}
                  <Link
                    href="https://support.joinheard.com/hc/en-us/articles/19233040314775-What-tax-documents-should-I-be-looking-out-for-And-when-"
                    newPage
                  >
                    Tax Document FAQs
                  </Link>
                  .
                  <br />
                  <br />
                  You can come back later once you’ve located all the necessary
                  documents. If you haven’t completed your checklist by{' '}
                  <b>
                    {form?.formType.name === TAX_ENTITY_TYPES.form_1120_s
                      ? 'March'
                      : 'April'}{' '}
                    1, {Number(form?.year) + 1}
                  </b>
                  , we’ll automatically file an extension for you.
                  <Popup
                    content={
                      <>
                        <Text as="h3">Extension Filing</Text>
                        <Text as="bodyLg">
                          The reason we put customers (who are late on
                          completing the tax questionnaire) on extensions is to
                          prevent them from paying penalties for late filings.
                          <br />
                          <br />
                          There’s nothing bad about filing an extension for your
                          tax return. It simply postpones the process until you
                          have all of your information gathered. However, you’ll
                          still need to pay an estimated tax amount before the
                          filing deadline to avoid a penalty.
                        </Text>
                      </>
                    }
                  />
                </Text>
              }
              variant="default"
            />
          </GridRowColumn>
        )}
        <Grid.Row />
        <Grid.Row />
      </Grid>
    </>
  )
}

export default TaxChecklist
