import axios from 'axios'
import { fetchIfNeededWrapper } from '../../../reducers/fetch'
import {
  TaxfyleCall,
  TaxfyleJob,
} from '../AnnualTaxes/annualTaxFilingForms.slice'

export enum PilotFeatureFlagValues {
  noPilot = 'no_pilot',
  heardScheduled = 'heard_scheduled',
  userScheduled = 'user_scheduled',
}

export enum JobServices {
  consultation30Min = 'Consultation (30 Minutes)',
  consultation60Min = 'Consultation (60 Minutes)',
}

export const FETCH_ACTIVE_TAX_CONSULTATIONS = 'FETCH_ACTIVE_TAX_CONSULTATIONS'
export const fetchActiveTaxConsultationsIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ACTIVE_TAX_CONSULTATIONS,
    fetchFunction: async () => {
      const json = await axios.get<{
        taxfyleJobs: TaxfyleJob[]
        taxfyleCalls: TaxfyleCall[]
      }>('/finances/api/v1/tax_consultations/active')
      return json.data
    },
  })

export const FETCH_TAX_CONSULTATION_URL = 'FETCH_TAX_CONSULTATION_URL'
export const fetchTaxConsultationUrl = (jobId: string) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_TAX_CONSULTATION_URL,
    fetchFunction: async () => {
      const json = await axios.get<{ link: string }>(
        `/finances/api/v1/tax_consultations/${jobId}/link`
      )
      return json.data.link
    },
  })

export const FETCH_TAX_CONSULTATION_ADVISOR = 'FETCH_TAX_CONSULTATION_ADVISOR'
export const fetchTaxConsultationAdvisor = (jobId: string) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_TAX_CONSULTATION_ADVISOR,
    fetchFunction: async () => {
      const json = await axios.get<{ advisor: string }>(
        `/finances/api/v1/tax_consultations/${jobId}/advisor`
      )
      return json.data.advisor
    },
  })
