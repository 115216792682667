import React from 'react'
import moment from 'moment'
import { Label, Table } from 'semantic-ui-react'
import { FinancialAccountReconciliationWithTransactions } from '../../../../reducers/admin/allFinancialAccountReconciliationsReducer'
import { FinancialAccountWithAdminInfo } from '../../../../reducers/admin/financialAccountsReducer'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

const FinancialAccountReconciliationsTableRow = ({
  financialAccount,
  financialAccountReconciliation,
}: {
  financialAccount?: FinancialAccountWithAdminInfo
  financialAccountReconciliation: FinancialAccountReconciliationWithTransactions
}) => {
  const accountDisplayName = `${financialAccount?.plaidInstitutionName} (${financialAccount?.mask})`
  const status = financialAccountReconciliation.status

  return (
    <Table.Row key={financialAccountReconciliation.id}>
      <Table.Cell>{financialAccountReconciliation.id}</Table.Cell>
      <Table.Cell>
        {moment
          .utc(financialAccountReconciliation.startingBalanceDate)
          .format(DATE_FORMATS.DISPLAY_LONG)}
      </Table.Cell>
      <Table.Cell>
        {formatCurrencyFromCents(
          financialAccountReconciliation.startingBalanceInCents
        )}
      </Table.Cell>
      <Table.Cell>
        {moment
          .utc(financialAccountReconciliation.endingBalanceDate)
          .format(DATE_FORMATS.DISPLAY_LONG)}
      </Table.Cell>
      <Table.Cell>
        {formatCurrencyFromCents(
          financialAccountReconciliation.endingBalanceInCents
        )}
      </Table.Cell>
      <Table.Cell>{accountDisplayName}</Table.Cell>
      <Table.Cell>
        <Label color={status === 'complete' ? 'green' : 'red'}>{status}</Label>
      </Table.Cell>
    </Table.Row>
  )
}

export default FinancialAccountReconciliationsTableRow
