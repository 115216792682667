import { useEffect, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  Alert,
  DatePicker,
  GridRowColumn,
  Link,
  Text,
} from '../../BaseComponents'
import { ArticleCard } from '../shared/ArticleCard'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  useReselector,
  useToggle,
  useTrackFinancialInsightsButtonClick,
} from '../../../utils/sharedHooks'
import {
  ARTICLE_TYPE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
} from '../shared/utils'
import InsightsBreakdownTable from '../shared/InsightsBreakdownTable'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { UncategorizedTransactionBanner } from '../shared/UncategorizedTransactionBanner'
import { isEmpty } from 'lodash'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { CurrentPanel } from '../shared/CurrentPanel'
import { TrackInsightsSection } from '../shared/TrackInsightsSection'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { BannerCard } from '../../BaseComponents/BannerCard'
import { useAppDispatch } from '../../../utils/typeHelpers'

const InsightsExpensesPanel = () => {
  const dispatch = useAppDispatch()
  const [displayBanner, toggleBanner] = useToggle(true)
  const [inputDate, setInputDate] = useState('')

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const linkedPlaidAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasLinkedPlaidAccounts = !isEmpty(linkedPlaidAccounts)

  const trackInsights = useTrackFinancialInsightsButtonClick()

  const shouldDisplayDatePicker = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.insightsDatePicker,
    false
  )

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  const closeBanner = () => {
    toggleBanner()
    trackInsights({
      button: FinancialInsightsButtonType.CLOSE_BANNER,
      insightsType: INSIGHTS_TYPE.EXPENSES,
    })
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: 20,
      }}
    >
      <Grid>
        <GridRowColumn
          columnStyle={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text as="h1"> Expenses </Text>
          {shouldDisplayDatePicker && (
            <DatePicker value={inputDate} onChange={setInputDate} clearable />
          )}
        </GridRowColumn>
        {displayBanner && (
          <GridRowColumn>
            <BannerCard
              headerText="What are Business Expenses?"
              imageUrl="https://heard-images.s3.amazonaws.com/assets/chart-tablet-banner.svg"
              onClose={closeBanner}
              bodyContent={
                <>
                  Business expenses are ordinary and necessary costs incurred to
                  operate your business, like rent, professional fees, and
                  software services. These are your consistent expenses that
                  occur every month.
                  <br />
                  <br />
                  Note: What about my expenses that aren&apos;t every month? For
                  expenses that are less often, like paying for licenses or
                  continuing education, decide on a percentage to set aside for
                  your “Operating Expenses” in your{' '}
                  <Link to="/allocation-guides" style={{ fontSize: 14 }}>
                    Allocation guide
                  </Link>
                  .
                </>
              }
            />
          </GridRowColumn>
        )}
        {!hasLinkedPlaidAccounts && (
          <GridRowColumn>
            <Alert>
              <Text>
                You do not have a connected bank account.{' '}
                <Link to={'/accounts#connected-institutions'}>
                  Link Business Account
                </Link>
              </Text>
            </Alert>
          </GridRowColumn>
        )}
        <UncategorizedTransactionBanner inputDate={inputDate} />
        <CurrentPanel
          inputDate={inputDate}
          insightsType={INSIGHTS_TYPE.EXPENSES}
        />
        <TrackInsightsSection
          inputDate={inputDate}
          type={INSIGHTS_TYPE.EXPENSES}
        />
        <GridRowColumn style={{ paddingBottom: 0 }}>
          <Text as="h2" style={{ marginBottom: 12 }}>
            Expenses Breakdown
          </Text>
          <Text color="darkGray">
            Look at your expense spending in detail. Save receipts for
            deductible categories.
          </Text>
        </GridRowColumn>
        <InsightsBreakdownTable
          inputDate={inputDate}
          insightsType={INSIGHTS_TYPE.EXPENSES}
        />
        <GridRowColumn>
          <Text as="h2" style={{ marginBottom: 12 }}>
            Learn More
          </Text>
          <Text color="darkGray">
            Learn more about improving your expense spending
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Divider style={{ padding: 0, margin: 0 }} />
        </GridRowColumn>
        <Grid.Row className="short" columns={isMobile ? 1 : 3}>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="How to Build a Budget for Your Therapy Practice"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/how-to-build-a-budget-for-your-therapy-practice"
              insightsType={INSIGHTS_TYPE.EXPENSES}
            />
          </Grid.Column>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="How to Determine if Something is a Tax Deduction for Therapists (with Examples)"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/how-to-determine-if-something-is-a-tax-deduction-for-therapists-with-examples"
              insightsType={INSIGHTS_TYPE.EXPENSES}
            />
          </Grid.Column>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="What Therapists Need to Know About Deducting Education Expenses"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/what-therapists-need-to-know-about-deducting-education-expenses"
              insightsType={INSIGHTS_TYPE.EXPENSES}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default InsightsExpensesPanel
