import { Component, useMemo } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Moment from 'react-moment'
import {
  Breadcrumb,
  Button,
  Card,
  Confirm,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Label,
  Loader,
  Menu,
  Popup,
  Tab,
  Table,
} from 'semantic-ui-react'

import { adminFetchSingleUser } from '../../../actions/admin/adminAllUsersActions'
import { fetchTransactionCounts } from '../../../actions/adminActions'
import { fetchAdminUsersDocuments } from '../../../actions/admin/userDocumentsActions'
import {
  fetchTransactionCountsSinceLastReconciliation,
  fetchUserFinancialAccounts,
} from '../../../actions/admin/adminFinancialAccountActions'
import {
  fetchAdminUsersBookkeepingReports,
  updateUsersBookkeepingReportStatus,
} from '../../../actions/admin/bookkeepingReportActions'
import BookkeepingStatusLabel from '../UserRecord/BookkeepingStatusLabel'
import BookkeepingStatusCard from './BookkeepingStatusCard'
import TransactionTable from '../TransactionTable'
import PaginatedTransactionTable from '../PaginatedTransactionTable'
import ProfitAndLossTable from './ProfitAndLossTable'
import UserRecordNotes from '../UserRecord/UserRecordNotes'
import ReconciliationModal, {
  ReconciliationModalProps,
} from '../Reconciliation/Screens/ReconciliationModal'
import { toUTCDateString } from '../Reconciliation/service'
import {
  clearAndUpdateTransactionFilters,
  clearTransactionFilters,
} from '../../../reducers/finances/transactionFiltersReducer'
import {
  Dispatch,
  ReduxState,
  wrapDispatch,
  filterNulls,
} from '../../../utils/typeHelpers'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'
import {
  getUserById,
  selectAdminUserState,
} from '../../../selectors/user.selectors'
import { withRouter } from '../../../utils/routeHelpers'
import UserBreadcrumbs from '../UserRecord/UserBreadcrumbs'
import {
  getUserFinancialAccounts,
  selectSortedFinancialAccounts,
} from '../../../selectors/financeSelectors'
import { Alert, Text } from '../../BaseComponents'
import SmartRuleCreationModal from '../../../features/Admin/SmartRuleCreation/SmartRuleCreationModal'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectBookkeepingReportsById,
  selectBookkeepingReportStatus,
} from '../../../selectors/bookkeepingReportsSelectors'
import TransactionCountsCard from './TransactionCountsCard'
import {
  BookkeepingReport,
  BookkeepingReportStatus,
} from '../../../reducers/finances/bookkeepingReportsReducer'
import { selectUserDocumentsByReportId } from '../../../features/UserDocuments/userDocuments.selector'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import DownloadPayrollReportMenuItem from './DownloadPayrollReportMenuItem'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import UserAccountCanceledAlert from '../UserRecord/UserAccountStates/UserAccountCanceledAlert'
import UserStateLabel from '../UserRecord/UserAccountStates/UserStateLabel'
import { Colors } from '../../../styles/theme'
import { useBooleanFlagDetails } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { OnboardingAnswers } from '../UserRecord/OnboardingAnswers'

const DocumentsTable = ({
  userId,
  reportId,
}: {
  userId: string
  reportId?: string
}) => {
  const accounts = useReselector(getUserFinancialAccounts, userId)
  const releaseStatementUpload = useBooleanFlagDetails(
    FEATURE_FLAG_KEYS.multiStatementUpload,
    false
  )
  const statements = useReselector(
    selectUserDocumentsByReportId,
    userId,
    reportId
  )

  const filteredAccounts = useMemo(() => {
    if (!accounts) return []
    return filterNulls(Object.values(accounts)).filter((account) => {
      if (account.inactive) return false
      return (
        // filter out accounts where a statement exists with that financial account id
        !statements.some((statement) =>
          statement?.statement?.statementFinancialAccounts?.some(
            (statementAccount) =>
              statementAccount.financialAccountId === account.id
          )
        )
      )
    })
  }, [accounts, statements])

  const renderAccountsTable = () => {
    return (
      <>
        {filteredAccounts.map((account) => {
          return (
            <Table.Row
              key={account.id}
              style={{ backgroundColor: Colors.lightOrange }}
            >
              <Table.Cell>
                <Text as="bodyXs">
                  <b> {account.plaidInstitutionName} </b>
                </Text>
                <Text as="bodyXs">
                  {account?.name}:{account?.mask}
                </Text>
              </Table.Cell>
              <Table.Cell>
                {account.statementPermissions === 'plaid_statement' ? (
                  <Text as="bodyXs"> Plaid Statements </Text>
                ) : account.bankAccessEnabledAt ? (
                  <Text as="bodyXs">Limited Bank Access</Text>
                ) : (
                  <Text as="bodyXs"> Manual User Upload </Text>
                )}
              </Table.Cell>
              <Table.Cell> N/A </Table.Cell>
            </Table.Row>
          )
        })}
      </>
    )
  }
  const renderTable = () => {
    if (statements && statements.length > 0) {
      return statements.map(
        ({
          id,
          statementFinancialAccountId,
          signedUrl,
          fileDescription,
          createdAt,
          statement,
        }) => {
          const firstAccount = statement?.statementFinancialAccounts?.[0]
          const institution = firstAccount
            ? accounts?.[firstAccount.financialAccountId]?.plaidInstitutionName
            : null
          const isLBA = firstAccount
            ? accounts?.[firstAccount.financialAccountId]?.bankAccessEnabledAt
            : false
          return (
            <Table.Row key={id}>
              {releaseStatementUpload && (
                <Table.Cell>
                  {institution && (
                    <Text as="bodyXs">
                      <b> {institution} </b>
                    </Text>
                  )}
                  {accounts &&
                    statement?.statementFinancialAccounts?.map(
                      ({ financialAccountId }) => {
                        return (
                          <Text
                            key={financialAccountId}
                            as="bodyXs"
                            style={{ marginBottom: 6 }}
                          >
                            {accounts[financialAccountId]?.name}:
                            {accounts[financialAccountId]?.mask}
                          </Text>
                        )
                      }
                    )}
                </Table.Cell>
              )}

              {!releaseStatementUpload && (
                <Table.Cell>
                  {statementFinancialAccountId && accounts
                    ? `${accounts[statementFinancialAccountId]?.name}: ${accounts[statementFinancialAccountId]?.mask}`
                    : null}
                </Table.Cell>
              )}
              <Table.Cell>
                <a
                  href={signedUrl || undefined}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    overflowWrap: 'anywhere',
                    fontSize: 12,
                  }}
                >
                  {isLBA ? 'Limited Bank Access' : fileDescription}
                </a>
              </Table.Cell>
              <Table.Cell>
                <Moment format={DATE_FORMATS.DISPLAY_SHORT}>{createdAt}</Moment>
              </Table.Cell>
            </Table.Row>
          )
        }
      )
    }
    return (
      <Table.Row>
        <Table.Cell colSpan="3" textAlign="center">
          <Header as="h6">No Documents</Header>
        </Table.Cell>
      </Table.Row>
    )
  }
  return (
    <Table striped compact="very">
      {statements && statements.length > 0 && (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Account</Table.HeaderCell>
            <Table.HeaderCell width={4}>File Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Uploaded</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>
        {renderTable()}
        {renderAccountsTable()}
      </Table.Body>
    </Table>
  )
}

const StatementCard = ({
  reportId,
  userId,
}: {
  reportId?: string
  userId: string
}) => {
  return (
    <Card fluid>
      <Card.Content>
        <Header as="h5">Statements</Header>
        <DocumentsTable userId={userId} reportId={reportId} />
      </Card.Content>
    </Card>
  )
}

const TIME_FORMAT = 'h:mm:ss a'

// 4 total states, 2 are derived
const statuses = (date?: string, count?: number) => {
  const subtitleDate = date
    ? toUTCDateString({
        value: date,
        format: DATE_FORMATS.DISPLAY_LONG,
      })
    : ''
  const popupDate = date
    ? toUTCDateString({ value: date, format: DATE_FORMATS.DISPLAY_SHORT })
    : ''
  const popupTime = date
    ? toUTCDateString({ value: date, format: TIME_FORMAT })
    : ''

  return {
    complete: {
      color: 'green' as const,
      label: 'complete',
      subtitle: `Reconciled on ${subtitleDate} 🥳`,
      type: 'complete',
      popupText: `Completed on ${popupDate} at ${popupTime} UTC`,
    },
    incomplete: {
      color: 'red' as const,
      label: 'incomplete',
      subtitle: `Resume from ${subtitleDate}`,
      type: 'incomplete',
      popupText: `Last edited on ${popupDate} at ${popupTime} UTC`,
    },
    // derived - - -
    needs_updating: {
      color: 'yellow' as const,
      label: 'needs updating',
      type: 'needs_updating',
      subtitle: `There are ${count || 0} new transaction(s)`,
      popupText: `${
        count || 0
      } transaction(s) have arrived since you last reconciled on ${popupDate} at ${popupTime} UTC`,
    },
    never_reconciled: {
      color: 'red' as const,
      label: 'never reconciled',
      subtitle: 'This acct has never been reconciled',
      type: 'never_reconciled',
      popupText: "Click 'Reconcile' to begin your first reconciliation",
    },
    // - - - end derived
  }
}

const getAccountStatus = (
  account: FinancialAccountWithAdminInfo,
  newTransactionCounts: { [key: number]: number }
) => {
  let status

  if (account.reconciliations.length === 0) {
    status = statuses().never_reconciled
    return status
  }

  // reconciliations are sorted by most recent first
  const { reconciliations } = account
  const mostRecent = reconciliations[0]

  status = statuses(mostRecent.updatedAt)[mostRecent.status]

  if (mostRecent.status === 'complete') {
    // status is complete in DB, but need to check if new transactions exist
    const count = newTransactionCounts[account.id]

    if (count > 0) {
      // if new transactions exist, set status to 'needs updating'
      status = statuses(mostRecent.updatedAt, count).needs_updating
    }
  }

  return status
}

const AccountSumCards = ({
  financialAccounts,
  newTransactionCounts,
  onReconcileClick,
}: {
  financialAccounts: FinancialAccountWithAdminInfo[]
  newTransactionCounts: { [key: number]: number }
  onReconcileClick: (
    formType: string,
    account: FinancialAccountWithAdminInfo
  ) => void
}) => {
  const style = {
    card: { position: 'relative' },
    inactive: { opacity: '0.5' },
    label: { margin: '2px' },
    subtitle: { textAlign: 'center' as const, textTransform: 'none' as const },
  }

  return (
    <Card.Group itemsPerRow={4}>
      {financialAccounts.map((account) => {
        const status = getAccountStatus(account, newTransactionCounts)

        return (
          <Card
            style={account.inactive ? style.inactive : {}}
            fluid
            key={account.id}
          >
            <Card.Content>
              {account.inactive && (
                <Label style={style.label} color="orange">
                  inactive account
                </Label>
              )}
              {account.statementPermissions === 'plaid_statement' ? (
                <Label style={style.label} color="blue">
                  Plaid Statement
                </Label>
              ) : account.bankAccessEnabledAt ? (
                <Label style={style.label} color="violet">
                  LBA
                </Label>
              ) : null}
              <Label
                style={style.label}
                color={account.accountType === 'plaid' ? 'green' : 'red'}
              >
                {account.accountType}
              </Label>
              <Label
                style={style.label}
                basic={status.type === 'never_reconciled'}
                color={status.color}
              >
                {status.label}
              </Label>
            </Card.Content>
            <Card.Content>
              <Card.Header>
                <Header as="h4" style={{ paddingLeft: 0 }}>
                  {account.plaidInstitutionName}
                </Header>
              </Card.Header>
              <Card.Description>
                <p>
                  <b>{account.name}:</b> {account.mask}
                </p>
                <p>
                  {account.type}&nbsp;—&nbsp;{account.subtype}
                </p>
              </Card.Description>
            </Card.Content>

            <Card.Content extra>
              <Popup
                inverted
                disabled={account.inactive}
                content={`id ${account.id}: ${status.popupText}`}
                trigger={
                  <p color={status.color} style={style.subtitle}>
                    {status.subtitle}
                  </p>
                }
              />
            </Card.Content>

            {status.type !== 'complete' && !account.inactive && (
              <Card.Content style={{ margin: '0 auto' }}>
                <Button
                  color="blue"
                  onClick={() => {
                    // Passing "status.type" instead of "reconciliation.status"
                    // b/c reconciliation.status does not include derived types [needs_updating, never_reconciled]
                    onReconcileClick(status.type, account)
                  }}
                >
                  Reconcile
                </Button>
              </Card.Content>
            )}
          </Card>
        )
      })}
    </Card.Group>
  )
}

const getReportTransactionQueryProps = (
  userId: number,
  report: BookkeepingReport | undefined
) => {
  const startDate = report
    ? moment(report.startDate, 'YYYY-MM').startOf('month').format('MM-DD-YYYY')
    : undefined
  const endDate = report
    ? moment(report.endDate, 'YYYY-MM').endOf('month').format('MM-DD-YYYY')
    : undefined
  return {
    userId,
    startDate,
    endDate,
  }
}

type ParentProps = { params: { userId: string; reportId: string } }
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>

type Props = ParentProps & DispatchProps & StateProps

interface State {
  loading: boolean
  inProgressOpen: boolean
  completeOpen: boolean
  reconciledOpen: boolean
  doneOpen: boolean
  smartRuleOpen: boolean
  reconciliationModalProps: ReconciliationModalProps | null
  newTransactionCounts: { [key: number]: number }
  showNewTransactionTable: boolean
}

const UserRecordHeader = ({ user }: { user?: UserWithAdminInfo }) => {
  const userState = useReselector(selectAdminUserState, user?.id)

  if (!userState) {
    return null
  }

  return (
    <div>
      <Header as="h3" {...(userState === 'unpaid' && { color: 'red' })}>
        <UserStateLabel user={user} />
        <UserAccountCanceledAlert user={user} />
      </Header>
    </div>
  )
}

class MonthlyBooksPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
      inProgressOpen: false,
      completeOpen: false,
      reconciledOpen: false,
      doneOpen: false,
      smartRuleOpen: false,
      reconciliationModalProps: null,
      newTransactionCounts: {},
      showNewTransactionTable: false,
    }
  }

  async componentDidMount() {
    const {
      params: { userId },
      report,
    } = this.props

    const [newTransactionCounts] = await Promise.all([
      this.props.fetchTransactionCountsSinceLastReconciliation(userId),
      this.props.adminFetchSingleUser(userId),
      this.props.clearAndUpdateTransactionFilters(
        getReportTransactionQueryProps(Number(userId), report)
      ),
      this.props.fetchAdminUsersBookkeepingReports(userId),
      this.props.fetchAdminUsersDocuments(userId),
      this.props.fetchUserFinancialAccounts(userId),
    ])

    this.setState({ loading: false })

    if (newTransactionCounts) {
      this.setState({ newTransactionCounts })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      params: { userId },
      report,
    } = this.props

    if (report && report?.id !== prevProps.report?.id) {
      this.props.clearAndUpdateTransactionFilters(
        getReportTransactionQueryProps(Number(userId), report)
      )
    }
  }

  componentWillUnmount() {
    this.props.clearTransactionFilters()
  }

  handleBooksUpdate = async (status: BookkeepingReportStatus) => {
    const {
      params: { reportId },
    } = this.props

    if (!reportId) {
      return
    }
    await this.props.updateUsersBookkeepingReportStatus(reportId, {
      status,
      bookkeepingReportId: reportId,
    })
  }

  renderProgressButton() {
    const { status } = this.props

    if (status === BookkeepingReportStatus.done) {
      return (
        <Button
          size="mini"
          className="headerButton"
          secondary
          disabled={false}
          onClick={() => this.setState({ inProgressOpen: true })}
        >
          1. Re-start Books
        </Button>
      )
    } else if (
      status === BookkeepingReportStatus.plaid_error ||
      status === BookkeepingReportStatus.missing_info
    ) {
      return (
        <Button
          size="mini"
          className="headerButton"
          secondary
          disabled={false}
          onClick={() =>
            this.handleBooksUpdate(BookkeepingReportStatus.in_progress)
          }
        >
          1. Start Books
        </Button>
      )
    }

    return (
      <Button
        size="mini"
        className="headerButton"
        secondary
        disabled={status !== 'not_started'}
        onClick={() => this.setState({ inProgressOpen: true })}
      >
        1. Start Books
      </Button>
    )
  }
  renderCompletedButton() {
    const { status } = this.props

    if (status !== BookkeepingReportStatus.in_progress) {
      return (
        <Button size="mini" className="headerButton" secondary disabled>
          2. Bookkeeping Complete
        </Button>
      )
    }
    return (
      <Button
        size="mini"
        className="headerButton"
        secondary
        onClick={() => this.setState({ completeOpen: true })}
      >
        2. Bookkeeping Complete
      </Button>
    )
  }

  renderActionsBar() {
    const {
      inProgressOpen,
      completeOpen,
      reconciledOpen,
      doneOpen,
      smartRuleOpen,
    } = this.state
    const { report, status, user } = this.props

    const completeBooksDisabled =
      !report?.reconciledAt ||
      status === BookkeepingReportStatus.in_progress ||
      status === BookkeepingReportStatus.done
    return (
      <Menu secondary size="mini">
        <Menu.Menu position="right">
          <Menu.Item>
            {this.renderProgressButton()}
            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Start"
              content="Are you sure you want to start these books? This will notify the user"
              open={inProgressOpen}
              onCancel={() => this.setState({ inProgressOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.in_progress)
                this.setState({ inProgressOpen: false })
              }}
            />
          </Menu.Item>
          <Menu.Item>
            {this.renderCompletedButton()}
            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Completion"
              content="Are you sure you want to complete these books? This will mark it as 'Bookkeeping Complete' internally, but will not notify the user."
              open={completeOpen}
              onCancel={() => this.setState({ completeOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.categorized)
                this.setState({ smartRuleOpen: true, completeOpen: false })
              }}
            />
            {user && (
              <SmartRuleCreationModal
                open={smartRuleOpen}
                close={() => this.setState({ smartRuleOpen: false })}
                user={user}
              />
            )}
          </Menu.Item>

          <Menu.Item>
            <Button
              size="mini"
              className="headerButton"
              secondary
              disabled={status !== 'categorized'}
              onClick={() => this.setState({ reconciledOpen: true })}
            >
              3. Mark as Reconciled
            </Button>

            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Reconciliation"
              content={
                'This marks the books as reconciled,\u00A0meaning that you have gone through and cross referenced transactions with their bank accounts.'
              }
              open={reconciledOpen}
              onCancel={() => this.setState({ reconciledOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.reconciled)
                this.setState({ reconciledOpen: false })
              }}
            />
          </Menu.Item>

          <Menu.Item>
            <Button
              size="mini"
              className="headerButton"
              secondary
              disabled={completeBooksDisabled}
              onClick={() => this.setState({ doneOpen: true })}
            >
              4. Mark as Done
            </Button>
            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Completion"
              content="Are you sure you want to set report as DONE? This will notify the user to view their reports."
              open={doneOpen}
              onCancel={() => this.setState({ doneOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.done)
                this.setState({ doneOpen: false })
              }}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )
  }

  renderBooks() {
    const {
      newTransactionCounts,
      loading,
      reconciliationModalProps,
      showNewTransactionTable,
    } = this.state
    const {
      user,
      params: { userId, reportId },
      report,
      accounts,
      status,
    } = this.props

    const bookkeepingComplete = status === BookkeepingReportStatus.done

    const dimmerMessage = bookkeepingComplete
      ? 'You must "Open Books" before making any changes'
      : 'You must "Re-start Books" before making any changes'

    const onReconcileClick = (
      formType: string,
      account: FinancialAccountWithAdminInfo
    ) => {
      // Prevent reconciliation for a "never_reconciled" account which does not have any transactions yet
      if (formType === 'never_reconciled') {
        const count = newTransactionCounts[account.id]

        if (count === 0) {
          alert(
            'This account does not have any transactions yet. Please force pull, or retry reconciling at a later time'
          )
          return
        }
      }

      if (!user) {
        return
      }

      this.setState({
        reconciliationModalProps: {
          account,
          formType,
          open: true,
          userId: user?.id,
          close: () => {
            this.setState({ reconciliationModalProps: null })
          },
          onSumMatch: async () => {
            const newTransactionCounts =
              await this.props.fetchTransactionCountsSinceLastReconciliation(
                userId
              )
            if (newTransactionCounts) {
              this.setState({ newTransactionCounts })
            }
          },
        },
      })
    }

    return (
      <Dimmer.Dimmable dimmed={bookkeepingComplete}>
        <Grid stackable doubling>
          <Grid.Row stretched>
            <Grid.Column width={6}>
              {userId && <StatementCard userId={userId} reportId={reportId} />}
            </Grid.Column>
            <Grid.Column width={4}>
              <TransactionCountsCard
                userId={userId}
                reportId={reportId}
                loading={loading}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              {report && <BookkeepingStatusCard report={report} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />
        <AccountSumCards
          financialAccounts={accounts}
          newTransactionCounts={newTransactionCounts}
          onReconcileClick={onReconcileClick}
        />
        {reconciliationModalProps && (
          <ReconciliationModal {...reconciliationModalProps} />
        )}
        <Divider />

        {!showNewTransactionTable && <TransactionTable userId={user?.id} />}
        {showNewTransactionTable && user && (
          <PaginatedTransactionTable user={user} />
        )}

        <Dimmer active={bookkeepingComplete} verticalAlign="top">
          <Header as="h2" style={{ color: '#ffffff' }}>
            {dimmerMessage}
          </Header>
          {bookkeepingComplete && (
            <Header as="h4" style={{ color: '#ffffff' }}>
              Books completed
              {report?.completedAt &&
                `on ${moment
                  .utc(report?.completedAt)
                  .format(DATE_FORMATS.DISPLAY_SHORT)}`}
            </Header>
          )}
        </Dimmer>
      </Dimmer.Dimmable>
    )
  }

  renderPnL() {
    const {
      report,
      params: { userId },
    } = this.props
    if (!userId) {
      return null
    }

    return (
      <ProfitAndLossTable
        startDate={report?.startDate}
        endDate={report?.endDate}
        userId={userId}
      />
    )
  }

  render() {
    const { showNewTransactionTable } = this.state
    const {
      user,
      report,
      status,
      params: { userId, reportId },
    } = this.props

    const membership = user?.memberships?.length
      ? user.memberships.filter((membership) => membership.isPrimary)[0]
      : null

    return (
      <Container id="adminPanel">
        <UserBreadcrumbs userId={userId} pageName="Monthly Books">
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>
            <Moment format={DATE_FORMATS.MONTH_YEAR}>{report?.date}</Moment>
          </Breadcrumb.Section>
        </UserBreadcrumbs>
        <Divider />
        <UserRecordHeader user={user} />
        <div className="flexbox">
          <div>
            <Header as="h3">
              <Link to={`/admin/finances/records/${userId}`}>
                <Button circular icon="arrow left" />
              </Link>
              <Moment format={DATE_FORMATS.MONTH_YEAR}>{report?.date}</Moment>{' '}
              Books: {user?.firstName} {user?.lastName}
              {membership?.membershipStatus === 'unpaid' ? (
                <Label color="red">Unpaid</Label>
              ) : (
                <BookkeepingStatusLabel reportId={reportId} />
              )}
            </Header>
          </div>

          <div>{this.renderActionsBar()}</div>
        </div>

        <br />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <Menu secondary size="mini">
            <Menu.Menu>
              <Menu.Item>
                <Button
                  size="mini"
                  className="headerButton"
                  secondary
                  onClick={() =>
                    this.setState({
                      showNewTransactionTable: !showNewTransactionTable,
                    })
                  }
                >
                  {showNewTransactionTable
                    ? 'Show Old Transaction Table'
                    : 'Show New Transaction Table'}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  size="mini"
                  className="headerButton"
                  secondary
                  onClick={() =>
                    this.handleBooksUpdate(BookkeepingReportStatus.plaid_error)
                  }
                  disabled={status === BookkeepingReportStatus.plaid_error}
                >
                  Mark Plaid Error
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  size="mini"
                  className="headerButton"
                  secondary
                  onClick={() =>
                    this.handleBooksUpdate(BookkeepingReportStatus.missing_info)
                  }
                  disabled={status === BookkeepingReportStatus.missing_info}
                >
                  Mark Missing Information
                </Button>
              </Menu.Item>
              <DownloadPayrollReportMenuItem
                reportId={reportId}
                userId={userId}
              />
            </Menu.Menu>
          </Menu>
        </div>
        <br />
        {membership?.membershipStatus === 'unpaid' && (
          <Alert type="error">
            This user&apos;s account is defunct — do not work on their books.
          </Alert>
        )}
        <br />

        {report ? (
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={[
              { menuItem: 'Books', render: () => this.renderBooks() },
              { menuItem: 'Profit and Loss', render: () => this.renderPnL() },
              {
                menuItem: 'User Record Notes',
                render: () =>
                  userId && <UserRecordNotes userId={Number(userId)} />,
              },
              {
                menuItem: 'Onboarding',
                render: () => <OnboardingAnswers userId={Number(userId)} />,
              },
            ]}
          />
        ) : (
          <Dimmer active inverted>
            <Loader inverted>Loading report</Loader>
          </Dimmer>
        )}
      </Container>
    )
  }
}

const mapStateToProps = (state: ReduxState, props: ParentProps) => ({
  user: getUserById(state, props.params.userId),
  report: selectBookkeepingReportsById(state, props.params.reportId),
  accounts: selectSortedFinancialAccounts(state, props.params.userId),
  status: selectBookkeepingReportStatus(state, props.params.reportId),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTransactionCounts: wrapDispatch(dispatch, fetchTransactionCounts),
  clearTransactionFilters: wrapDispatch(dispatch, clearTransactionFilters),
  clearAndUpdateTransactionFilters: wrapDispatch(
    dispatch,
    clearAndUpdateTransactionFilters
  ),
  fetchAdminUsersBookkeepingReports: wrapDispatch(
    dispatch,
    fetchAdminUsersBookkeepingReports
  ),
  updateUsersBookkeepingReportStatus: wrapDispatch(
    dispatch,
    updateUsersBookkeepingReportStatus
  ),
  fetchAdminUsersDocuments: wrapDispatch(dispatch, fetchAdminUsersDocuments),
  adminFetchSingleUser: wrapDispatch(dispatch, adminFetchSingleUser),
  fetchUserFinancialAccounts: wrapDispatch(
    dispatch,
    fetchUserFinancialAccounts
  ),
  fetchTransactionCountsSinceLastReconciliation: wrapDispatch(
    dispatch,
    fetchTransactionCountsSinceLastReconciliation
  ),
})

const ConnectedMonthlyBooksPanel = connect<
  StateProps,
  DispatchProps,
  ParentProps,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyBooksPanel)

export default withRouter(ConnectedMonthlyBooksPanel)
