import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { Dropdown as SemanticDropdown, Grid, Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import {
  updateUserActionItem,
  UserActionItem,
  UserActionItemActionItemIdentifiers,
} from '../userActionItems.slice'
import { ACTION_ITEM_TYPE } from './UserActionItemTabPane'
import {
  Card,
  Dropdown,
  Label,
  Text,
} from '../../../../components/BaseComponents'
import { Colors, Fonts } from '../../../../styles/theme'
import Tooltip from '../../../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import DeadlineLabel from '../../../Taxes/QuarterlyTaxEstimates/components/DeadlineLabel'
import '../style.scss'
import {
  PostOnboardingTaskIdents,
  templateDescription,
} from '../../../Onboarding/config'
import UserActionListItemExtras from './UserActionListItemExtras'
import { markUserActionItemCompleteIfExists } from '../service'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useColumnWidth } from '../../../../utils/deviceWidthHelpers'
import { useAnalyticsTrack } from '../../../Amplitude'
import { useAppDispatch } from '../../../../utils/typeHelpers'

interface Props {
  dropdownOptions?: {
    key: string
    text: string
    onClick?: (id: number, description?: string) => void
  }[]
  userActionItem: UserActionItem
  selectedTabPane: ACTION_ITEM_TYPE
}

const dropdownStyle = {
  backgroundColor: 'transparent',
  fontSize: '1.2em',
  color: Colors.mediumGray,
  marginLeft: -5,
  marginTop: -3,
}

const isActionItemOverdue = ({
  actionItem: { endsAt },
  endsAt: userActionEndsAt,
}: UserActionItem) => {
  if (!endsAt && !userActionEndsAt) {
    return false
  }

  const dueDate = moment.utc(userActionEndsAt || endsAt).endOf('D')
  return moment().isAfter(dueDate, 'D')
}

const generateIconStyle = (
  userActionItem: UserActionItem,
  selectedTabPane: ACTION_ITEM_TYPE
) => ({
  backgroundColor:
    selectedTabPane === ACTION_ITEM_TYPE.UPCOMING ||
    selectedTabPane === ACTION_ITEM_TYPE.COMPLETED
      ? Colors.green
      : userActionItem.actionItem.identifier ===
          UserActionItemActionItemIdentifiers.reconnectBankAccount
        ? Colors.red
        : isActionItemOverdue(userActionItem)
          ? Colors.red
          : Colors.green,
  padding: userActionItem.completedAt ? '4px 0' : '2px 0',
  borderRadius: '5px',
  fontSize: '1.1em',
  height: '26px',
  width: '26px',
  color: Colors.white,
})

const generateDateTextStyle = (
  userActionItem: UserActionItem,
  selectedTabPane: ACTION_ITEM_TYPE
) => ({
  color:
    selectedTabPane === ACTION_ITEM_TYPE.COMPLETED
      ? Colors.accentGreen
      : selectedTabPane === ACTION_ITEM_TYPE.UPCOMING
        ? Colors.blue
        : isActionItemOverdue(userActionItem)
          ? Colors.red
          : Colors.orange,
  textAlign: 'right' as const,
})

const getDeadlineColumn = ({
  userActionItem,
  selectedTabPane,
}: {
  userActionItem: UserActionItem
  selectedTabPane: ACTION_ITEM_TYPE
}) => {
  const {
    actionItem: { endsAt },
    completedAt,
    endsAt: userActionEndsAt,
  } = userActionItem

  const dateTextStyle = generateDateTextStyle(userActionItem, selectedTabPane)

  if (
    (endsAt || userActionEndsAt) &&
    selectedTabPane !== ACTION_ITEM_TYPE.COMPLETED
  ) {
    return (
      <Grid.Column floated="right" width={5}>
        <DeadlineLabel
          style={dateTextStyle}
          text={
            selectedTabPane === ACTION_ITEM_TYPE.ACTIVE &&
            isActionItemOverdue(userActionItem)
              ? 'Overdue'
              : 'Due'
          }
          prominentText={moment
            .utc(userActionEndsAt || endsAt)
            .format(DATE_FORMATS.DISPLAY_SHORT)}
        />
      </Grid.Column>
    )
  }

  if (completedAt && selectedTabPane === ACTION_ITEM_TYPE.COMPLETED) {
    return (
      <Grid.Column floated="right" width={5}>
        <DeadlineLabel
          style={dateTextStyle}
          text="Completed"
          prominentText={moment(completedAt).format(DATE_FORMATS.DISPLAY_SHORT)}
        />
      </Grid.Column>
    )
  }

  return null
}

const UserActionItemListItem = ({
  dropdownOptions,
  userActionItem,
  selectedTabPane,
}: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [extraModalOpen, setExtraModalOpen] = useState(false)
  const track = useAnalyticsTrack()

  const markUserActionItemRead = useCallback(
    (id: number) =>
      updateUserActionItem(id, { id, readAt: moment().toJSON() })(dispatch),
    [dispatch]
  )

  const {
    actionItem: {
      actionItemCategory,
      actionLink,
      description,
      tooltip,
      identifier,
      canMarkComplete,
      id: actionItemId,
    },
    id,
    readAt,
    templateValue,
  } = userActionItem

  const itemDescription = useMemo(
    () => templateDescription(description, templateValue),
    [description, templateValue]
  )

  const tooltipTitle = useMemo(
    () => templateDescription(tooltip?.title ?? '', templateValue),
    [tooltip?.title, templateValue]
  )

  const iconStyle = generateIconStyle(userActionItem, selectedTabPane)

  const deadlineColumn = useMemo(
    () => getDeadlineColumn({ userActionItem, selectedTabPane }),
    [selectedTabPane, userActionItem]
  )

  const titleColumnWidth = useColumnWidth({
    width: deadlineColumn ? 8 : 13,
    tabletMobileAdd: 2,
    mobileAdd: 1,
  })

  return (
    <>
      <Card
        className="user-action-item-list-item"
        type="section"
        onClick={
          selectedTabPane === ACTION_ITEM_TYPE.ACTIVE
            ? async (e) => {
                // Prevent default <a> navigation
                e.preventDefault()

                // Perform save
                await markUserActionItemRead(id)

                // Special case for get to know link.  As soon as it's clicked it should mark as complete
                if (identifier === PostOnboardingTaskIdents.GET_TO_KNOW_HEARD) {
                  markUserActionItemCompleteIfExists(
                    PostOnboardingTaskIdents.GET_TO_KNOW_HEARD,
                    (event, properties) => track(event, properties)
                  )
                }

                track('clicked todo', {
                  todo_description: description,
                  todo_id: actionItemId,
                })

                if (actionLink) {
                  if (
                    new URL(document.baseURI).origin ===
                    new URL(actionLink, document.baseURI).origin
                  ) {
                    // If `actionLink` is relative, navigate to path
                    navigate(actionLink)
                  } else {
                    // Otherwise, open a new window with external link
                    window.open(actionLink, '_blank', 'noreferrer')
                  }
                } else {
                  setExtraModalOpen(true)
                }
              }
            : undefined
        }
        backgroundColor={
          selectedTabPane === ACTION_ITEM_TYPE.COMPLETED ||
          selectedTabPane === ACTION_ITEM_TYPE.UPCOMING
            ? 'stone40'
            : !readAt
              ? 'blush'
              : 'stone'
        }
        style={{ border: 'none', padding: 18, minHeight: 72 }}
      >
        <Grid>
          <Grid.Row verticalAlign="middle">
            {/* Icon */}
            <Grid.Column
              width={1}
              only="computer tablet"
              verticalAlign="middle"
            >
              <Icon
                style={iconStyle}
                className="user-action-item-icon"
                name={
                  selectedTabPane === ACTION_ITEM_TYPE.COMPLETED
                    ? 'checkmark'
                    : 'clipboard outline'
                }
              />
            </Grid.Column>

            {/* Title and category */}
            <Grid.Column width={titleColumnWidth}>
              <Text style={{ fontSize: Fonts.eyebrow.fontSize }} as="bodySm">
                {actionItemCategory?.title}
              </Text>
              <Text as="h3">
                {itemDescription}
                {selectedTabPane === ACTION_ITEM_TYPE.ACTIVE && !readAt && (
                  <Label
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'transparent',
                    }}
                    size="mini"
                    color="green"
                  >
                    NEW
                  </Label>
                )}
              </Text>
            </Grid.Column>

            {deadlineColumn}

            {/* Tooltip */}
            <Grid.Column floated="right" width={1} only="computer">
              {tooltip?.body && (
                <Tooltip
                  labelComponent={
                    <Icon name="question circle outline" color="grey" />
                  }
                  popup={{
                    title: tooltipTitle,
                    body: tooltip?.body || '',
                    link: tooltip?.link || '',
                  }}
                  style={{ marginRight: 10 }}
                />
              )}
            </Grid.Column>

            {/* Dropdown */}
            <Grid.Column
              width={1}
              only="computer"
              style={{
                display:
                  selectedTabPane === ACTION_ITEM_TYPE.UPCOMING
                    ? 'none'
                    : 'inline',
              }}
            >
              {canMarkComplete && (
                <Dropdown
                  disabled={selectedTabPane === ACTION_ITEM_TYPE.UPCOMING}
                  direction="left"
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                  style={dropdownStyle}
                  icon="ellipsis horizontal"
                >
                  <SemanticDropdown.Menu>
                    {dropdownOptions?.map((option) => (
                      <SemanticDropdown.Item
                        onClick={() =>
                          option.onClick && option.onClick(id, description)
                        }
                        key={`UpcomingActionItem__${id}_${option.key}`}
                      >
                        {option.text}
                      </SemanticDropdown.Item>
                    ))}
                  </SemanticDropdown.Menu>
                </Dropdown>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <UserActionListItemExtras
        userActionItemId={userActionItem.id}
        open={extraModalOpen}
        onClose={() => setExtraModalOpen(false)}
      />
    </>
  )
}

export default UserActionItemListItem
