import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { TRUE_FALSE_OPTIONS } from '../../../constants/onboardingConstants'
import {
  Button,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  getFieldName,
  GridRowColumn,
  makeReqStringSchema,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { selectActionItemCategories } from './actionItemCategories.slice'
import {
  ActionItemType,
  createActionItem,
  updateActionItem,
  ActionItem,
} from './allActionItems.slice'

const validationSchema = yup.object({
  description: makeReqStringSchema({ field: 'description' }),
  actionLink: yup.string().nullable(),
  actionItemCategoryId: yup.number(),
  default: yup.boolean(),
  identifier: makeReqStringSchema({ field: 'identifier' }),
  startsAt: yup.string().nullable(),
  endsAt: yup.string().nullable(),
  tooltipId: yup.number().nullable(),
})

interface TodoCreationFormProps {
  actionItem?: ActionItem
  isTodo?: boolean
  close: () => void
}

const TodoAndImportantDateCreationForm = ({
  actionItem,
  isTodo,
  close,
}: TodoCreationFormProps) => {
  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues: {
      description: actionItem?.description || '',
      actionItemCategoryId: actionItem?.actionItemCategoryId,
      actionLink: actionItem?.actionLink || '',
      tooltipId: actionItem?.tooltipId,
      identifier: actionItem?.identifier || '',
      default: actionItem?.default || false,
      startsAt: actionItem?.startsAt,
      endsAt: actionItem?.endsAt,
      showToUserAt: actionItem?.showToUserAt,
      hideFromUserAt: actionItem?.hideFromUserAt,
    },
    validationSchema,
    onSubmit: async (newTodoValues) => {
      const newTodo = {
        ...newTodoValues,
        // These are hard coded for todos
        active: false,
        type: isTodo ? ActionItemType.actionItem : ActionItemType.importantDate,
      }

      let res
      if (actionItem) {
        res = await updateActionItem(actionItem.id, newTodo)(dispatch)
      } else {
        res = await createActionItem(newTodo)(dispatch)
      }
      if (res) {
        close()
      }
    },
  })

  const allActionItemCategories = useReselector(selectActionItemCategories)

  const categoryOptions = useMemo(
    () =>
      Object.values(allActionItemCategories).map((category) => ({
        text: category.title,
        value: category.id,
      })),
    [allActionItemCategories]
  )

  const allTooltips = useReselector((state) => state.admin.allTooltips)

  const tooltipOptions = useMemo(
    () =>
      Object.values(allTooltips).map((tooltip) => ({
        text: tooltip.title || tooltip.body,
        value: tooltip.id,
      })),
    [allTooltips]
  )

  const { values, submitForm, isSubmitting, isValid } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <FormikInput
            label="Description"
            name={getFieldName<typeof values>('description')}
            fullWidth
            required
          />
        </GridRowColumn>
        {isTodo && (
          <GridRowColumn>
            <FormikInput
              label="Action Link"
              name={getFieldName<typeof values>('actionLink')}
              fullWidth
              required
            />
          </GridRowColumn>
        )}
        <GridRowColumn>
          <FormikInput
            label="Internal identifier"
            name={getFieldName<typeof values>('identifier')}
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDropdown
            label="Set as default for all users"
            placeholder="Select Option"
            name={getFieldName<typeof values>('default')}
            options={TRUE_FALSE_OPTIONS}
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDropdown
            label="Action Item Category"
            placeholder="Select Option"
            name={getFieldName<typeof values>('actionItemCategoryId')}
            options={categoryOptions}
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDropdown
            label="Tooltip"
            placeholder="Select Option"
            name={getFieldName<typeof values>('tooltipId')}
            options={tooltipOptions}
            fullWidth
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDateInput
            name={getFieldName<typeof values>('startsAt')}
            label="Start Date"
            fullWidth
            dateFormat={DATE_FORMATS.UTC}
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDateInput
            name={getFieldName<typeof values>('endsAt')}
            label="End Date"
            fullWidth
            dateFormat={DATE_FORMATS.UTC}
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDateInput
            name={getFieldName<typeof values>('showToUserAt')}
            label="Show to user at"
            fullWidth
            dateFormat={DATE_FORMATS.UTC}
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDateInput
            name={getFieldName<typeof values>('hideFromUserAt')}
            label="Hide from user at"
            fullWidth
            dateFormat={DATE_FORMATS.UTC}
          />
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={6}>
            <Button variant="secondary" onClick={close} fullWidth>
              Cancel
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button
              onClick={submitForm}
              type="submit"
              fullWidth
              disabled={isSubmitting || !isValid}
            >
              {actionItem ? 'Update' : 'Create'}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormikProvider>
  )
}

export default TodoAndImportantDateCreationForm
