import axios from 'axios'
import { Moment } from 'moment'

import { fetchWrapper } from '../reducers/fetch'
import { parseErrorFromCatch } from '../utils/errorHelpers'
import { BALANCE_SHEET_CATEGORIES_TYPE } from '../features/Taxes/taxConstants'

export const fetchExpensesSum =
  ({ month, year }: { month?: Moment; year?: string }) =>
  () =>
    axios
      .get<{ sum: number; expenses: unknown }>(
        '/finances/api/v1/transactions/expenses',
        {
          params: {
            year,
            month: month?.format('MMMM YYYY'),
          },
        }
      )
      .then((json) => json.data)
      .catch()

export const fetchIncomesSum =
  ({ month, year }: { month?: Moment; year?: string }) =>
  () =>
    axios
      .get<{ sum: number; profits: unknown }>(
        '/finances/api/v1/transactions/incomes',
        {
          params: {
            year,
            month: month?.format('MMMM YYYY'),
          },
        }
      )
      .then((json) => json.data)
      .catch()

export interface CashflowReport {
  expenses: string
  income: string
  month_start: string
  total_sum: string | null
  year_month: string
}

export const fetchCashflow = (year: string) =>
  axios
    .get<CashflowReport[]>('/finances/api/v1/reports/cashflow', {
      params: { year },
    })
    .then((json) => {
      return json.data
    })
    .catch()

export const fetchProfitsBreakdown =
  ({
    startDate,
    endDate,
    year,
  }: {
    startDate?: Moment
    endDate?: Moment
    year?: string
  }) =>
  () =>
    axios
      .get<{
        profits: Expense[]
        sum: number
      }>('/finances/api/v1/reports/profits_breakdown', {
        params: {
          year,
          startDate: startDate?.format('YYYY-MM-DD'),
          endDate: endDate?.format('YYYY-MM-DD'),
        },
      })
      .then((json) => {
        return json.data
      })
      .catch(() => ({
        profits: [] as Expense[],
        sum: 0,
      }))

/*
  Fetch uncategorized sum
*/
export const fetchUncategorizedBreakdown =
  ({
    startDate,
    endDate,
    year,
  }: {
    startDate?: Moment
    endDate?: Moment
    year?: string
  }) =>
  async () => {
    try {
      const json = await axios.get<{
        sum: number
        transactionCategoryId: number
      }>('finances/api/v1/reports/uncategorized_breakdown', {
        params: {
          year,
          startDate: startDate?.format('YYYY-MM-DD'),
          endDate: endDate?.format('YYYY-MM-DD'),
        },
      })
      return json.data
    } catch (err) {
      return {
        sum: 0,
        transactionCategoryId: null,
      }
    }
  }

export interface Expense {
  percentage: string
  sum: string
  transactionCategoryId: number
}

// Expenses Breakdown
export const fetchExpensesBreakdown = ({
  startDate,
  endDate,
  year,
}: {
  startDate?: Moment
  endDate?: Moment
  year?: string
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching the expenses breakdown.',
    defaultValue: {
      expenses: [] as Expense[],
      sum: 0,
    },
    fetchFunction: () =>
      axios
        .get<{
          expenses: Expense[]
          sum: number
        }>('/finances/api/v1/reports/expenses_breakdown', {
          params: {
            year,
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
          },
        })
        .then((json) => json.data),
  })

export const fetchOtherBreakdown = ({
  startDate,
  endDate,
  year,
}: {
  startDate?: Moment
  endDate?: Moment
  year?: string
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching the other breakdown.',
    defaultValue: {
      otherExpenses: [] as Expense[],
      sum: 0,
    },
    fetchFunction: () =>
      axios
        .get<{
          otherExpenses: Expense[]
          sum: string | number
        }>('/finances/api/v1/reports/other_breakdown', {
          params: {
            year,
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
          },
        })
        .then((json) => json.data),
  })

// Admin Breakdowns

export interface AdminExpenses {
  expenses: Array<{
    transactionCategoryId: number
    sum: string
  }>
  sum: string | number
}
export const fetchAdminExpensesBreakdown = ({
  userId,
  startDate,
  endDate,
}: {
  userId: number | string
  startDate?: Moment
  endDate?: Moment
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching the expenses breakdown.',
    defaultValue: {
      expenses: [],
      sum: 0,
    },
    fetchFunction: () =>
      axios
        .get<AdminExpenses>(
          `/finances/api/v1/admin/reports/expenses_breakdown/${userId}`,
          {
            params: {
              startDate: startDate?.format('YYYY-MM-DD'),
              endDate: endDate?.format('YYYY-MM-DD'),
              year: !startDate && !endDate && 'all',
            },
          }
        )
        .then((json) => {
          return json.data
        }),
  })

export interface AdminProfits {
  profits: Array<{
    transactionCategoryId: number
    sum: string
  }>
  sum: string | number
}

export const fetchAdminProfitsBreakdown =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: number | string
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<AdminProfits>(
        `/finances/api/v1/admin/reports/profits_breakdown/${userId}`,
        {
          params: {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            year: !startDate && !endDate && 'all',
          },
        }
      )
      .then((json) => {
        return json.data
      })
      .catch(() => ({
        profits: [],
        sum: 0,
      }))

export const fetchAdminUncategorizedBreakdown =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: number
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<{ sum: number; transactionCategoryId: number }>(
        `/finances/api/v1/admin/reports/uncategorized_breakdown/${userId}`,
        {
          params: {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            year: !startDate && !endDate && 'all',
          },
        }
      )
      .then((json) => json.data)
      .catch(() => ({
        sum: 0,
        transactionCategoryId: null,
      }))

export const fetchAdminOtherBreakdown =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: number
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<{
        otherExpenses: Array<{
          transactionCategoryId: number
          sum: string
        }>
        sum: string | number
      }>(`/finances/api/v1/admin/reports/other_breakdown/${userId}`, {
        params: {
          startDate: startDate?.format('YYYY-MM-DD'),
          endDate: endDate?.format('YYYY-MM-DD'),
          year: !startDate && !endDate && 'all',
        },
      })
      .then((json) => {
        return json.data
      })
      .catch(() => ({
        otherExpenses: [],
        sum: 0,
      }))

export interface AdminProfitLossItem {
  name: string
  account_type: string
  sum: string
}

export const fetchAdminProfitAndLoss =
  ({
    userId,
    startDate,
    endDate,
  }: {
    userId: string | number
    startDate?: Moment
    endDate?: Moment
  }) =>
  () =>
    axios
      .get<{
        profits: AdminProfitLossItem[]
        profitSum: string | number // Api returns 0 instead of "0"
        expenses: AdminProfitLossItem[]
        expensesSum: string | number
        other: AdminProfitLossItem[]
        otherSum: string | number
        uncategorizedSum: string | number
      }>(`/finances/api/v1/admin/reports/profit_and_loss/${userId}`, {
        params: {
          startDate: startDate?.format('YYYY-MM-DD'),
          endDate: endDate?.format('YYYY-MM-DD'),
          year: !startDate && !endDate && 'all',
        },
      })
      .then((json) => json.data)
      .catch(() => ({
        profits: [],
        profitSum: 0,
        expenses: [],
        expensesSum: 0,
        other: [],
        otherSum: 0,
        uncategorizedSum: 0,
      }))

/*
  Admin Balance Sheets
*/
export interface BalanceSheetFinancialAccountSummary {
  displayName: string
  financialAccountId: number
  mostRecentReconciliationId: number
  mostRecentReconciliationEndingBalanceInCents?: number
  mostRecentReconciliationEndingBalanceDate?: string
  lifetimeReconciliationIdsFromYearMonthInclusive?: number[]
  reconciledThisPeriod: boolean
  isCashAccount: boolean
}

export interface BalanceSheetDropdownItem {
  mostRecentReconciliationEndingBalanceDate: string
  mostRecentReconciliationId: number
  financialAccounts: BalanceSheetFinancialAccountSummary[]
}

export interface BalanceSheetDropdownData {
  [key: string]: BalanceSheetDropdownItem
}

export interface BalanceSheetLineItem {
  label: string
  sumInCents: number
  transactionCategoryId?: number
  financialAccountId?: number
}

export interface BalanceSheetSubcategory extends BalanceSheetLineItem {
  accounts: BalanceSheetLineItem[]
}

export interface BalanceSheetCategoryData extends BalanceSheetLineItem {
  subcategories: BalanceSheetSubcategory[]
}

export interface BalanceSheetReportData {
  totals: {
    totalAssetsInCents: number
    totalLiabilitiesEquityInCents: number
  }
  accounts: {
    Assets: BalanceSheetCategoryData
    Liability: BalanceSheetCategoryData
    Equity: BalanceSheetCategoryData
  }
}

export const fetchAdminBalanceSheetDropdownOptions = async (userId: number) => {
  try {
    const res = await axios.get<BalanceSheetDropdownData>(
      `/finances/api/v1/admin/${userId}/balance_sheets/dropdown_period_options`
    )
    return { error: null, data: res.data }
  } catch (err) {
    const message = parseErrorFromCatch(
      err,
      'An error occurred loading dropdown options. Please try again'
    )
    return { error: message, data: null }
  }
}

export interface AdminBalanceSheetRequestPayload {
  reconciliationIdsByAccountId: { [key: number]: number[] }
  userId: number
  mostRecentReconciliationEndingBalanceDate: string
}

export const generateAdminBalanceSheet = async (
  data: AdminBalanceSheetRequestPayload
) => {
  try {
    const res = await axios.post<BalanceSheetReportData>(
      '/finances/api/v1/admin/balance_sheets',
      data
    )
    return { error: null, data: res.data }
  } catch (err) {
    const message = parseErrorFromCatch(
      err,
      'An error occurred generating the balance sheet. Please try again'
    )
    return { error: message, data: null }
  }
}

export const downloadAdminBalanceSheetTransactions = async (
  data: AdminBalanceSheetRequestPayload,
  balanceSheetCategory: BALANCE_SHEET_CATEGORIES_TYPE
) => {
  try {
    const res = await axios.post<{ csvString: string }>(
      `/finances/api/v1/admin/${data.userId}/balance_sheets/${balanceSheetCategory}/download-lineitems-csv`,
      data
    )
    return { error: null, data: res.data.csvString }
  } catch (err) {
    const message = parseErrorFromCatch(
      err,
      'An error occurred downloading balance sheet transactions. Please try again'
    )
    return { error: message, data: null }
  }
}
