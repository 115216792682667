import { useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

import {
  Button,
  GridRowColumn,
  Icon,
  Link,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  QTEChecklistStatuses,
  selectIsPastOrSameAsTaxEstimateIRSPaymentDate,
  selectIsPastTaxEstimateCalculationDate,
  selectQTEChecklistStatusAndDates,
} from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { DATE_FORMATS_LUXON } from '../../../../../utils/dateHelpers'
import { selectUserTaxCalculationWithinActiveQuarter } from '../../../../../selectors/userTaxCalculation.selectors'
import { FEATURE_FLAG_KEYS } from '../../../../OpenFeature'

const IRSPaymentDueAt = ({
  irsPaymentDueAt,
  isPastPaymentDate,
}: {
  irsPaymentDueAt?: DateTime
  isPastPaymentDate: boolean
}) =>
  irsPaymentDueAt ? (
    <>
      <br />
      <Text color={isPastPaymentDate ? 'red' : 'black'}>
        IRS payment due{' '}
        <b>{irsPaymentDueAt.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}</b>
      </Text>
    </>
  ) : null

const ChecklistDueAt = ({
  checkListDueAt,
  displayText,
  isPastCalculationDate,
}: {
  checkListDueAt: DateTime | string
  displayText: string
  isPastCalculationDate: boolean
}) => {
  if (!displayText || !checkListDueAt) {
    return null
  }
  const dueDate =
    typeof checkListDueAt === 'string'
      ? checkListDueAt
      : checkListDueAt.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)
  return (
    <Text
      color={isPastCalculationDate ? 'red' : 'black'}
      style={{ marginBottom: 8 }}
    >
      <Icon
        color={isPastCalculationDate ? 'red' : 'oak'}
        style={{ paddingRight: 8 }}
        icon={regular('calendar-check')}
      />{' '}
      {displayText} <b>{dueDate}</b>
    </Text>
  )
}

export const ChecklistDoneHeader = () => {
  const checklistStatus = useReselector(selectQTEChecklistStatusAndDates)
  const taxCalc = useReselector(selectUserTaxCalculationWithinActiveQuarter)
  const shouldUseRealTimeRecalculation = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.qteRealTimeRecalculation,
    false
  )

  const showRecalcText =
    taxCalc?.needsRecalculation && !shouldUseRealTimeRecalculation

  const displayText = showRecalcText
    ? 'We’re Calculating Your New Estimate...'
    : 'We’re Calculating Your Estimate...'

  return (
    <Grid centered>
      <GridRowColumn short>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/calculator2.svg"
          style={{ height: 100, width: 117 }}
          centered
        />
      </GridRowColumn>
      <GridRowColumn width={12} columnStyle={{ textAlign: 'center' }}>
        <Text as="display2">{displayText}</Text>
        <Text style={{ marginTop: 16, marginBottom: 16 }}>
          Your new federal tax estimate will be released within an hour. Check
          back soon!
        </Text>
        <IRSPaymentDueAt
          irsPaymentDueAt={checklistStatus?.rest?.forDisplay.irsPaymentDue}
          // text is black here even if past due date
          isPastPaymentDate={false}
        />
      </GridRowColumn>
      <Grid.Row />
    </Grid>
  )
}

export const ChecklistOpenHeader = () => {
  const checklistStatus = useReselector(selectQTEChecklistStatusAndDates)

  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )
  const isPastPaymentDate = useReselector(
    selectIsPastOrSameAsTaxEstimateIRSPaymentDate
  )
  const infoText = useMemo(() => {
    switch (checklistStatus.status) {
      case QTEChecklistStatuses.checklistOpen: {
        return 'Complete the checklist to receive your quarterly tax estimate'
      }
      case QTEChecklistStatuses.internalCalculationHold: {
        return 'Good news! We’ve extended the checklist deadline. Please complete your checklist by the new deadline in order to receive an estimate before the IRS payment due date.'
      }
      case QTEChecklistStatuses.calcsShouldBeReadyBeforeIRSPaymentDue: {
        return 'Good news! We’ve extended the checklist deadline one last time. Please submit your checklist by the new deadline in order to receive an estimate before the IRS payment due date.'
      }
      case QTEChecklistStatuses.calcsMayBeReceivedAfterIRSPaymentDue:
        return (
          <>
            We’re available to calculate your estimate! If you submit your
            checklist ASAP, there’s still potential for you to receive an
            estimate in time (barring bookkeeping limitations).
            <br />
            <br />
            In the event you don’t receive an estimate in time, you can find
            guidance by reading{' '}
            <Link
              newPage
              href="https://support.joinheard.com/hc/en-us/articles/22050337435415-How-to-manually-calculate-quarterly-estimate-tax"
            >
              How much should I pay?
            </Link>
          </>
        )
      // These 2 are handled outside of this component
      case QTEChecklistStatuses.afterIRSPaymentDue:
      case QTEChecklistStatuses.beforeChecklistOpens:
        return ''
      default:
        return checklistStatus.status satisfies never
    }
  }, [checklistStatus])

  const [displayText, checklistDueAt]: [
    string,
    DateTime<true> | DateTime<false> | string,
  ] = useMemo(() => {
    switch (checklistStatus.status) {
      case QTEChecklistStatuses.internalCalculationHold:
        return [
          'Checklist deadline extended to',
          checklistStatus.endDateForDisplay,
        ]
      case QTEChecklistStatuses.calcsShouldBeReadyBeforeIRSPaymentDue:
        return [
          'Checklist deadline extended to',
          checklistStatus.endDateForDisplay,
        ]
      case QTEChecklistStatuses.calcsMayBeReceivedAfterIRSPaymentDue:
        return ['Complete by', 'ASAP']
      case QTEChecklistStatuses.afterIRSPaymentDue:
      case QTEChecklistStatuses.beforeChecklistOpens:
      case QTEChecklistStatuses.checklistOpen:
        return ['', '']
      default:
        return checklistStatus.status satisfies never
    }
  }, [checklistStatus])

  const popupText = useMemo(() => {
    switch (checklistStatus.status) {
      case QTEChecklistStatuses.internalCalculationHold:
      case QTEChecklistStatuses.calcsShouldBeReadyBeforeIRSPaymentDue:
        return (
          <>
            We’re giving you as much time as we can to submit your checklist so
            that you can receive your estimate in time.
            <br />
            <br />
            <b>If you have bank statements that need to be uploaded,</b> your
            bookkeeping may be delayed due to our focus on bookkeeping reviews
            for customers who submitted their checklist earlier.
            <br />
            <br />
            <b>If you don’t submit your checklist by the new deadline,</b> we
            can’t guarantee an answer to any questions you may have about your
            estimates before the IRS payment due date. Our current focus is on
            therapists who submit their checklist by the deadline.
          </>
        )

      case QTEChecklistStatuses.calcsMayBeReceivedAfterIRSPaymentDue:
        return (
          <>
            We still want you to submit your checklist ASAP!
            <br />
            <br />
            <b>If you still have bank statements that you need to upload,</b> we
            cannot guarantee that your books will be reviewed in time for you to
            receive an estimate and make a payment before the IRS deadline.
            <br />
            <br />
            <b>Keep in mind:</b> responses to your questions about your
            estimates are not guaranteed to arrive in time before the IRS
            payment due date. We encourage you to submit your questions as soon
            as you can.
          </>
        )

      case QTEChecklistStatuses.checklistOpen:
      case QTEChecklistStatuses.beforeChecklistOpens:
      case QTEChecklistStatuses.afterIRSPaymentDue:
        return null
      default:
        return checklistStatus.status satisfies never
    }
  }, [checklistStatus.status])

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 124 }}>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/green-checklist.svg"
          style={{ height: 120, width: 120 }}
          centered
        />
      </div>
      <div style={{ marginLeft: 16, flex: 1 }}>
        <Text as="h2" style={{ marginBottom: 8 }}>
          Get Your Estimate
        </Text>
        <Text color="darkGray" style={{ marginBottom: 16 }}>
          {infoText}
        </Text>
        {popupText && (
          <Popup
            trigger={
              <Button variant="tooltipLink" style={{ marginBottom: 40 }}>
                Things you should know
              </Button>
            }
            position="bottom left"
            content={
              <>
                <Text as="h3">Things you should know</Text>
                <br />
                <Text>{popupText}</Text>
              </>
            }
          />
        )}
        <ChecklistDueAt
          checkListDueAt={checklistDueAt}
          displayText={displayText}
          isPastCalculationDate={isPastCalculationDate}
        />
        <IRSPaymentDueAt
          irsPaymentDueAt={checklistStatus?.rest?.forDisplay.irsPaymentDue}
          isPastPaymentDate={isPastPaymentDate}
        />
      </div>
    </div>
  )
}
