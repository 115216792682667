import { useDispatch } from 'react-redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { UnknownAction } from '@reduxjs/toolkit'
import { BaseActionCreator } from '@reduxjs/toolkit/src/createAction'
import { isNil } from 'lodash'

import appReducer from '../reducers/appReducer'

export type ReduxState = ReturnType<typeof appReducer>
export type GetState = () => ReduxState
export type Dispatch = ThunkDispatch<ReduxState, void, UnknownAction>

export const useAppDispatch = useDispatch.withTypes<Dispatch>()

// TS has some trouble typing filtered arrays
// In `const foo = [1,2,undefined].filter(Boolean);` `foo` will have a type of `Array<number | undefined>`
// even though there won't be any undefined values. This helper will help type `foo` to just have a type of number[]
export function filterNulls<T>(array: Array<T | undefined | null>) {
  return array.filter(Boolean) as T[]
}

// This allows the compiler to recognize that objects have relevant keys, after checking with the `in` operator
// https://github.com/microsoft/TypeScript/issues/21732
export function inOperator<K extends string, T extends object>(
  k: K,
  o: T
): o is T & Record<K, unknown> {
  return k in o
}

export const parseStringArray = (val: unknown) =>
  Array.isArray(val) && val.every((val) => typeof val === 'string')
    ? (val as string[])
    : null

// Strips out value from promise.  i.e. `type MyPromiseType = PromiseValue<Promise<boolean>>` MyPromiseType will be boolean
export type PromiseValue<PromiseType> =
  PromiseType extends PromiseLike<infer Value>
    ? PromiseValue<Value>
    : PromiseType
type AsyncFunction = (_: unknown) => Promise<unknown>
// Strips out Promise value from return type.
// i.e. `const promiseFun (val: boolean) => Promise.resolve(val)` `type MyPromiseType = AsyncReturnType<typeof promiseFun>` MyPromiseType will be boolean
export type AsyncReturnType<Target extends AsyncFunction> = PromiseValue<
  ReturnType<Target>
>

// This simple but pretty wild helper function is to create maps (AKA objects) that are strongly
// typed from the get go. Usage looks like the following:
//
// const map = createMap<ValueType>()({ ... /* object declaration */ })
//
// Typescript will resolve the type of `map` to be the following:
//
// `Record<'key1', 'key2', 'key3', ..., ValueType>`.
//
// Now `map` is a strictly typed object :)
export const createMap =
  <V>() =>
  <K extends string | number | symbol>(map: Record<K, V>) =>
    map

// Matches any action creator (actions made in reduxjs/toolkit)
interface AnyActionCreator<Args extends unknown[], U extends UnknownAction>
  extends BaseActionCreator<unknown, string> {
  (...args: Args): U
}

// For actions created manually (function that takes dispatch and/or state)
export function wrapDispatch<Args extends unknown[], U>(
  dispatch: Dispatch,
  action: (...args: Args) => ThunkAction<U, ReduxState, void, UnknownAction>
): (...args: Args) => U

// For actions created in reduxjs/toolkit
export function wrapDispatch<Args extends unknown[], U extends UnknownAction>(
  dispatch: Dispatch,
  action: AnyActionCreator<Args, U>
): (...args: Args) => U

// Made for wrapping actions with dispatch in mapDispatchToProps so args don't have to be redeclared
// ie `{myAction: (val: string) => dispatch(myAction(val))}` can be {myAction: wrapDispatch(dispatch, myAction)}
export function wrapDispatch<Args extends unknown[], U>(
  dispatch: Dispatch,
  action: (...args: Args) => ThunkAction<U, ReduxState, void, UnknownAction>
): (...args: Args) => U {
  return (...args: Args) => dispatch(action(...args))
}

/**
 * Filter out `null` and/or `undefined` values from an object.
 *
 * @param object An object whose value is possibly `null` or `undefined` and filter those key-value
 * pairs from the object.
 * @returns An object whose value are not `null` or `undefined`.
 */
export const filterObjNils = <K extends string | number | symbol, T>(
  object:
    | {
        [key in K]: T | undefined | null
      }
    | undefined
) =>
  Object.fromEntries(
    Object.entries(object ?? {}).filter(([_, value]) => !isNil(value))
  ) as { [key in K]: T }

/**
 * Generates a function that can produce a type guard/check against enums.
 *
 * Example –
 * enum Foo {
 *   BAR_1 = 'bar1',
 *   BAR_2 = 'bar2',
 * }
 *
 * const isFoo = createIsSomeEnum(Foo) // isFoo: (token: any) => token is Foo
 * const isBar1InFoo = isFoo('bar1') // true
 * const isBar3InFoo = isFoo('bar3') // false
 *
 * @see {@link https://stackoverflow.com/questions/58278652/generic-enum-type-guard}
 */
export const createIsSomeEnum =
  <T extends { [s: string]: unknown }>(e: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T])
