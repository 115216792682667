import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { AnnualTaxFormType } from './annualTaxFormType.slice'
import {
  fetchIfNeededWrapper,
  fetchWrapper,
  invalidateFetch,
} from '../../../reducers/fetch'
import { FETCH_USER_ACTION_ITEMS_KEY } from '../../Dashboard/UserActionItems/userActionItems.slice'

export enum FormFilingStatus {
  draft_being_prepared = 'draft_being_prepared',
  draft_ready = 'draft_ready',
  draft_accepted = 'draft_accepted',
  draft_rejected = 'draft_rejected',
  ready_to_sign = 'ready_to_sign',
  signed = 'signed',
  submitted_to_irs = 'submitted_to_irs',
  payment_required = 'payment_required',
  complete = 'complete',
  complete_no_payment = 'complete_no_payment',
  rejected_by_irs = 'rejected_by_irs',
}

export enum TaxfyleJobStatus {
  underConstruction = 'UNDER_CONSTRUCTION',
  infoGathering = 'INFO_GATHERING',
  unclaimed = 'UNCLAIMED',
  claimed = 'CLAIMED',
  idle = 'IDLE',
  onHold = 'ON_HOLD',
  completed = 'COMPLETED',
  canceled = 'CANCELLED',
}

export enum TaxfyleJobStep {
  jobStarted = 'JOB_STARTED',
  openItems = 'OPEN_ITEMS',
  draftInReview = 'DRAFT_IN_REVIEW',
  draftRejected = 'DRAFT_REJECTED',
  draftApproved = 'DRAFT_APPROVED',
  authorizationRequested = 'AUTHORIZATION_REQUESTED',
  authorizationSigned = 'AUTHORIZATION_SIGNED',
  returnFiled = 'RETURN_FILED',
}

export interface TaxfyleJob {
  jobId: string
  jobStatus: TaxfyleJobStatus
  statusLastUpdatedAt: string | null
  jobStep: TaxfyleJobStep | null
  stepLastUpdatedAt: string | null
  jobStartedAt: string | null
  jobCompletedAt: string | null
  transferredProviderReason: string | null
  cancelledReason: string | null
  cancelledAt: string | null
  taxProLastMessageAt: string | null
  userLastMessageAt: string | null
  hasUnreadMessages: boolean
  messagesLastViewedAt: string | null
  jobType: 'TAX_FILING' | 'EXTENSION' | 'CONSULTATION'
  services: string[]
  createdAt: string
  updatedAt: string
}

export interface TaxfyleCall {
  id: number
  taxfyleJobId: string
  scheduledAt: string
  transcriptId: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export interface AnnualTaxFilingForm {
  id: number
  userId: number
  helloSignTemplateId: string | null
  helloSignSignatureRequest: string | null
  helloSignSignedAt: string | null
  annualTaxFilingId: number
  formTypeId: number
  finalFormUserDocumentId?: number
  questionnaireResponseStatus: AnnualTaxFilingFormQuestionnaireStatus
  questionnaireLockedOn: string | null
  zendeskTicketId: number | null
  user_id: number
  year: string
  createdAt: string
  updatedAt: string
  formType: Pick<AnnualTaxFormType, 'name' | 'displayName'>
  isExtended: boolean | null
  extensionPayments: AnnualTaxFilingExtensionPayment[] | null
  formFilingStatus: FormFilingStatus | null
  completedAt: string | null
  startedAt: string | null
  url: string | null
  taxfyleJob: TaxfyleJob | null
}

export interface AnnualTaxFilingExtensionPayment {
  entityType: 'state' | 'federal'
  entityName: string
  value?: string
  paidAt?: string
}

export interface AnnualTaxFilingFormState {
  [key: string]: AnnualTaxFilingForm
}

const annualTaxFilingFormsSlice = createSlice({
  name: 'annualTaxFilingForms',
  initialState: {} as AnnualTaxFilingFormState,
  reducers: {
    getAllAnnualTaxFilingForms: (
      state,
      action: PayloadAction<{ [key: string]: AnnualTaxFilingForm }>
    ) => ({ ...state, ...action.payload }),
    updateAnnualTaxFilingForm: (
      state,
      action: PayloadAction<AnnualTaxFilingForm>
    ) => {
      state[action.payload.id] = action.payload
    },
    updatePartialAnnualTaxFilingForm: (
      state,
      action: PayloadAction<Partial<AnnualTaxFilingForm> & { id: number }>
    ) => {
      const taxFormEntry = state[action.payload.id]
      if (taxFormEntry) {
        state[action.payload.id] = {
          ...taxFormEntry,
          ...action.payload,
        }
      }
    },
  },
})

export default annualTaxFilingFormsSlice.reducer

// Actions
export const {
  updateAnnualTaxFilingForm,
  getAllAnnualTaxFilingForms,
  updatePartialAnnualTaxFilingForm,
} = annualTaxFilingFormsSlice.actions

export const FETCH_ANNUAL_TAX_FILING_FORMS_KEY =
  'FETCH_ANNUAL_TAX_FILING_FORMS_KEY'
export const fetchAnnualTaxFilingFormsIfNeeded = (forceFetch?: boolean) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'Error fetching all user tax filings forms',
    fetchKey: FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    alwaysFetch: forceFetch,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AnnualTaxFilingForm[]>(
        '/finances/api/v1/annual_tax_filing_forms'
      )

      dispatch(getAllAnnualTaxFilingForms(keyBy(json.data, 'id')))

      return json.data
    },
  })

export enum AnnualTaxFilingFormQuestionnaireStatus {
  'notStarted' = 'not_started',
  'started' = 'started',
  'submitted' = 'submitted',
}

export const UPDATE_ANNUAL_TAX_FILING_FORMS_KEY =
  'UPDATE_ANNUAL_TAX_FILING_FORMS_KEY'
export const updateAnnualTaxFilingForms = (
  id: number,
  data: Partial<AnnualTaxFilingForm>
) =>
  fetchWrapper({
    fetchKey: UPDATE_ANNUAL_TAX_FILING_FORMS_KEY,
    defaultErrorMessage: 'Error updating tax filing form',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<AnnualTaxFilingForm>(
        `/finances/api/v1/annual_tax_filing_forms/${id}`,
        data
      )
      dispatch(updateAnnualTaxFilingForm(json.data))
      return json.data
    },
  })

export const PUT_EXTEND_ANNUAL_TAX_FILING_FORMS_KEY =
  'UPDATE_ANNUAL_TAX_FILING_FORMS_KEY'
export const putExtendAnnualTaxFilingForm = (
  id: number,
  data: { extend: boolean }
) =>
  fetchWrapper({
    fetchKey: PUT_EXTEND_ANNUAL_TAX_FILING_FORMS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<{
        // More comes back here but some things are missing (namely formType) so just define what we need to set
        form: Pick<AnnualTaxFilingForm, 'isExtended' | 'updatedAt'>
      }>(`/finances/api/v1/annual_tax_filing_forms/${id}/updateExtension`, data)

      const { isExtended, updatedAt } = json.data.form
      // Not all required fields come back here so just update what changed
      dispatch(updatePartialAnnualTaxFilingForm({ id, isExtended, updatedAt }))
      // Since the action items can change here we need to refetch them the next time they are requested
      dispatch(invalidateFetch(FETCH_USER_ACTION_ITEMS_KEY))
      return json.data.form
    },
  })

export const putAnnualTaxFilingFormMessagesViewed = (id: number | string) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.put<AnnualTaxFilingForm>(
        `/finances/api/v1/annual_tax_filing_forms/${id}/messages/viewed`
      )
      dispatch(updateAnnualTaxFilingForm(json.data))
      return json.data
    },
  })

/*
  Fetch signing link for Form
*/

export const fetchAnnualTaxFilingFormHelloSignUrls = (id: number) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching annual tax filing form signature',
    fetchFunction: async () => {
      const json = await axios.get<{
        signingUrl: string
      }>(`/finances/api/v1/annual_tax_filing_forms/${id}/signature`)
      return json.data.signingUrl || null
    },
  })

export const updateAdminAnnualTaxFilingForm = (
  annualTaxFilingFormId: number,
  data: Partial<AnnualTaxFilingForm>
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating annual tax filing form',
    fetchFunction: async () => {
      const res = await axios.put<AnnualTaxFilingForm>(
        `/finances/api/v1/admin/annual_tax_filing_forms/${annualTaxFilingFormId}`,
        data
      )
      return res.data
    },
  })

export const FETCH_TAXFYLE_JOB_URL_KEY = 'FETCH_TAXFYLE_JOB_URL_KEY'
export const fetchTaxFyleJobUrl = (filingFormId?: number | string) =>
  fetchWrapper({
    fetchKey: FETCH_TAXFYLE_JOB_URL_KEY,
    fetchFunction: async () => {
      if (!filingFormId) {
        return undefined
      }

      const json = await axios.get<{ jobUrl: string }>(
        `/finances/api/v1/annual_tax_filing_forms/${filingFormId}/job/url`
      )

      return json.data
    },
  })

export const fetchTaxFyleExtensionJobUrl = (filingId?: number | string) =>
  fetchWrapper({
    fetchKey: FETCH_TAXFYLE_JOB_URL_KEY,
    fetchFunction: async () => {
      if (!filingId) {
        return undefined
      }

      const json = await axios.get<{ jobUrl: string }>(
        `/finances/api/v1/annual_tax_filings/${filingId}/job/url`
      )

      return json.data
    },
  })
