import { useEffect, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { groupBy, sortBy } from 'lodash'

import { useReselector } from '../../../../utils/sharedHooks'
import { selectUserTaxEstimates } from '../../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.selector'
import {
  FETCH_USER_TAX_ESTIMATES_KEY,
  fetchSingleUserTaxEstimates,
} from '../../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import { getIsFetching } from '../../../../reducers/fetch'
import { GridRowColumn, Text } from '../../../../components/BaseComponents'
import {
  addCurrencyArray,
  formatCurrencyFromCents,
} from '../../../../utils/currencyHelpers'
import { selectUserTaxCalculationById } from '../../../../selectors/userTaxCalculation.selectors'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const QtePaymentsPopup = ({ id }: { id: number }) => {
  const calc = useReselector(selectUserTaxCalculationById, id)

  const dispatch = useAppDispatch()
  const loading = useReselector(
    getIsFetching,
    FETCH_USER_TAX_ESTIMATES_KEY(calc?.userId)
  )
  const userTaxEstimates = useReselector(selectUserTaxEstimates, calc?.userId)

  useEffect(() => {
    calc?.userId && fetchSingleUserTaxEstimates(calc?.userId)(dispatch)
  }, [calc?.userId, dispatch])

  const yearEstimates = useMemo(
    () =>
      sortBy(
        Object.values(userTaxEstimates).filter(
          (te) =>
            calc?.calendarYear &&
            te.taxQuarter.startsWith(calc?.calendarYear) &&
            !te.taxQuarter.endsWith('4')
        ),
        'taxQuarter'
      ),
    [userTaxEstimates, calc?.calendarYear]
  )

  const totals = useMemo(
    () =>
      Object.entries(groupBy(yearEstimates, 'filingState')).map(
        ([key, estimates]) =>
          `${key === 'null' ? 'Federal' : key} - ${formatCurrencyFromCents(
            addCurrencyArray(estimates.map((est) => est.amountPaidInCents))
          )}`
      ),
    [yearEstimates]
  )

  return (
    <Grid>
      <GridRowColumn short>
        <Text as="bodyLg">Previous QTE Payments</Text>
      </GridRowColumn>

      {loading && !yearEstimates.length ? (
        <Text>Loading...</Text>
      ) : (
        <>
          {yearEstimates.map((est) => (
            <GridRowColumn key={est.id} short>
              <Text as="bodySm">
                <i>{est.taxQuarter}</i> - {est.filingState || 'Federal'} -{' '}
                {est.amountPaidInCents
                  ? formatCurrencyFromCents(est.amountPaidInCents)
                  : '--'}
              </Text>
            </GridRowColumn>
          ))}
          <GridRowColumn short>
            <Text>
              <u>Totals</u>
            </Text>
          </GridRowColumn>
          {totals.map((total) => (
            <GridRowColumn key={total} short>
              <Text as="bodySm">{total}</Text>
            </GridRowColumn>
          ))}
        </>
      )}
    </Grid>
  )
}

export default QtePaymentsPopup
