import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

import {
  Button,
  GridRowColumn,
  Loader,
  Text,
} from '../../../../components/BaseComponents'
import { updateFinancialProfile } from '../../../../actions/financialProfileActions'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import { dateWithoutTimestamp } from '../../QuarterlyTaxEstimates/service'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import {
  RECALCULATE_USER_TAX_CALCULATIONS_KEY,
  fetchUserTaxCalculationsIfNeeded,
  recalculateUserTaxCalculation,
  updateUserTaxCalculation,
} from '../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import { selectIsFetchingForKeys } from '../../../../reducers/fetch'

import { selectUserTaxCalculationWithinActiveQuarter } from '../../../../selectors/userTaxCalculation.selectors'

const FinishRecalculation = () => {
  const shouldUseRealTimeRecalculation = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.qteRealTimeRecalculation,
    false
  )

  const isCalculating = useReselector(selectIsFetchingForKeys, [
    RECALCULATE_USER_TAX_CALCULATIONS_KEY,
  ])

  const navigate = useNavigate()

  const fp = useReselector(getFinancialProfile)
  const calculation = useReselector(selectUserTaxCalculationWithinActiveQuarter)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      updateFinancialProfile(fp?.id, {
        taxProfileLastReviewed: dateWithoutTimestamp().format(
          DATE_FORMATS.TIMESTAMP
        ),
      })
    )
  }, [dispatch, fp?.id])

  useEffect(() => {
    if (!shouldUseRealTimeRecalculation) {
      if (calculation?.id) {
        dispatch(
          updateUserTaxCalculation(calculation.id, { needsRecalculation: true })
        )
      }
    } else {
      dispatch(recalculateUserTaxCalculation())
    }
  }, [dispatch, shouldUseRealTimeRecalculation, calculation?.id])

  useEffect(() => {
    dispatch(fetchUserTaxCalculationsIfNeeded(true))
  }, [dispatch])

  return (
    <Grid>
      {isCalculating ? (
        <Loader loading />
      ) : (
        <>
          <GridRowColumn centerContent>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/booklist.svg"
              style={{ height: 120, width: 120 }}
            />
          </GridRowColumn>
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <Text as="display" textAlign="center">
              You&apos;ve made changes to your tax information
            </Text>
          </GridRowColumn>
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <Text as="bodyLg" textAlign="center">
              You&apos;ll find your new federal tax estimate in the Quarterly
              Tax Center{!shouldUseRealTimeRecalculation && ' within an hour'}.
            </Text>
          </GridRowColumn>
          <GridRowColumn centerContent>
            <Button
              variant="secondary"
              onClick={() => navigate('/taxes/quarterly')}
            >
              Quarterly Tax Center
            </Button>
          </GridRowColumn>
          <Grid.Row />
        </>
      )}
    </Grid>
  )
}

export default FinishRecalculation
