import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RoleName } from '../../../reducers/admin/allRolesReducer'
import { fetchIfNeededWrapper } from '../../../reducers/fetch'
import { HTTP_METHODS } from './constants'

export interface SimulatedApiError {
  path: string
  httpMethod: typeof HTTP_METHODS
}

export interface Pathway {
  name: string
  description: string
  identifier: string
  simulatedApiErrors?: SimulatedApiError[]
  adminRoleName: RoleName
}

export interface Feature {
  name: string
  linearCode: string
  description: string
  pathways: Pathway[]
  createdAt: string
}

const QAFeatureSlice = createSlice({
  name: 'qaFeature',
  initialState: null as Feature[] | null,
  reducers: {
    setQAFeatures: (state, action: PayloadAction<Feature[]>) => [
      ...(state || []),
      ...action.payload,
    ],
  },
})

export default QAFeatureSlice.reducer

export const { setQAFeatures } = QAFeatureSlice.actions

export const FETCH_QA_FEATURES_KEY = 'FETCH_QA_FEATURES_KEY'
export const fetchQAFeaturesIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_QA_FEATURES_KEY,
    defaultErrorMessage: 'Error fetching all qa features',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Feature[]>(
        '/finances/api/v1/admin/qa/features'
      )

      dispatch(setQAFeatures(json.data))
      return json.data
    },
  })
