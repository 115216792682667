import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Icon,
  Pill,
  Text,
  CircularWrapper,
  BorderedIcon,
} from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectDraftReviewStatusForForm,
  selectFileExtensionStatusForYear,
  selectFileNecStatusByYear,
  selectFilingStatusForForm,
  selectFormTQStatus,
  selectTaxSeasonKickoffStatusByYear,
  selectShowFileExtensionForYear,
  selectShowNeedContractorFilingByYear,
  selectTaxPreparationStatusForForm,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import {
  selectAnnualTaxFilingFormById,
  selectFilingFormsForYear,
} from '../annualTaxFilingForms.selector'
import { FORM_1040_TYPE } from '../Questionnaires/constants'
import { createClassName } from '../../../../utils/styleHelpers'
import { PillProps } from '../../../../components/BaseComponents/Pill'
import {
  selectCurrentStepForForm,
  selectCurvedLineStyles,
  selectDividingLine2Styles,
  selectDividingLineStyles,
  selectIsCurveGreen,
} from './statusCard.selectors'
import './AnnualTaxOverview.scss'

// Curved line into the bottom status line
const CurvedLine = ({ year }: { year: string }) => {
  const isCurveGreen = useReselector(selectIsCurveGreen, year)
  const curvedLineStyles = useReselector(selectCurvedLineStyles, year)

  return (
    <div
      className={createClassName([
        'curve-line-wrapper',
        isCurveGreen && 'green',
      ])}
      style={curvedLineStyles}
    >
      <div className="curve-wrapper-top">
        <div />
      </div>
      <div className="curve-wrapper-bottom">
        <div />
      </div>

      <div className="diagonal-line" />
    </div>
  )
}

const ContentColumn = ({
  status,
  showLabelForFormType,
}: {
  status: AnnualTaxFilingStepStatus
  showLabelForFormType?: string
}) => {
  const formLabel = useMemo(() => {
    return showLabelForFormType ? (
      <Text
        color="oak"
        style={{ marginTop: 5, position: 'absolute', top: 24 }}
        as="bodySm"
      >
        <Icon
          icon={
            showLabelForFormType === FORM_1040_TYPE
              ? regular('user')
              : regular('buildings')
          }
          style={{ marginRight: 10 }}
          color="oak"
        />
        {showLabelForFormType === FORM_1040_TYPE ? 'Personal' : 'Business'}
      </Text>
    ) : null
  }, [showLabelForFormType])

  const circleContent = useMemo(() => {
    let pillColor: PillProps['color']
    let pillText = ''

    switch (status) {
      case AnnualTaxFilingStepStatus.completeLocked:
      case AnnualTaxFilingStepStatus.completeUnlocked:
        return (
          <BorderedIcon
            icon={regular('check')}
            color="white"
            wrapperColor="green"
            height={24}
          />
        )
      case AnnualTaxFilingStepStatus.upcoming:
        return (
          <CircularWrapper
            height={24}
            wrapperColor="white"
            style={{ border: `solid ${Colors.lightGray} 1px` }}
          />
        )

      case AnnualTaxFilingStepStatus.notStarted:
        pillColor = 'green'
        pillText = 'GET STARTED'
        break
      case AnnualTaxFilingStepStatus.inProgress:
        pillColor = 'yellow'
        pillText = 'IN PROGRESS'
        break
      case AnnualTaxFilingStepStatus.comingSoon:
        pillColor = 'neutral'
        pillText = 'COMING SOON'
        break
      case AnnualTaxFilingStepStatus.readyForReview:
        pillColor = 'green'
        pillText = 'READY FOR REVIEW'
        break
      case AnnualTaxFilingStepStatus.readyToSign:
        pillColor = 'green'
        pillText = 'READY TO SIGN'
        break
      default:
        break
    }

    if (pillColor) {
      return (
        <>
          <Pill
            color={pillColor}
            variant="light"
            style={{ marginTop: 4, whiteSpace: 'nowrap' }}
          >
            {pillText}
          </Pill>
          {formLabel}
        </>
      )
    }

    return null
  }, [formLabel, status])

  return <Grid.Column style={{ zIndex: 3 }}>{circleContent}</Grid.Column>
}

const FilingFormLine = ({ filingFormId }: { filingFormId?: number }) => {
  // The filing form might will be null in the case where filing forms haven't been created yet
  // The selectors should handle and return upcoming for this case
  const filingForm = useReselector(selectAnnualTaxFilingFormById, filingFormId)
  const tqStatus = useReselector(selectFormTQStatus, filingFormId)
  const taxPreparationStatus = useReselector(
    selectTaxPreparationStatusForForm,
    filingFormId
  )
  const draftReviewStatus = useReselector(
    selectDraftReviewStatusForForm,
    filingFormId
  )
  const filingStatus = useReselector(selectFilingStatusForForm, filingFormId)
  const currentStep = useReselector(selectCurrentStepForForm, filingFormId)

  return (
    <>
      {[tqStatus, taxPreparationStatus, draftReviewStatus, filingStatus].map(
        (status, index) => (
          <ContentColumn
            key={index}
            status={status}
            showLabelForFormType={
              currentStep === index ? filingForm?.formType.name : undefined
            }
          />
        )
      )}
    </>
  )
}

const AnnualTaxFilingStatusCard = ({ year }: { year: string }) => {
  const taxSeasonKickoffStatus = useReselector(
    selectTaxSeasonKickoffStatusByYear,
    year
  )
  const fileNecStatus = useReselector(selectFileNecStatusByYear, year)
  const showContractorFiling = useReselector(
    selectShowNeedContractorFilingByYear,
    year
  )
  const showFileExtension = useReselector(selectShowFileExtensionForYear, year)
  const fileExtensionStatus = useReselector(
    selectFileExtensionStatusForYear,
    year
  )
  const filingForms = useReselector(selectFilingFormsForYear, year)

  const steps = useMemo(
    () => [
      'Tax Season Kickoff',
      ...(showContractorFiling ? ['File 1099-NECs'] : []),
      ...(showFileExtension ? ['Extensions'] : []),
      'Tax Checklist',
      'Tax Preparation',
      'Tax Draft Return',
      'Tax Return Filing',
    ],
    [showContractorFiling, showFileExtension]
  )

  const formLines = useMemo(() => {
    if (filingForms.length) {
      return filingForms.map((form) => (
        <FilingFormLine key={form.id} filingFormId={form.id} />
      ))
    }

    return [<FilingFormLine key={0} />]
  }, [filingForms])

  const dividingLineStyles = useReselector(
    selectDividingLineStyles,
    filingForms[0]?.id
  )
  const dividingLine2Styles = useReselector(
    selectDividingLine2Styles,
    filingForms[1]?.id
  )

  return (
    // Tax statuses should be scrollable on smaller screen
    <GridRowColumn style={{ overflow: 'auto hidden' }}>
      <Grid className="annualTaxFilingStatusCards">
        {/*Mapping of labels*/}
        <Grid.Row columns="equal" style={{ paddingTop: 8, paddingBottom: 5 }}>
          {steps.map((step, index) => (
            <Grid.Column key={step} textAlign="center">
              <Text as="bodySm" style={{ fontSize: 12, marginBottom: 5 }}>
                {`${index + 1}. ${step}`}
              </Text>
            </Grid.Column>
          ))}
        </Grid.Row>

        {/*Mapping of the first form statuses*/}
        <Grid.Row columns="equal" style={{ paddingBottom: 0, paddingTop: 0 }}>
          {formLines[1] && <CurvedLine year={year} />}
          <div className="dividing-line" style={dividingLineStyles} />
          {[
            taxSeasonKickoffStatus,
            ...(showContractorFiling ? [fileNecStatus] : []),
            ...(showFileExtension ? [fileExtensionStatus] : []),
          ].map((status, index) => (
            <ContentColumn key={index} status={status} />
          ))}
          {formLines[0]}
        </Grid.Row>

        {/*Mapping of the second form statuses*/}
        {formLines[1] && (
          <Grid.Row
            columns="equal"
            style={{ paddingBottom: 0, marginBottom: 25, marginTop: 25 }}
          >
            <div className="lower-dividing-line" style={dividingLine2Styles} />
            <Grid.Column />
            {showContractorFiling && <Grid.Column />}
            {showFileExtension && <Grid.Column />}
            {formLines[1]}
          </Grid.Row>
        )}
      </Grid>
    </GridRowColumn>
  )
}

export default AnnualTaxFilingStatusCard
