import { useEffect } from 'react'
import { Loader } from 'semantic-ui-react'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import styled from 'styled-components'
import SetupLandingCardItems from './SetupLandingCardItems'
import { Button, GridRowColumn, Text } from '../../../components/BaseComponents'
import { useNavigate } from 'react-router-dom'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'

export const ContainerStyle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'right',
  gap: '16px',
  marginLeft: '160px',
  marginRight: '160px',
  marginTop: '80px',
  padding: '0px 32px 0px 32px',
})

const cardData = [
  {
    stepNumber: 1,
    headerText: 'Connect your business bank accounts',
    bodyText:
      'We automatically sync your transactions into Heard via bank connections.',
    timeEstimate: '5 minutes',
    imageSrc:
      'https://heard-images.s3.amazonaws.com/assets/pavilion_shadow.png',
    imageAlt: 'pavilion',
  },
  {
    stepNumber: 2,
    headerText: 'Tell us about your accounts',
    bodyText:
      'This lets us know if there are any special considerations around your accounts.',
    timeEstimate: '5 minutes',
    imageSrc: 'https://heard-images.s3.amazonaws.com/assets/laptop-only.png',
    imageAlt: 'laptop',
  },
  {
    stepNumber: 3,
    headerText: 'Upload monthly statements',
    bodyText:
      'We use your monthly statements to double-check your bookkeeping every month.',
    timeEstimate: '10 minutes',
    imageSrc: 'https://heard-images.s3.amazonaws.com/assets/statement.png',
    imageAlt: 'statement',
  },
]

const SetupLandingCard = () => {
  const currentUser = useReselector(getCurrentUser)
  const navigate = useNavigate()
  const pageView = useAnalyticsView()
  const includeBaa = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.addBaaToGuidedOnboarding,
    false
  )

  const track = useAnalyticsTrack()

  useEffect(() => {
    pageView('onboarding items')
  }, [pageView])

  if (!currentUser) {
    return <Loader active />
  }

  const handleContinueClick = () => {
    includeBaa
      ? navigate('/onboarding-v2?stage=connect&step=baa')
      : navigate('/onboarding-v2?stage=connect&step=prescreen')

    track('completed onboarding items page')
  }

  return (
    <ContainerStyle>
      <Text as="display2">Get Started with Heard </Text>
      <Text
        as="bodyLg"
        style={{
          marginBottom: '16px',
        }}
      >
        In these next steps, you&apos;ll get your account set up. This powers
        everything in Heard, from bookkeeping, practice insights, and tax
        preparation.
      </Text>
      <div
        style={{
          gap: '40px',
        }}
      >
        {cardData.map((card, index) => (
          <SetupLandingCardItems
            key={index}
            stepNumber={card.stepNumber}
            headerText={card.headerText}
            bodyText={card.bodyText}
            timeEstimate={card.timeEstimate}
            imageSrc={card.imageSrc}
            imageAlt={card.imageAlt}
          />
        ))}
      </div>
      <GridRowColumn
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button variant="primary" size="medium" onClick={handleContinueClick}>
          Continue
        </Button>
      </GridRowColumn>
    </ContainerStyle>
  )
}

export default SetupLandingCard
