import React, { useState } from 'react'
import { adminDeleteTransaction } from '../../../actions/adminActions'
import { Button, Confirm, Message } from 'semantic-ui-react'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const useDeleteTransaction = ({
  transaction,
  onDeleted = () => undefined,
}: {
  transaction: Transaction
  onDeleted?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [isDeleting, setDeleting] = useState(false)
  const [open, setOpen] = useState<boolean>(false)

  const deleteTransaction = async () => {
    setDeleting(true)
    try {
      await adminDeleteTransaction(Number(transaction.id))(dispatch)
      onDeleted()
      setDeleting(false)
    } catch (err) {
      setOpen(false)
      setDeleting(false)
    }
  }

  const renderConfirm = () => (
    <Confirm
      open={open}
      size="small"
      onConfirm={() => deleteTransaction()}
      onCancel={() => setOpen(false)}
      header={`Delete transaction "${transaction.description}"?`}
      content={
        <div style={{ padding: '2em' }}>
          <Message>
            Please make sure that you are 100% certain you want to delete this
            transaction.
          </Message>
          <p>
            <b>Transaction:</b> {transaction.description}
          </p>
          <p>
            <b>ID:</b> {transaction.id}
          </p>
        </div>
      }
    />
  )

  return {
    renderConfirm,
    isDeleting,
    showConfirm: () => setOpen(true),
  }
}

interface Props {
  transaction: Transaction
}

const DeleteTransactionButton: React.FC<Props> = ({ transaction }) => {
  const { isDeleting, showConfirm, renderConfirm } = useDeleteTransaction({
    transaction,
  })

  return (
    <>
      <Button
        className="link"
        onClick={() => showConfirm()}
        disabled={isDeleting}
      >
        Delete Transaction
      </Button>
      {renderConfirm()}
    </>
  )
}

export default DeleteTransactionButton
