import { ReactNode } from 'react'
import { uniqBy } from 'lodash'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { RoleName } from '../../../reducers/admin/allRolesReducer'

export const placeholders = [
  '{customer.firstName}',
  '{customer.lastName}',
  '{sender.firstName}',
  '{sender.lastName}',
]

// Returns active customers: not admin, state is active, have a zendeskId
export const filterForActiveCustomers = (users: UserWithAdminInfo[]) =>
  users.filter((u) => u.state === 'active' && !u.admin && u.zendeskId !== null)

export function replacePlaceholdersInString(
  input: string,
  customer: UserWithAdminInfo
) {
  let result = input

  // replace all placeholders with appropriate values
  placeholders.forEach((ph) => {
    const regex = new RegExp(ph, 'g')

    if (ph === placeholders[0] && customer.firstName) {
      // customer.firstName
      result = result.replace(regex, customer.firstName)
    } else if (ph === placeholders[1] && customer.lastName) {
      // customer.lastName
      result = result.replace(regex, customer.lastName)
    } else if (ph === placeholders[2] && customer.owner) {
      // am.firstName
      result = result.replace(regex, customer.owner.firstName)
    } else if (ph === placeholders[3] && customer.owner) {
      // am.lastName
      result = result.replace(regex, customer.owner.lastName)
    }
  })

  return result
}

export function getCustomersForAm({
  amId,
  customers,
}: {
  amId: number
  customers: UserWithAdminInfo[]
}) {
  return customers.filter(
    (c) =>
      c.owner?.roles?.find((role) => role?.name === RoleName.AccountManager)
        ?.UserRole.userId === amId
  )
}

/**
 * Creates a fullname preview for the first 3 customers belonging to an AM
 */
export function recipientsPreviewForSelectedAm({
  customers,
}: {
  customers: UserWithAdminInfo[]
}) {
  if (customers.length === 0) {
    return ''
  }

  const NUM_CUSTOMERS_TO_PREVIEW = 3

  // First thing customer names separated by commas
  let preview = customers
    .slice(0, NUM_CUSTOMERS_TO_PREVIEW)
    .map((r) => `${r.firstName} ${r.lastName}`)
    .join(', ')

  // Add "+ N more" string if excess exist
  if (customers.length > NUM_CUSTOMERS_TO_PREVIEW) {
    preview += ` + ${customers.length - NUM_CUSTOMERS_TO_PREVIEW} more`
  }

  // Prepend the AMs full name
  const am = customers[0].owner
  preview = am ? `${am.firstName} ${am.lastName}'s customers - ${preview}` : ''

  return preview
}

export function recipientFormOptions(
  customers: UserWithAdminInfo[],
  renderFn: (content: string, subHeader: string) => ReactNode
) {
  const options = [
    {
      key: 1,
      text: 'All active customers',
      value: -1,
      content: renderFn(
        'All active customers',
        `${customers.length} active customers`
      ),
    },
  ]

  const amsDup = customers.map((customer) => customer.owner)
  const ams = uniqBy(amsDup, (c) => c?.uuid)

  ams.forEach((am, i) => {
    if (!am) {
      return
    }

    const title = `${am.firstName} ${am.lastName}'s customers`
    /**
     * OK to use top 0th index of User Role here as User id is the
     * same regardless
     */
    const amId = am.roles[0].UserRole.userId

    const amCustomers = getCustomersForAm({
      amId,
      customers,
    })

    options.push({
      key: i + 2,
      text: title,
      value: amId,
      content: renderFn(title, `${amCustomers.length} active customers`),
    })
  })

  return options
}
