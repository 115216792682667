import { useEffect, useState } from 'react'
import { Fonts, Colors } from '../../../styles/theme'
import { Alert, Link, Loader } from '../../BaseComponents'
import { fetchPlaidStatementInstitutionsIfNeeded } from '../../../actions/plaidStatementInstitutionActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { selectEligibleForPlaidStatements } from '../../../selectors/financeSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { useAnalyticsTrack } from '../../../features/Amplitude'

export const PlaidStatementAlert = () => {
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const hasEligibleInstitution = useReselector(selectEligibleForPlaidStatements)
  const track = useAnalyticsTrack()

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchPlaidItemsIfNeeded())
      await dispatch(fetchPlaidStatementInstitutionsIfNeeded())
      setFetching(false)
    }

    fetch()
  }, [dispatch])

  if (!hasEligibleInstitution) {
    return null
  }

  if (fetching) {
    return <Loader />
  }

  return (
    <Alert
      fullWidth
      closeable
      stackOnMobile
      type="info"
      permanentDismissalKey="plaidStatementAccountBannerDismissed"
      title="Save time with automatic statement uploads"
      style={{ backgroundColor: Colors.stone40 }}
      innerTextStyle={{ ...Fonts.bodySm }}
      onClose={() => track('close plaid statement alert')}
      customIcon={
        <img
          src="https://heard-images.s3.amazonaws.com/assets/statement_upload.svg"
          alt="banner"
          height={140}
          width={140}
        />
      }
    >
      <div style={{ margin: '8px 0' }}>
        Some of your financial institutions now support automatic statement
        uploads. After setting this up, you won&apos;t have to manually uploads
        statements every month for those accounts!
      </div>
      <div style={{ marginBottom: 8 }}>
        Re-login to your financial institution in Plaid to activate.
      </div>
      <Link
        newPage
        href="https://support.joinheard.com/hc/en-us/articles/24343313630103/"
        as="actionLink"
      >
        Learn more &#8594;
      </Link>
    </Alert>
  )
}
