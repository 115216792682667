import React from 'react'

// BL
import { RowModel } from './SplitTransactionForm'

// UI
import { Button, Form, Step } from 'semantic-ui-react'
import { DropdownOption } from '../../../BaseComponents/Dropdown'
import { Dropdown, Input } from '../../../BaseComponents'

interface Props {
  categoryOptions: DropdownOption<number>[]
  index: number
  model: RowModel
  onRowChange: (_index: number, _key: string, _value: string) => void
  onRowDelete: (_index: number) => void
}

const SplitTransactionRow: React.FC<Props> = ({
  categoryOptions,
  index,
  model,
  onRowChange,
  onRowDelete,
}) => {
  const onDeleteClick = () => {
    onRowDelete(index)
  }

  const CategoryDropdown = () => (
    <Dropdown
      disabled={model.fields['expenseType'] === 'personal'}
      placeholder="Select Category"
      value={
        model.fields['expenseType'] === 'personal'
          ? undefined
          : Number(model.fields['categoryId']) || undefined
      }
      options={categoryOptions}
      onChange={(value) => {
        value && onRowChange(index, 'categoryId', value.toString())
      }}
      search
      clearable
    />
  )

  const ExpenseTypeDropdown = () => {
    const options = [
      { key: 'business', text: 'Business Expense', value: 'business' },
      { key: 'personal', text: 'Personal Expense', value: 'personal' },
    ]
    return (
      <Dropdown
        defaultValue={model.fields['expenseType']}
        onChange={(value) => {
          onRowChange(index, 'expenseType', value)
        }}
        value={model.fields['expenseType']}
        options={options}
        placeholder="Expense Type"
        style={{ height: '100%' }}
      />
    )
  }

  return (
    <Step>
      <Step.Content style={{ width: '100%' }}>
        <Form.Group style={{ marginBottom: '0px' }} widths="equal">
          <Form.Field control={ExpenseTypeDropdown} />
          <Input
            componentType="currency"
            placeholder="Amount"
            name="amountInCents"
            value={model.fields['amount']}
            onChange={(value) => onRowChange(index, 'amount', value.toString())}
            required
          />
          <Form.Field control={CategoryDropdown} />
          <Button
            basic
            icon="trash alternate outline"
            onClick={onDeleteClick}
            style={{ visibility: model.deletable ? 'visible' : 'hidden' }}
          />
        </Form.Group>
      </Step.Content>
    </Step>
  )
}

export default SplitTransactionRow
