import { useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  Checkbox,
  GridRowColumn,
  Icon,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import FileUploadModal from '../../../../../components/FileUpload/FileUploadModal'
import { useReselector } from '../../../../../utils/sharedHooks'
import UploadedDocument from './UploadedDocument'
import { selectUserDocumentCategoryByInternalName } from '../../../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { UploadDocumentType } from '../../../../../constants/businessConstants'
import { selectUserTaxDocumentsForCategory } from '../../../../UserDocuments/userDocuments.selector'
import { Colors } from '../../../../../styles/theme'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectFilingFormsForYear } from '../../annualTaxFilingForms.selector'
import { FORM_1120_S_TYPE } from '../../Questionnaires/constants'
import {
  selectIsMarriedFilingJointlyOrQualifyingWidow,
  selectOtherTaxDocuments,
} from '../taxChecklist.selectors'
import { TaxListQuestionId } from '../service'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'

const UploadCard = ({
  formId,
  docCategoryIdentifier,
  isOtherCat,
  styleOverrides = {},
  isRequired,
  skipped,
  updateSkipped,
  readOnly,
}: {
  formId: number
  docCategoryIdentifier: string
  isOtherCat?: boolean
  styleOverrides?: {
    skipDescription?: boolean
    cardBackgroundColor?: keyof typeof Colors
  }
  isRequired?: boolean
  skipped?: boolean
  updateSkipped?: (identifier: string, checked: boolean) => void
  readOnly?: boolean
}) => {
  const [modelOpen, setModalOpen] = useState(false)
  const { skipDescription, cardBackgroundColor } = styleOverrides
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const previousTaxYear = (Number(currentTaxYear) - 1).toString()
  const usePreviousTaxYear = /final_return/i.test(
    docCategoryIdentifier.toLowerCase()
  )
  const filingForms = useReselector(selectFilingFormsForYear, currentTaxYear)
  const isMfjOrWidow = useReselector(
    selectIsMarriedFilingJointlyOrQualifyingWidow,
    TaxListQuestionId.filing_status,
    currentTaxYear
  )

  const taxDocCat = useReselector(
    selectUserDocumentCategoryByInternalName,
    docCategoryIdentifier
  )

  // include all final_return categories (generic, 1120s, 1040)
  const taxDocumentsForCategory = useReselector(
    selectUserTaxDocumentsForCategory,
    docCategoryIdentifier,
    usePreviousTaxYear ? previousTaxYear : currentTaxYear
  )
  const otherTaxDocuments = useReselector(
    selectOtherTaxDocuments,
    formId,
    currentTaxYear
  )

  const taxDocuments = useMemo(() => {
    if (isOtherCat) {
      return otherTaxDocuments
    }
    return taxDocumentsForCategory
  }, [isOtherCat, otherTaxDocuments, taxDocumentsForCategory])

  // Since the descriptions are dynamic now, we should probably refactor these for next year
  const docDescription = useMemo(() => {
    const currentForm = filingForms?.find((form) => form.id === formId)
    const is1120s = currentForm?.formType?.name === FORM_1120_S_TYPE
    const isTwoFormFiler = filingForms?.length > 1
    const spouseText =
      'Please also upload any of these documents that apply to your spouse.'
    if (is1120s) {
      if (
        taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099nec
      ) {
        return 'If your S Corp business was contracted by another party.'
      }
      if (
        taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099int
      ) {
        return `This form is provided when a person or entity paid your private practice interest during the ${currentTaxYear} tax year. This form only applies to your private practice.`
      }
      if (
        taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099div
      ) {
        return 'This form is created by banks or other financial institutes to report dividends and other distributions made. This form only applies to your private practice.'
      }
    }
    if (
      !is1120s &&
      isTwoFormFiler &&
      taxDocCat?.internalName === UserDocumentCategoryIdentifier.scheduleK1
    ) {
      return `${taxDocCat.description} You'll need your Schedule K-1 to file with your personal tax return, which becomes available after your business tax return has been filed. If your tax preparer didn't upload the K-1 form on its own, you can still find it within your business tax return.`
    }
    let description = taxDocCat?.description
    if (
      isMfjOrWidow &&
      taxDocCat &&
      [
        UserDocumentCategoryIdentifier.form1099nec,
        UserDocumentCategoryIdentifier.form1099misc,
        UserDocumentCategoryIdentifier.form1098,
        UserDocumentCategoryIdentifier.form1099b,
        UserDocumentCategoryIdentifier.form1099div,
        UserDocumentCategoryIdentifier.form1099int,
        UserDocumentCategoryIdentifier.formW2g,
        UserDocumentCategoryIdentifier.form1099sa,
        UserDocumentCategoryIdentifier.form1095a,
        UserDocumentCategoryIdentifier.form1095b,
        UserDocumentCategoryIdentifier.form1095c,
      ].includes(taxDocCat.internalName as UserDocumentCategoryIdentifier)
    ) {
      description = `${description} ${spouseText}`
    }
    return description
  }, [taxDocCat, filingForms, isMfjOrWidow, currentTaxYear, formId])

  const tooltip = useMemo(() => {
    const isTwoFormFiler = filingForms?.length > 1
    const currentForm = filingForms?.find((form) => form.id === formId)
    const is1120s = currentForm?.formType?.name === FORM_1120_S_TYPE
    if (
      !is1120s &&
      isTwoFormFiler &&
      taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099k
    ) {
      return 'If you already uploaded a 1099-K with your 1120-S return, you will only need to upload another one if you used a payment processor under your own SSN, instead of the EIN of your S-Corp.'
    }
    return null
  }, [filingForms, taxDocCat, formId])

  const cardBackground =
    cardBackgroundColor ||
    (taxDocuments.length || isOtherCat || skipped ? 'stone40' : 'natural')
  return (
    <GridRowColumn short>
      <Card backgroundColor={cardBackground} type="subsection">
        <FileUploadModal
          open={modelOpen}
          close={() => setModalOpen(false)}
          userFacing
          documentType={taxDocCat?.type || UploadDocumentType.TAX}
          extraPayload={{
            annualTaxFilingFormId: formId,
          }}
          categoryId={taxDocCat?.id}
          // Remove checked skipped status once upload is complete
          setUploadedFile={() => updateSkipped?.(docCategoryIdentifier, false)}
          year={usePreviousTaxYear ? previousTaxYear : currentTaxYear}
        />
        <Grid>
          <Grid.Row>
            {isOtherCat ? (
              <Grid.Column width={13}>
                <Text>
                  If there are any other documents you’d like us to review,
                  upload them here. If our tax team requires anything additional
                  from you, we’ll reach out directly.
                </Text>
              </Grid.Column>
            ) : (
              <>
                <Grid.Column
                  width={skipDescription ? 11 : 4}
                  verticalAlign="middle"
                >
                  <Text as="h3" color={skipped ? 'mediumGray' : 'black'}>
                    {taxDocCat?.title}
                  </Text>
                </Grid.Column>
                {!skipDescription && (
                  <Grid.Column width={9} verticalAlign="middle">
                    <Text color={skipped ? 'mediumGray' : 'black'}>
                      {docDescription}
                      {tooltip && <Popup content={tooltip} />}
                    </Text>
                  </Grid.Column>
                )}
              </>
            )}

            <Grid.Column width={skipDescription ? 5 : 3} verticalAlign="middle">
              {!readOnly && (
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => setModalOpen(true)}
                >
                  <Icon
                    icon={regular('upload')}
                    size="1x"
                    style={{ marginRight: 10 }}
                  />
                  Upload
                </Button>
              )}
              {readOnly && taxDocuments.length === 0 && (
                <Text textAlign="right" color="darkGray">
                  <i>No Uploads</i>
                </Text>
              )}
            </Grid.Column>
          </Grid.Row>
          {taxDocuments.map((td) => (
            <UploadedDocument key={td.id} id={td.id} readOnly={readOnly} />
          ))}
          {!taxDocuments.length && !isRequired && (
            <GridRowColumn
              spacer={skipDescription ? 11 : 13}
              width={skipDescription ? 5 : 3}
            >
              <Checkbox
                label="I don't have this"
                checked={skipped}
                onClick={() => updateSkipped?.(docCategoryIdentifier, !skipped)}
                variant="default"
                disabled={readOnly}
              />
            </GridRowColumn>
          )}
        </Grid>
      </Card>
    </GridRowColumn>
  )
}

export default UploadCard
