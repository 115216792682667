import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  FormikInput,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
  makeNumberSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
  withholdingRecommendedMinimumPercentage,
} from './helpers'
import {
  addCurrencyArray,
  dollarsToCents,
  dollarsToCurrency,
} from '../../../../utils/currencyHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'
import ExamplePaystub from './ExamplePaystub'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../../Amplitude'

const WithholdingsForm = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const fp = useReselector(getFinancialProfile)
  const track = useAnalyticsTrack()
  const totalWIncome = addCurrencyArray([
    fp?.scorpPracticeWIncomeInCents,
    fp?.wIncomeInCents,
    fp?.estimatedAnnualScorpPracticeW2IncomeInCents,
    fp?.estimatedAnnualW2IncomeInCents,
  ])

  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.withholdings
  )

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      federalWithholding: formFlowAnswers.federalWithholding,
      stateWithholding: formFlowAnswers.stateWithholding,
    },
    onSubmit: async ({ federalWithholding, stateWithholding }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          federalWithholdingInCents: dollarsToCents(federalWithholding),
          stateWithholdingInCents: dollarsToCents(stateWithholding),
        })
      )

      if (res) {
        setFormFlowAnswers({
          ...formFlowAnswers,
          federalWithholding,
          stateWithholding,
        })
        track('self withholdings submitted', {
          federal_withholding_in_cents: dollarsToCents(federalWithholding),
          state_withholding_in_cents: dollarsToCents(stateWithholding),
          user_id: fp?.userId ?? null,
        })
        goToNextStep(res)
      }
    },
  })

  const { values, submitForm, isValid, isSubmitting } = formik

  const federalWithholdingSchema = makeNumberSchema({
    allowedDecimals: 2,
    required: false,
  })
    .test(
      'federalWithholdingValidation',
      'Your withholdings must be less than the W-2 income you shared on a previous step. Please double-check your paystub.',
      (federalWithholding) => {
        const withholding = dollarsToCurrency(federalWithholding).value

        return !withholding || totalWIncome > withholding
      }
    )
    .test(
      'federalWithholdingGreaterThanZeroValidation',
      'Your withholdings cannot be $0. Please double-check your paystub.',
      (federalWithholding) => {
        //don't throw an error when they first arrive to the page
        if (!federalWithholding) {
          return true
        }
        const withholding = dollarsToCurrency(federalWithholding).value

        return withholding > 0
      }
    )

  const stateWithholdingSchema = makeNumberSchema({
    allowedDecimals: 2,
  }).test(
    'stateWithholdingValidation',
    'Your withholdings must be less than the W-2 income you shared on a previous step. Please double-check your paystub.',
    (stateWithholding) => {
      const withholding = dollarsToCurrency(stateWithholding).value

      return !withholding || totalWIncome > withholding
    }
  )

  const withholdingPercentage = useMemo(
    () =>
      Math.floor(
        (dollarsToCurrency(values.federalWithholding).value / totalWIncome) *
          100
      ),
    [values.federalWithholding, totalWIncome]
  )

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <ExamplePaystub zoomTo="withholdings" />
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="display2" textAlign="center">
            What are your total W-2 year-to-date tax withholdings?
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([12, 16, 16], true)}>
          <Text as="bodyLg" textAlign="center">
            Please enter the total of all tax withholdings from your W-2 job(s).
            You can find these numbers on your most recent paystub(s).
          </Text>
        </GridRowColumn>
        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <Grid.Row />
        <GridRowColumn {...makeGridConfig([10, 16], true)}>
          <FormikInput
            name={getFieldName<typeof values>('federalWithholding')}
            label="Year-to-Date Federal Tax Withholdings"
            required
            fullWidth
            componentType="currency"
            schema={federalWithholdingSchema}
          />
        </GridRowColumn>
        {Boolean(
          values.federalWithholding &&
            withholdingPercentage < withholdingRecommendedMinimumPercentage
        ) && (
          <GridRowColumn short {...makeGridConfig([10, 16], true)}>
            <Alert
              customIcon={<Icon icon={regular('stars')} />}
              title={`You are withholding ${withholdingPercentage}% of your income`}
            >
              On average, therapists who earn W-2 income withhold{' '}
              {withholdingRecommendedMinimumPercentage}% for taxes. Not
              withholding enough taxes increases your year-end tax bill. This is
              simply a recommendation for you to consider. Learn how to
              calculate your withholdings{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/22225192416151"
                newPage
              >
                here
              </Link>
              .
            </Alert>
          </GridRowColumn>
        )}
        <GridRowColumn {...makeGridConfig([10, 16], true)}>
          <FormikInput
            name={getFieldName<typeof values>('stateWithholding')}
            label="Year-to-Date State Tax Withholdings"
            required
            fullWidth
            componentType="currency"
            schema={stateWithholdingSchema}
          />
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row />
        <TaxProfileAccordions
          faqs={[
            faqCopy.alreadyPaidW2Withholdings,
            faqCopy.whatAreYTDTaxWithholdings,
            faqCopy.whatIfMultipleW2,
            faqCopy.whatIfHomeStateNoTax,
          ]}
        />
        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default WithholdingsForm
