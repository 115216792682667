import { Accordion, Button, Text } from '../../BaseComponents'
import { List } from 'semantic-ui-react'
import { Colors } from '../../../styles/theme'
import { useState } from 'react'
import ShowAccountModal from './ShowAccountModal'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { AccountBanner } from './InstitutionList'
import { useAnalyticsTrack } from '../../../features/Amplitude'

const HiddenAccountsAccordion = ({
  hiddenAccounts,
  institutionName,
  setAccountBanner,
  disconnectedOn,
  initialOpen,
}: {
  hiddenAccounts: FinancialAccount[]
  institutionName: string
  setAccountBanner: (accountBanner: AccountBanner | null) => void
  disconnectedOn: string | null
  initialOpen: boolean
}) => {
  const [showModalOpen, setShowModalOpen] = useState(false)
  const [accountToShow, setAccountToShow] = useState<FinancialAccount | null>(
    null
  )
  const track = useAnalyticsTrack()

  return (
    <Accordion
      initialOpen={initialOpen}
      title="Hidden accounts"
      variant={'text'}
      style={{ marginTop: 16 }}
      content={
        <List as="ul">
          {hiddenAccounts.map((account) => {
            return (
              <List.Item
                as="li"
                key={account.id}
                style={{ color: Colors.darkGray }}
              >
                {account.name} {account.mask}{' '}
                <Button
                  variant={'secondaryLink'}
                  style={{ marginLeft: 4 }}
                  disabled={Boolean(disconnectedOn)}
                  inline
                  onClick={() => {
                    setAccountToShow(account)
                    setShowModalOpen(true)
                    track('clicked review bank account button')
                  }}
                >
                  <Text as={'buttonSm'}>Show</Text>
                </Button>
              </List.Item>
            )
          })}
          {accountToShow && (
            <ShowAccountModal
              open={showModalOpen}
              account={accountToShow}
              onClose={() => {
                setShowModalOpen(false)
                setAccountToShow(null)
              }}
              setAccountBanner={setAccountBanner}
            />
          )}
        </List>
      }
      tooltip={{
        title: 'Hidden accounts',
        body: `Connections to these accounts will still appear on the ${institutionName} website, but all transactions will be hidden on Heard.`,
      }}
    />
  )
}

export default HiddenAccountsAccordion
