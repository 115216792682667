import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Divider, Form, Grid, Icon, Loader } from 'semantic-ui-react'
import { isEqual, isNil } from 'lodash'

import {
  FinancialProfile,
  PercentageIncomePerState,
} from '../../../../reducers/auth/userReducer'
import {
  Dispatch,
  inOperator,
  ReduxState,
  wrapDispatch,
} from '../../../../utils/typeHelpers'
import { taxProfileForm as copy } from '../../../../features/Taxes/QuarterlyTaxEstimates/copyConstants'
import {
  FILING_STATUSES,
  TAX_PROFILE_VERSION,
  TAX_CENTER_URL_PATHNAME,
  USER_DOCUMENTS_URL_PATHNAME,
  getUnusedOptions,
  databaseColumns as KEYS,
  TaxEntityTypeOptions,
  YesNoOptions,
  generateUSAStateOptions,
} from '../../../../features/Taxes/taxConstants'
import {
  calculateStandardDeduction,
  dateWithoutTimestamp,
  inspectTaxProfileForWarnings,
  isValidDate,
  shouldShowPaystub,
  validateTaxProfileForm,
} from '../../../../features/Taxes/QuarterlyTaxEstimates/service'
import FileUploadModal from '../../../FileUpload/FileUploadModal'
import IncomeByState from './IncomeByState'
import { fetchUserDocuments } from '../../../../features/UserDocuments/userDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../../features/Admin/UserDocumentCategories/userDocumentCategories.slice'
import { parseErrorFromCatch } from '../../../../utils/errorHelpers'
import {
  getUserDocumentById,
  selectAdminTaxReturnDocumentsForTaxYear,
} from '../../../../features/UserDocuments/userDocuments.selector'
import { useReselector, useTimeoutRef } from '../../../../utils/sharedHooks'
import { fetchAdminUsersDocuments } from '../../../../actions/admin/userDocumentsActions'
import { updateUserFinancialProfile } from '../../../../actions/admin/adminAllUsersActions'
import { getUserById } from '../../../../selectors/user.selectors'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../../features/Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../../features/Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import {
  Alert,
  Button,
  DatePicker,
  Dropdown,
  GridRowColumn,
  Input,
  Link,
  Popup,
  Radio,
  Text,
} from '../../../BaseComponents'
import {
  PostOnboardingTaskIdents,
  useIsOnboardingNav,
} from '../../../../features/Onboarding/config'
import { markUserActionItemCompleteIfExists } from '../../../../features/Dashboard/UserActionItems/service'
import { selectUserDocumentCategoryList } from '../../../../features/Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import {
  FederalIncomeTaxEstimateDetails,
  getCurrentFederalDetails,
} from '../../../../features/Admin/Taxes/IncomeTaxDetails/FederalIncomeTaxDetails/federalIncomeTaxDetailsActions'
import { selectCurrentAnnualTaxYear } from '../../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useAnalyticsTrack } from '../../../../features/Amplitude'
import { fetchTaxUserDocumentsIfNeededForUser } from '../../../../features/Taxes/AnnualTaxes/taxUserDocuments.slice'

interface TaxProfileFormProps {
  userId: number
  onSaveSuccess?: () => void
  onCancelClick?: () => void
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & TaxProfileFormProps

export type UpdateStateType = {
  key: string
  value:
    | string
    | null
    | boolean
    | number
    | PercentageIncomePerState[]
    | undefined
}

const ToolTipIcon = () => (
  <Icon className="tooltip" size="large" name="question circle" />
)

const TaxProfileForm = ({
  userId,
  fetchUserDocuments,
  fetchAdminUsersDocuments,
  fetchTaxUserDocumentsIfNeededForUser,
  fetchUserDocumentCategoriesIfNeeded,
  updateUserFinancialProfile,
  onSaveSuccess,
  onCancelClick,
  fetchAllQuarterlyTaxEstimateDetailsIfNeeded,
  getCurrentFederalDetails,
}: Props) => {
  const [saving, setSaving] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [saveError, setSaveError] = useState<string | null>(null)
  const [saveSuccess, setSaveSuccess] = useState<boolean>()
  const [paystubModalOpen, setPaystubModalOpen] = useState(false)
  const [taxReturnModalOpen, setTaxReturnModalOpen] = useState(false)
  const [currentFederalDetails, setCurrentFederalDetails] =
    useState<FederalIncomeTaxEstimateDetails>()
  const isOnboarding = useIsOnboardingNav()

  const user = useReselector(getUserById, userId)
  const track = useAnalyticsTrack()
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const previousTaxYear = (Number(currentTaxYear) - 1).toString()

  const [formState, setFormState] = useState<Partial<FinancialProfile>>({
    ...(user?.financialProfile || {}),
  })

  const todayLocal = dateWithoutTimestamp(null)
  const activeQuarterlyTaxEstimateDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string
  }>({})
  const [warningMessages, setWarningMessages] = useState<{
    [key: string]: string
  }>({})
  const isFormDirty = !isEqual(user?.financialProfile, formState)

  useEffect(() => {
    async function fetch() {
      if (user?.id) {
        await fetchAdminUsersDocuments(user.id)
        fetchTaxUserDocumentsIfNeededForUser(user.id, previousTaxYear)
      }

      await fetchUserDocumentCategoriesIfNeeded()
      setCurrentFederalDetails(await getCurrentFederalDetails())
      setFetching(false)
    }

    fetchAllQuarterlyTaxEstimateDetailsIfNeeded()

    if (user?.id) {
      setFetching(true)
      fetch()
    }
  }, [
    fetchAdminUsersDocuments,
    user?.id,
    setFetching,
    fetchUserDocumentCategoriesIfNeeded,
    fetchUserDocuments,
    fetchAllQuarterlyTaxEstimateDetailsIfNeeded,
    getCurrentFederalDetails,
    fetchTaxUserDocumentsIfNeededForUser,
    previousTaxYear,
  ])

  /*
      UserDocuments - admin GET vs customer GET
      a. Spouse Paystub
      b. Prior Year Tax Return (PYTR)
  */

  const spousePaystub = useReselector(
    getUserDocumentById,
    formState.spousePaystubDocumentId
  )

  const pytrDocuments = useReselector(
    selectAdminTaxReturnDocumentsForTaxYear,
    user?.financialProfile?.id ?? -1,
    previousTaxYear
  )
  const priorYearTaxReturns = pytrDocuments.length ? pytrDocuments : null

  const usaStateOptions = useMemo(() => {
    const used = []
    if (formState.relocatedPreviousState) {
      used.push(formState.relocatedPreviousState)
    }
    if (formState.homeState) {
      used.push(formState.homeState)
    }
    const all = generateUSAStateOptions()

    return getUnusedOptions({ used, all })
  }, [formState.homeState, formState.relocatedPreviousState])

  const navigate = useNavigate()
  const navigateAway = useCallback(() => {
    navigate(isOnboarding ? '/' : TAX_CENTER_URL_PATHNAME)
  }, [isOnboarding, navigate])

  const redirectToTaxCenterTimeout = useTimeoutRef()
  const redirectToTaxCenter = useCallback(() => {
    redirectToTaxCenterTimeout.current = setTimeout(() => {
      navigateAway()
    }, 3000)
  }, [redirectToTaxCenterTimeout, navigateAway])

  const onSaveSuccessAfterTimeout = useCallback(() => {
    if (onSaveSuccess) {
      redirectToTaxCenterTimeout.current = setTimeout(() => {
        onSaveSuccess()
      }, 3000)
    }
  }, [onSaveSuccess, redirectToTaxCenterTimeout])

  const checkInputsForWarnings = useCallback(
    (state: Partial<FinancialProfile>) => {
      setWarningMessages(
        inspectTaxProfileForWarnings(state, activeQuarterlyTaxEstimateDetails)
      )
    },
    [setWarningMessages, activeQuarterlyTaxEstimateDetails]
  )

  useEffect(() => {
    checkInputsForWarnings(formState)
  }, [formState, checkInputsForWarnings])

  // Updates the form's state by invoking setFormState using key and value string params
  // Depending upon the key being updated, we may manually update "related" field values
  const updateFormState = useCallback(
    ({ key, value }: UpdateStateType) => {
      let newValue = value
      const clone = { ...formState }

      if (inOperator(key, clone)) {
        // Force empty strings to be stored as null values
        if (typeof newValue === 'string' && newValue?.trim() === '') {
          newValue = null
        }

        clone[key] = newValue

        // FilingStatus changed
        if (key === KEYS.filingStatus) {
          // If a standard deduction has been provided, we need to recalculate its value
          if (
            !clone[KEYS.itemizedDeduction] &&
            clone[KEYS.deductionInCents] &&
            newValue
          ) {
            clone[KEYS.deductionInCents] = calculateStandardDeduction(
              newValue.toString(),
              currentFederalDetails
            )
          }

          // Reset spouse info
          clone[KEYS.spouseFederalWithholdingInCents] = null
          clone[KEYS.spouseStateWithholdingInCents] = null
          clone[KEYS.spouseFirstName] = null
          clone[KEYS.spouseLastName] = null
          clone[KEYS.spouseWIncomeInCents] = null
          clone[KEYS.spousePaystubDocumentId] = null

          // Reset numDependents if "Single" status
          if (newValue === FILING_STATUSES.single) {
            clone[KEYS.numberOfDependents] = null
          }
        }

        // Itemized is checked (ON)
        if (key === KEYS.itemizedDeduction && newValue === true) {
          // Set the deduction value to 0
          clone[KEYS.deductionInCents] = 0
        }

        // Standard deduction is checked (ON)
        if (key === KEYS.itemizedDeduction && newValue === false) {
          // Calculate the deduction amount if a filing status has been selected
          let deductionInCents: number | null = 0
          if (formState.filingStatus) {
            deductionInCents = calculateStandardDeduction(
              formState.filingStatus,
              currentFederalDetails
            )
          }

          clone[KEYS.deductionInCents] = deductionInCents
        }

        // relocatedThisQuarter changed
        if (key === KEYS.relocatedThisQuarter) {
          // Reset related fields
          clone[KEYS.relocatedPreviousState] = null
          clone[KEYS.relocatedPreviousStateAt] = null

          // Clear the home state when relocated dropdown is "yes"
          if (newValue === true) {
            clone[KEYS.homeState] = null
          }
        }

        // sawMultistateClientsThisQuarter changed
        if (key === KEYS.sawMultistateClientsThisQuarter) {
          // Clear the % income per state field
          clone[KEYS.percentageIncomePerState] = null
        }

        setFormState(clone)
      }
    },
    [formState, currentFederalDetails]
  )

  const validateForm = useCallback(
    (state: Partial<FinancialProfile>) => {
      const erroneousFields = validateTaxProfileForm(state)
      setValidationErrors(erroneousFields)
      return erroneousFields
    },
    [setValidationErrors]
  )

  const onSaveClicked = useCallback(async () => {
    setSaving(true)

    // Perform validation
    const erroneousFields = validateForm(formState)

    if (Object.values(erroneousFields).length > 0 || !formState.id) {
      setSaving(false)
      return
    }

    formState.updatedAt = todayLocal.format(DATE_FORMATS.TIMESTAMP)
    formState.taxProfileVersion = TAX_PROFILE_VERSION

    // Perform save
    const result = await updateUserFinancialProfile(formState.id, formState)

    setSaving(false)

    if (result?.id) {
      setSaveError(null)
      setSaveSuccess(true)

      markUserActionItemCompleteIfExists(
        PostOnboardingTaskIdents.UPDATE_TAX_PROFILE,
        (event, properties) => track(event, properties)
      )
      if (onSaveSuccess) {
        onSaveSuccessAfterTimeout()
      } else {
        redirectToTaxCenter()
      }
    } else {
      const error = parseErrorFromCatch(result)
      setSaveSuccess(false)
      setSaveError(error)
    }
  }, [
    setSaving,
    validateForm,
    todayLocal,
    formState,
    setSaveError,
    setSaveSuccess,
    redirectToTaxCenter,
    onSaveSuccess,
    onSaveSuccessAfterTimeout,
    updateUserFinancialProfile,
    track,
  ])

  const numValidationErrors = Object.values(validationErrors).length

  const activeTaxYear =
    activeQuarterlyTaxEstimateDetails?.taxYear ||
    new Date().getFullYear.toString()

  if (!user) {
    return null
  }

  return (
    <Form warning={Object.values(warningMessages).length > 0} loading={saving}>
      <Grid>
        <GridRowColumn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text as="h3">
              {copy.fieldGroups.filingStatus.title} <span>*</span>
            </Text>
            <Popup
              hoverable
              content={copy.fieldGroups.filingStatus.tooltip()}
              trigger={<ToolTipIcon />}
            />
          </div>
        </GridRowColumn>
        <GridRowColumn>
          <Radio
            label={copy.fieldGroups.filingStatus.single}
            name={KEYS.filingStatus}
            checked={formState.filingStatus === FILING_STATUSES.single}
            onChange={() =>
              updateFormState({
                key: KEYS.filingStatus,
                value: FILING_STATUSES.single,
              })
            }
          />
        </GridRowColumn>
        <GridRowColumn>
          <Radio
            label={copy.fieldGroups.filingStatus.marriedFilingJointly}
            name={KEYS.filingStatus}
            checked={
              formState.filingStatus === FILING_STATUSES.married_filing_jointly
            }
            onChange={() =>
              updateFormState({
                key: KEYS.filingStatus,
                value: FILING_STATUSES.married_filing_jointly,
              })
            }
          />
        </GridRowColumn>
        <GridRowColumn>
          <Radio
            label={copy.fieldGroups.filingStatus.marriedFilingSeparately}
            name={KEYS.filingStatus}
            checked={
              formState.filingStatus ===
              FILING_STATUSES.married_filing_separately
            }
            onChange={() =>
              updateFormState({
                key: KEYS.filingStatus,
                value: FILING_STATUSES.married_filing_separately,
              })
            }
          />
        </GridRowColumn>
        <GridRowColumn>
          <Radio
            label={copy.fieldGroups.filingStatus.headOfHousehold}
            name={KEYS.filingStatus}
            checked={
              formState.filingStatus === FILING_STATUSES.head_of_household
            }
            onChange={() =>
              updateFormState({
                key: KEYS.filingStatus,
                value: FILING_STATUSES.head_of_household,
              })
            }
          />
        </GridRowColumn>
        <GridRowColumn>
          <Radio
            label={copy.fieldGroups.filingStatus.qualifyingWidow}
            name={KEYS.filingStatus}
            checked={
              formState.filingStatus === FILING_STATUSES.qualifying_widow
            }
            onChange={() =>
              updateFormState({
                key: KEYS.filingStatus,
                value: FILING_STATUSES.qualifying_widow,
              })
            }
          />
        </GridRowColumn>

        {formState.filingStatus !== FILING_STATUSES.single && (
          <>
            <GridRowColumn>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text as="h3">
                  {copy.fieldGroups.numberOfDependents.title}
                  <span>*</span>
                </Text>
              </div>
            </GridRowColumn>
            <GridRowColumn>
              <Input
                componentType="number"
                required
                placeholder={copy.fieldGroups.numberOfDependents.placeholder}
                error={validationErrors[KEYS.numberOfDependents]}
                value={formState.numberOfDependents?.toString() || ''}
                onChange={(value) =>
                  updateFormState({ key: KEYS.numberOfDependents, value })
                }
                prefix=""
                decimalScale={0}
                allowNegative={false}
              />
            </GridRowColumn>
          </>
        )}

        <GridRowColumn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text as="h3">
              {copy.fieldGroups.taxEntityType.title} <span>*</span>
            </Text>
            <Popup
              hoverable
              content={copy.fieldGroups.taxEntityType.tooltip()}
              trigger={<ToolTipIcon />}
            />
          </div>
        </GridRowColumn>

        <GridRowColumn width={6}>
          <Dropdown
            fullWidth
            error={validationErrors[KEYS.taxEntityType]}
            options={TaxEntityTypeOptions}
            placeholder={copy.fieldGroups.taxEntityType.placeholder}
            value={formState.taxEntityType || undefined}
            onChange={(value) =>
              updateFormState({ key: KEYS.taxEntityType, value })
            }
          />
        </GridRowColumn>

        <Divider />

        <GridRowColumn>
          <Text as="h2">Income and withholdings</Text>
        </GridRowColumn>

        <GridRowColumn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text as="h3">
              {copy.fieldGroups.w2IncomeInCents.title(activeTaxYear)}{' '}
              <span>*</span>
            </Text>
            <Popup
              hoverable
              content={copy.fieldGroups.w2IncomeInCents.tooltip}
              trigger={<ToolTipIcon />}
            />
          </div>
        </GridRowColumn>
        <GridRowColumn>
          <Input
            required
            componentType="currency"
            error={validationErrors[KEYS.wIncomeInCents]}
            placeholder={copy.zeroDollarIfNonePlaceholder}
            value={centsToDollars(formState.wIncomeInCents).toString()}
            onChange={(value) =>
              updateFormState({
                key: KEYS.wIncomeInCents,
                value: dollarsToCents(value),
              })
            }
          />
        </GridRowColumn>

        <GridRowColumn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text as="h3">
              {copy.fieldGroups.otherIndividualIncomeInCents.title(
                activeTaxYear
              )}{' '}
              <span>*</span>
            </Text>
            <Popup
              hoverable
              content={copy.fieldGroups.otherIndividualIncomeInCents.tooltip}
              trigger={<ToolTipIcon />}
            />
          </div>
        </GridRowColumn>
        <GridRowColumn>
          <Input
            required
            componentType="currency"
            error={validationErrors[KEYS.wIncomeInCents]}
            placeholder={copy.zeroDollarIfNonePlaceholder}
            value={centsToDollars(
              formState.otherIndividualIncomeInCents
            ).toString()}
            onChange={(value) =>
              updateFormState({
                key: KEYS.otherIndividualIncomeInCents,
                value: dollarsToCents(value),
              })
            }
          />
        </GridRowColumn>

        <GridRowColumn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text as="h3">
              {copy.fieldGroups.federalWithholding.title(activeTaxYear)}{' '}
              <span>*</span>
            </Text>
            <Popup
              hoverable
              content={copy.fieldGroups.federalWithholding.tooltip()}
              trigger={<ToolTipIcon />}
            />
          </div>
        </GridRowColumn>
        <GridRowColumn>
          <Input
            required
            componentType="currency"
            error={validationErrors[KEYS.federalWithholdingInCents]}
            placeholder={copy.zeroDollarIfNonePlaceholder}
            value={centsToDollars(
              formState.federalWithholdingInCents
            ).toString()}
            onChange={(value) => {
              updateFormState({
                key: KEYS.federalWithholdingInCents,
                value: dollarsToCents(value),
              })
            }}
          />
        </GridRowColumn>

        <GridRowColumn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text as="h3">
              {copy.fieldGroups.stateWithholdingInCents.title(activeTaxYear)}{' '}
              <span>*</span>
            </Text>
            <Popup
              hoverable
              content={copy.fieldGroups.stateWithholdingInCents.tooltip()}
              trigger={<ToolTipIcon />}
            />
          </div>
        </GridRowColumn>
        <GridRowColumn>
          <Input
            required
            componentType="currency"
            error={validationErrors[KEYS.stateWithholdingInCents]}
            placeholder={copy.zeroDollarIfNonePlaceholder}
            value={centsToDollars(formState.stateWithholdingInCents).toString()}
            onChange={(value) => {
              updateFormState({
                key: KEYS.stateWithholdingInCents,
                value: dollarsToCents(value),
              })
            }}
          />
        </GridRowColumn>
        <Divider />

        <GridRowColumn>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text as="h3">{copy.fieldGroups.deductionInCents.title}</Text>{' '}
            <span>*</span>
            <Popup
              hoverable
              content={copy.fieldGroups.deductionInCents.tooltip()}
              trigger={<ToolTipIcon />}
            />
          </div>
        </GridRowColumn>

        <GridRowColumn>
          <Radio
            label={copy.fieldGroups.deductionInCents.standard}
            checked={Boolean(
              formState.filingStatus &&
                formState.deductionInCents &&
                !formState.itemizedDeduction
            )}
            onChange={() => {
              updateFormState({ key: KEYS.itemizedDeduction, value: false })
            }}
            error={validationErrors[KEYS.deductionInCents]}
          />
        </GridRowColumn>

        <GridRowColumn>
          <Radio
            label={copy.fieldGroups.deductionInCents.itemized}
            checked={Boolean(
              formState.filingStatus && formState.itemizedDeduction
            )}
            onChange={() => {
              updateFormState({ key: KEYS.itemizedDeduction, value: true })
            }}
          />
        </GridRowColumn>

        {formState.itemizedDeduction && (
          <GridRowColumn>
            <Input
              required
              componentType="currency"
              placeholder={
                copy.fieldGroups.deductionInCents.itemizedPlaceholder
              }
              value={centsToDollars(formState.deductionInCents).toString()}
              onChange={(value) =>
                updateFormState({
                  key: KEYS.deductionInCents,
                  value: dollarsToCents(value),
                })
              }
            />
          </GridRowColumn>
        )}
        <Divider />

        {formState.filingStatus === FILING_STATUSES.married_filing_jointly && (
          <>
            <GridRowColumn>
              <Text as="h2">Spouse</Text>
            </GridRowColumn>

            <Grid.Row columns={2}>
              <Grid.Column>
                <Grid>
                  <GridRowColumn>
                    <Text as="h3" style={{ paddingBottom: '16px' }}>
                      {copy.fieldGroups.spouse.firstName} <span>*</span>
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <Input
                      required
                      type="text"
                      placeholder={copy.fieldGroups.spouse.placeholder}
                      error={validationErrors[KEYS.spouseFirstName]}
                      onChange={(value) =>
                        updateFormState({ key: KEYS.spouseFirstName, value })
                      }
                      value={formState.spouseFirstName || ''}
                    />
                  </GridRowColumn>
                </Grid>
              </Grid.Column>

              <Grid.Column>
                <Grid>
                  <GridRowColumn>
                    <Text as="h3" style={{ paddingBottom: '16px' }}>
                      {copy.fieldGroups.spouse.lastName} <span>*</span>
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <Input
                      required
                      type="text"
                      placeholder={copy.fieldGroups.spouse.placeholder}
                      error={validationErrors[KEYS.spouseLastName]}
                      onChange={(value) =>
                        updateFormState({ key: KEYS.spouseLastName, value })
                      }
                      value={formState.spouseLastName || ''}
                    />
                  </GridRowColumn>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <GridRowColumn>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Text as="h3">
                  {copy.fieldGroups.spouse.ytdW2(activeTaxYear)} <span>*</span>
                </Text>
                <Popup
                  hoverable
                  content={copy.fieldGroups.spouse.ytdW2Tooltip}
                  trigger={<ToolTipIcon />}
                />
              </div>
            </GridRowColumn>
            <GridRowColumn>
              <Input
                required
                componentType="currency"
                error={validationErrors[KEYS.spouseWIncomeInCents]}
                placeholder={copy.zeroDollarIfNonePlaceholder}
                value={centsToDollars(
                  formState.spouseWIncomeInCents
                ).toString()}
                onChange={(value) =>
                  updateFormState({
                    key: KEYS.spouseWIncomeInCents,
                    value: dollarsToCents(value),
                  })
                }
              />
            </GridRowColumn>
            <GridRowColumn>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Text as="h3">
                  {copy.fieldGroups.spouse.indivIncome(activeTaxYear)}{' '}
                  <span>*</span>
                </Text>
                <Popup
                  hoverable
                  content={copy.fieldGroups.spouse.indivIncomeTooltip}
                  trigger={<ToolTipIcon />}
                />
              </div>
            </GridRowColumn>
            <GridRowColumn>
              <Input
                required
                componentType="currency"
                error={validationErrors[KEYS.spouseIndividualIncomeInCents]}
                placeholder={copy.zeroDollarIfNonePlaceholder}
                value={centsToDollars(
                  formState.spouseIndividualIncomeInCents
                ).toString()}
                onChange={(value) =>
                  updateFormState({
                    key: KEYS.spouseIndividualIncomeInCents,
                    value: dollarsToCents(value),
                  })
                }
              />
            </GridRowColumn>
            <GridRowColumn>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text as="h3">
                  {copy.fieldGroups.spouse.federalWithholdingInCents(
                    activeTaxYear
                  )}{' '}
                  <span>*</span>
                </Text>
                <Popup
                  hoverable
                  content={copy.fieldGroups.spouse.withholdingTooltip()}
                  trigger={<ToolTipIcon />}
                />
              </div>
            </GridRowColumn>
            <GridRowColumn>
              <Input
                required
                componentType="currency"
                error={validationErrors[KEYS.spouseFederalWithholdingInCents]}
                placeholder={copy.zeroDollarIfNonePlaceholder}
                value={centsToDollars(
                  formState.spouseFederalWithholdingInCents
                ).toString()}
                onChange={(value) =>
                  updateFormState({
                    key: KEYS.spouseFederalWithholdingInCents,
                    value: dollarsToCents(value),
                  })
                }
              />
            </GridRowColumn>

            <GridRowColumn>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text as="h3">
                  {copy.fieldGroups.spouse.stateWithholdingInCents(
                    activeTaxYear
                  )}{' '}
                  <span>*</span>
                </Text>
                <Popup
                  hoverable
                  content={copy.fieldGroups.spouse.withholdingTooltip()}
                  trigger={<ToolTipIcon />}
                />
              </div>
            </GridRowColumn>
            <GridRowColumn>
              <Input
                required
                componentType="currency"
                error={validationErrors[KEYS.spouseStateWithholdingInCents]}
                placeholder={copy.zeroDollarIfNonePlaceholder}
                value={centsToDollars(
                  formState.spouseStateWithholdingInCents
                ).toString()}
                onChange={(value) =>
                  updateFormState({
                    key: KEYS.spouseStateWithholdingInCents,
                    value: dollarsToCents(value),
                  })
                }
              />
            </GridRowColumn>

            {shouldShowPaystub(formState.spouseWIncomeInCents) && (
              <>
                <GridRowColumn>
                  <Text as="h3">{copy.fieldGroups.spouse.paystub}</Text>
                </GridRowColumn>
                <GridRowColumn>
                  <div>
                    <Button onClick={() => setPaystubModalOpen(true)}>
                      {spousePaystub?.id
                        ? copy.fieldGroups.spouse.uploadNewerPaystubButtonText
                        : copy.fieldGroups.spouse.uploadPaystubButtonText}
                    </Button>
                  </div>
                </GridRowColumn>
                {spousePaystub?.id && (
                  <GridRowColumn>
                    {spousePaystub.signedUrl ? (
                      <Link href={spousePaystub.signedUrl} newPage>
                        {spousePaystub.fileDescription ||
                          copy.fieldGroups.spouse.paystubFilenameFallback}
                      </Link>
                    ) : (
                      <Link
                        href={
                          formState.spousePaystub?.signedUrl ||
                          USER_DOCUMENTS_URL_PATHNAME
                        }
                        newPage
                      >
                        {spousePaystub.fileDescription ||
                          copy.fieldGroups.spouse.paystubFilenameFallback}
                      </Link>
                    )}
                  </GridRowColumn>
                )}
              </>
            )}
            {paystubModalOpen && (
              <FileUploadModal
                user={user}
                open={paystubModalOpen}
                close={() => setPaystubModalOpen(false)}
                userFacing={false}
                setUploadedFile={({ id }) => {
                  updateFormState({
                    key: KEYS.spousePaystubDocumentId,
                    value: id,
                  })
                }}
                documentType={UploadDocumentType.OTHER}
              />
            )}
            <Divider />
          </>
        )}

        <GridRowColumn>
          <Text as="h2">{copy.partTwoHeader}</Text>
        </GridRowColumn>

        <GridRowColumn>
          <Text as="h3">
            {copy.fieldGroups.relocation.relocatedThisQuarter} <span>*</span>
          </Text>
        </GridRowColumn>

        <GridRowColumn>
          <Dropdown
            required
            options={YesNoOptions}
            clearable
            error={validationErrors[KEYS.relocatedThisQuarter]}
            value={
              isNil(formState.relocatedThisQuarter)
                ? undefined
                : formState.relocatedThisQuarter
            }
            placeholder="Choose an option"
            onChange={(value) =>
              updateFormState({ key: KEYS.relocatedThisQuarter, value })
            }
          />
        </GridRowColumn>

        {!formState.relocatedThisQuarter && (
          <>
            <GridRowColumn>
              <Text as="h3">
                {copy.fieldGroups.relocation.verifyHomeState}
                <span>*</span>
              </Text>
            </GridRowColumn>

            <GridRowColumn>
              <Dropdown
                required
                options={usaStateOptions}
                clearable
                search
                error={validationErrors[KEYS.homeState]}
                value={formState.homeState || undefined}
                placeholder={copy.fieldGroups.relocation.dropdownPlaceholder}
                onChange={(value) =>
                  updateFormState({ key: KEYS.homeState, value })
                }
              />
            </GridRowColumn>
          </>
        )}

        {formState.relocatedThisQuarter && (
          <>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Grid>
                  <GridRowColumn>
                    <Text as="h3" style={{ paddingBottom: '16px' }}>
                      {copy.fieldGroups.relocation.relocatedPreviousState}
                      <span>*</span>
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <Dropdown
                      required
                      options={usaStateOptions}
                      search
                      error={validationErrors[KEYS.relocatedPreviousState]}
                      value={formState.relocatedPreviousState || undefined}
                      placeholder={
                        copy.fieldGroups.relocation.dropdownPlaceholder
                      }
                      clearable
                      onChange={(value) =>
                        updateFormState({
                          key: KEYS.relocatedPreviousState,
                          value,
                        })
                      }
                    />
                  </GridRowColumn>
                </Grid>
              </Grid.Column>
              <Grid.Column>
                <Grid>
                  <GridRowColumn>
                    <Text as="h3" style={{ paddingBottom: '16px' }}>
                      {copy.fieldGroups.relocation.relocatedPreviousStateAt}
                      <span>*</span>
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <DatePicker
                      required
                      clearable
                      placeholder="Choose a date"
                      value={formState.relocatedPreviousStateAt || ''}
                      error={validationErrors[KEYS.relocatedPreviousStateAt]}
                      onChange={(date) =>
                        updateFormState({
                          key: KEYS.relocatedPreviousStateAt,
                          value: isValidDate(date) ? date : null,
                        })
                      }
                    />
                  </GridRowColumn>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <GridRowColumn>
              <Text as="h3">
                {copy.fieldGroups.relocation.verifyNewHomeState}
                <span>*</span>
              </Text>
            </GridRowColumn>

            <GridRowColumn>
              <Dropdown
                required
                options={usaStateOptions}
                clearable
                search
                error={validationErrors[KEYS.homeState]}
                value={formState.homeState || undefined}
                placeholder={copy.fieldGroups.relocation.dropdownPlaceholder}
                onChange={(value) =>
                  updateFormState({ key: KEYS.homeState, value })
                }
              />
            </GridRowColumn>
          </>
        )}

        <GridRowColumn>
          <Text as="h3">
            {copy.fieldGroups.multistate.sawMultistateClientsThisQuarter}
          </Text>
        </GridRowColumn>

        <GridRowColumn>
          <Dropdown
            required
            options={YesNoOptions}
            clearable
            error={validationErrors[KEYS.sawMultistateClientsThisQuarter]}
            value={
              isNil(formState.sawMultistateClientsThisQuarter)
                ? undefined
                : formState.sawMultistateClientsThisQuarter
            }
            placeholder="Choose an option"
            onChange={(value) =>
              updateFormState({
                key: KEYS.sawMultistateClientsThisQuarter,
                value,
              })
            }
          />
        </GridRowColumn>

        {formState.sawMultistateClientsThisQuarter && (
          <>
            <GridRowColumn>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text as="h3">
                  {copy.fieldGroups.multistate.incomePerStateSubtitle()}
                </Text>
                <Popup
                  hoverable
                  content={copy.fieldGroups.multistate.incomePerStateTitle}
                  trigger={<ToolTipIcon />}
                />
              </div>
            </GridRowColumn>

            <IncomeByState
              rowData={formState.percentageIncomePerState || [{}]}
              updateState={updateFormState}
              disabled={false}
              error={validationErrors[KEYS.percentageIncomePerState]}
            />
            <Grid.Row />
          </>
        )}
        <Divider />

        <GridRowColumn>
          <Text as="h2">{copy.partThreeHeader}</Text>
        </GridRowColumn>

        {fetching && (
          <div style={{ position: 'relative' }}>
            <Loader active />
          </div>
        )}

        {!fetching && !priorYearTaxReturns && (
          <GridRowColumn>
            <Button onClick={() => setTaxReturnModalOpen(true)}>
              {copy.fieldGroups.priorYearTaxReturn.buttonText}
            </Button>
          </GridRowColumn>
        )}
        {!fetching && (
          <FileUploadModal
            user={user}
            open={taxReturnModalOpen}
            documentType={UploadDocumentType.TAX}
            categoryId={null}
            userFacing={false}
            close={() => setTaxReturnModalOpen(false)}
          />
        )}

        {priorYearTaxReturns?.length && (
          <>
            <GridRowColumn>
              <Text as="h2">
                {copy.fieldGroups.priorYearTaxReturn.taxReturnContent}
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              {priorYearTaxReturns.map((priorYearTaxReturn) => (
                <div key={`TaxProfileForm__${priorYearTaxReturn.awsFileName}`}>
                  <Link newPage href={priorYearTaxReturn.signedUrl || '#'}>
                    {priorYearTaxReturn.fileDescription ||
                      priorYearTaxReturn.awsFileName}
                  </Link>
                  <br />
                </div>
              ))}
            </GridRowColumn>
          </>
        )}
        <div style={{ padding: '70px 0 40px 0' }}>
          {numValidationErrors > 0 && (
            <Alert type="error" title={copy.validationErrorHeader}>
              {copy.validationErrorContent(numValidationErrors)}
            </Alert>
          )}
          {saveError && (
            <Alert type="error" title={copy.saveErrorHeader}>
              {copy.saveErrorContent(saveError)}
            </Alert>
          )}
          {saveSuccess && (
            <Alert type="announcement" title={copy.saveSuccessHeader}>
              Closing modal momentarily...
            </Alert>
          )}
        </div>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          disabled={saving || saveSuccess}
          onClick={() => onCancelClick?.()}
        >
          {copy.cancelButtonText}
        </Button>
        <Button
          disabled={saving || saveSuccess || !isFormDirty}
          loading={saving}
          floated="right"
          onClick={onSaveClicked}
        >
          {copy.saveButtonText}
        </Button>
      </div>
    </Form>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  allUserDocumentCategories: selectUserDocumentCategoryList(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserDocumentCategoriesIfNeeded: wrapDispatch(
    dispatch,
    fetchUserDocumentCategoriesIfNeeded
  ),
  fetchUserDocuments: wrapDispatch(dispatch, fetchUserDocuments),
  fetchAdminUsersDocuments: wrapDispatch(dispatch, fetchAdminUsersDocuments),
  updateUserFinancialProfile: wrapDispatch(
    dispatch,
    updateUserFinancialProfile
  ),
  fetchTaxUserDocumentsIfNeededForUser: wrapDispatch(
    dispatch,
    fetchTaxUserDocumentsIfNeededForUser
  ),
  fetchAllQuarterlyTaxEstimateDetailsIfNeeded: wrapDispatch(
    dispatch,
    fetchAllQuarterlyTaxEstimateDetailsIfNeeded
  ),
  getCurrentFederalDetails: wrapDispatch(dispatch, getCurrentFederalDetails),
})

export default connect<
  StateProps,
  DispatchProps,
  Record<string, unknown>,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(TaxProfileForm)
