import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  GridRowColumn,
  Icon,
  IconButton,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectHasTqStartedForCurrentYear } from '../annualTaxFilings.selector'

const getClosedTaxFyleStorageKey = (locationPath: string) =>
  `closed-taxfyle-intro-${locationPath}`

const TaxFyleIntroMiniCard = () => {
  const location = useLocation()
  const hasTQStarted = useReselector(selectHasTqStartedForCurrentYear)

  const [closed, setClosed] = useState(
    localStorage.getItem(getClosedTaxFyleStorageKey(location.pathname)) ===
      'true'
  )

  const onClose = useCallback(() => {
    localStorage.setItem(getClosedTaxFyleStorageKey(location.pathname), 'true')
    setClosed(true)
  }, [location.pathname])

  if (
    closed ||
    !hasTQStarted ||
    process.env.VITE_SHOW_TAX_FYLE_INTRO !== 'true'
  ) {
    return null
  }

  return (
    <GridRowColumn>
      <Card type="subsection">
        <Grid>
          <Grid.Row>
            <Grid.Column width={1} verticalAlign="middle">
              <Icon icon={solid('megaphone')} size="xl" />
            </Grid.Column>
            <Grid.Column width={15}>
              <IconButton
                icon={regular('close')}
                style={{ position: 'absolute', top: 0, right: 20 }}
                color="darkGray"
                size="lg"
                onClick={onClose}
              />
              <Text as="h3">Introducing Taxfyle</Text>
              <br />
              <Text as="bodyLg">
                This year, we’re partnering with{' '}
                <Link
                  href="https://support.joinheard.com/hc/en-us/articles/19788518004887-Taxfyle-FAQs"
                  newPage
                  as="bodyLg"
                >
                  Taxfyle
                </Link>{' '}
                to provide you with quick, expert tax preparation. Once your tax
                preparer starts your return, you’ll communicate with them in a
                new messaging section that will appear here and in the Annual
                Tax Center.{' '}
                <Link
                  href="https://support.joinheard.com/hc/en-us/articles/19788518004887-Taxfyle-FAQs"
                  newPage
                  as="bodyLg"
                >
                  Learn more
                </Link>
              </Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </GridRowColumn>
  )
}

export default TaxFyleIntroMiniCard
