import { useStringFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  TaxfyleCall,
  TaxfyleJob,
  TaxfyleJobStatus,
} from '../AnnualTaxes/annualTaxFilingForms.slice'
import {
  FETCH_ACTIVE_TAX_CONSULTATIONS,
  FETCH_TAX_CONSULTATION_URL,
  JobServices,
  PilotFeatureFlagValues,
  fetchActiveTaxConsultationsIfNeeded,
  fetchTaxConsultationAdvisor,
  fetchTaxConsultationUrl,
} from './taxConsultation.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Button,
  Card,
  GridRowColumn,
  Label,
  Link,
  Text,
} from '../../../components/BaseComponents'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid } from 'semantic-ui-react'
import {
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../reducers/fetch'
import { DateTime } from 'luxon'

const ConsultationPortal = ({
  consultation,
  call,
}: {
  consultation: TaxfyleJob
  call?: TaxfyleCall
}) => {
  const jobLink = useFetchResponse(fetchTaxConsultationUrl, consultation.jobId)
  const advisorName = useFetchResponse(
    fetchTaxConsultationAdvisor,
    consultation.jobId
  )
  const loading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_CONSULTATION_URL,
  ])
  const errors = useReselector(selectErrorsForKeys, [
    FETCH_ACTIVE_TAX_CONSULTATIONS,
    FETCH_TAX_CONSULTATION_URL,
  ])
  const flag = useStringFlagValue(
    FEATURE_FLAG_KEYS.taxAdvisoryPilot,
    PilotFeatureFlagValues.noPilot
  )
  const displayTime = useMemo(() => {
    if (call?.scheduledAt) {
      const callEndsAt = consultation.services.includes(
        JobServices.consultation60Min
      )
        ? DateTime.fromISO(call.scheduledAt).plus({ minutes: 60 })
        : DateTime.fromISO(call.scheduledAt).plus({ minutes: 30 })
      // format 2:30-3:00PM PT
      return `${DateTime.fromISO(call.scheduledAt).toLocaleString(DateTime.TIME_SIMPLE)}-${callEndsAt.toLocaleString(DateTime.TIME_SIMPLE)}`
    }
    return ''
  }, [call?.scheduledAt, consultation.services])

  const JobPortal = ({ children }: { children: ReactNode }) => (
    <Link href={jobLink} style={{ opacity: 1 }}>
      <Button loading={loading}>{children}</Button>
    </Link>
  )

  if (
    flag === PilotFeatureFlagValues.userScheduled &&
    consultation.jobStatus === TaxfyleJobStatus.infoGathering
  ) {
    return (
      <Card backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <Label
              color="orange"
              icon={regular('thumbtack')}
              style={{ marginBottom: 32 }}
            >
              Needs Action
            </Label>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">Schedule tax advice consult</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Provide your schedule availability to speak to your tax advisor.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <JobPortal>Schedule consult</JobPortal>
          </GridRowColumn>
          {errors.length > 0 && (
            <GridRowColumn>
              <Text color="red">Error loading consultation.</Text>
            </GridRowColumn>
          )}
        </Grid>
      </Card>
    )
  }

  if (
    flag === PilotFeatureFlagValues.userScheduled &&
    consultation.jobStatus === TaxfyleJobStatus.unclaimed
  ) {
    return (
      <Card backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <Label
              color="neutral"
              icon={regular('thumbs-up')}
              style={{ marginBottom: 32 }}
            >
              No Action Needed
            </Label>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">We&apos;re matching you to a tax advisor</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Thanks for providing your availability. Once a tax advisor
              confirms your appointment, your call details will be updated here.
              In the meantime, feel free to upload additional documents you may
              want to review on your call by clicking the button below.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <JobPortal>Update Details</JobPortal>
          </GridRowColumn>
          {errors.length > 0 && (
            <GridRowColumn>
              <Text color="red">Error loading consultation.</Text>
            </GridRowColumn>
          )}
        </Grid>
      </Card>
    )
  }

  if (call?.scheduledAt) {
    return (
      <Card backgroundColor="stone40">
        <Grid>
          <GridRowColumn>
            <Text as="h2">Tax consult scheduled</Text>
          </GridRowColumn>
          <GridRowColumn>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text as="eyebrow">Date</Text>
              <Text as="bodyLg">
                {DateTime.fromISO(call.scheduledAt).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )}
              </Text>
            </div>
          </GridRowColumn>
          <GridRowColumn>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text as="eyebrow">Time</Text>
              <Text as="bodyLg">{displayTime}</Text>
            </div>
          </GridRowColumn>
          <GridRowColumn>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text as="eyebrow">Tax Advisor</Text>
              <Text as="bodyLg">{advisorName}</Text>
            </div>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Your tax advisor will call you at your meeting time. In the
              meantime, you can send additional documents, cancel*, or
              reschedule by using the “Send message” button below.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <JobPortal>Send message</JobPortal>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodySm">
              *You must provide 24 hour notice to cancel or reschedule, or you
              may be charged a fee.{' '}
            </Text>
          </GridRowColumn>
        </Grid>
      </Card>
    )
  }

  return null
}

export const TaxAdvisoryPilot = () => {
  const flag = useStringFlagValue(
    FEATURE_FLAG_KEYS.taxAdvisoryPilot,
    PilotFeatureFlagValues.noPilot
  )
  const [consultations, setConsultations] = useState<TaxfyleJob[]>([])
  const [scheduledCalls, setScheduledCalls] = useState<TaxfyleCall[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    const load = async () => {
      const res = await dispatch(fetchActiveTaxConsultationsIfNeeded())
      if (res) {
        setConsultations(res.taxfyleJobs)
        setScheduledCalls(res.taxfyleCalls)
      }
    }
    if (flag !== PilotFeatureFlagValues.noPilot) {
      load()
    }
  })

  if (flag === PilotFeatureFlagValues.noPilot) {
    return null
  }

  return (
    <>
      {consultations.map((consultation) => (
        <ConsultationPortal
          key={consultation.jobId}
          consultation={consultation}
          call={scheduledCalls.find(
            (sc) => sc.taxfyleJobId === consultation.jobId
          )}
        />
      ))}
    </>
  )
}
