import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  Alert,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  makeNumberSchema,
  makeReqBoolSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getFinancialProfile,
  selectIsCurrentUserScorp,
} from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
} from './helpers'
import { dollarsToCents } from '../../../../utils/currencyHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from './TaxProfileAccordions'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../../Amplitude'

const Ten99Income = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const fp = useReselector(getFinancialProfile)
  const track = useAnalyticsTrack()
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToPreviousStep, goToNextStep } = useTaxProfileSteps(
    TAX_PROFILE_STEP.ten99Income
  )

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      hasOtherIncome: formFlowAnswers.otherIndividualIncome ? true : undefined,
      otherIndividualIncome: formFlowAnswers.otherIndividualIncome,
    },
    onSubmit: async ({ hasOtherIncome, otherIndividualIncome }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          otherIndividualIncomeInCents: hasOtherIncome
            ? dollarsToCents(otherIndividualIncome)
            : null,
        })
      )

      if (res) {
        setFormFlowAnswers({
          ...formFlowAnswers,
          otherIndividualIncome,
        })
        track('1099 income submitted', {
          has_1099_income: hasOtherIncome ?? false,
          ten99_income_in_cents: hasOtherIncome
            ? dollarsToCents(otherIndividualIncome)
            : null,
          user_id: fp?.userId ?? null,
        })
        goToNextStep(res)
      }
    },
  })

  const { values, validateForm, submitForm, isSubmitting, isValid } = formik

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Text as="display2" textAlign="center">
            {isScorp
              ? 'Do you earn 1099 income?'
              : 'Do you earn additional income?'}
          </Text>
        </GridRowColumn>

        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Text as="bodyLg" textAlign="center">
            Therapists typically earn additional 1099 income through jobs like
            supervision, consulting, speaking, etc.
            {!isScorp && (
              <>
                {' '}
                This income is earned outside of your private practice and{' '}
                <b>isn’t already recorded in Heard.</b>
              </>
            )}
          </Text>
        </GridRowColumn>

        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        {/*since this question has a non standard label this is only used for error state*/}
        <GridRowColumn short>
          <FormikLabelError
            name={getFieldName<typeof values>('hasOtherIncome')}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={3} only="tablet computer" />
          <Grid.Column computer={5} tablet={5} mobile={16}>
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('hasOtherIncome')}
              value
              fullWidth
              onClick={validateForm}
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column computer={5} tablet={5} mobile={16}>
            <FormikRadioToggleButton
              name={getFieldName<typeof values>('hasOtherIncome')}
              value={false}
              fullWidth
              onClick={validateForm}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>

        {values.hasOtherIncome && (
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <FormikInput
              name={getFieldName<typeof values>('otherIndividualIncome')}
              required
              fullWidth
              componentType="currency"
              schema={makeNumberSchema({
                allowedDecimals: 2,
                greaterThanZero: true,
              })}
              label="Year-to-date 1099 income from other jobs"
              description={
                <>
                  This is money earned this year through contractor positions,
                  short-term gigs, or other services.
                  {!isScorp && (
                    <>
                      {' '}
                      Only include 1099 income that <b>isn’t in Heard.</b>
                    </>
                  )}
                </>
              }
            />
          </GridRowColumn>
        )}

        <Grid.Row />
        <Grid.Row />
        <TaxProfileAccordions
          faqs={[faqCopy.needToInclude1099Job, faqCopy.howFind1099]}
        />

        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default Ten99Income
