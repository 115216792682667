import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../styles/theme'

const LightBgColorMap = {
  neutral: Colors.stone,
  tertiary: Colors.sunrise,
  blue: Colors.lightBlue,
  navy: Colors.lightNavy,
  purple: Colors.lightPurple,
  red: Colors.lightRed,
  magenta: Colors.lightMagenta,
  orange: Colors.lightOrange,
  yellow: Colors.lightYellow,
  green: Colors.lightGreen,
  teal: Colors.lightTeal,
  oak: Colors.white,
}

export interface PillProps {
  children?: ReactNode
  color?: keyof typeof LightBgColorMap
  style?: CSSProperties
  variant?: 'solid' | 'light'
}

const getBackgroundColor = ({
  color = 'neutral',
  variant = 'solid',
}: PillProps) => {
  if (variant === 'solid') {
    if (color === 'neutral') {
      return Colors.moss
    } else if (color === 'green') {
      return Colors.accentGreen
    } else if (color === 'tertiary') {
      return Colors.sunrise
    } else {
      return Colors[color]
    }
  } else {
    return LightBgColorMap[color]
  }
}

const getTextColor = ({ color = 'neutral', variant = 'solid' }: PillProps) => {
  if (variant === 'solid') {
    if (color === 'neutral') {
      return Colors.forest
    } else if (color === 'tertiary') {
      return Colors.black
    } else {
      return Colors.white
    }
  } else {
    if (color === 'neutral') {
      return Colors.forest
    } else if (color === 'tertiary') {
      return Colors.black
    }
    return Colors[color]
  }
}

const StyledPill = styled.div<PillProps>(
  ({ color = 'neutral', variant = 'solid', style }) => ({
    alignItems: 'center',
    backgroundColor: getBackgroundColor({ variant, color }),
    borderRadius: 100,
    color: getTextColor({ variant, color }),
    display: 'flex',
    padding: '4px 8px',
    width: 'fit-content',
    ...Fonts.eyebrow,
    ...style,
  })
)

const Pill = ({ children, ...rest }: PillProps) => (
  <StyledPill {...rest}>{children ?? ''}</StyledPill>
)

export default Pill
