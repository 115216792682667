import axios from 'axios'
import { TransactionRuleCategoryType } from '../../../reducers/admin/transactionRulesReducer'

export interface PotentialRules {
  count: number
  page: number
  ruleSuggestions: RuleSuggestion[]
}

export interface RuleSuggestion {
  ruleText: string
  ruleType: string
  type: TransactionRuleCategoryType
  transactionCategoryId: number
}
export const listSmartRules = async (userId: number) => {
  try {
    const response = await axios.get<PotentialRules>(
      `/finances/api/v1/admin/transactions/smart_rules/${userId}`
    )

    return response.data.ruleSuggestions
  } catch (err) {
    return false
  }
}

export const listLastRanOnDate = async (userId: number) => {
  try {
    const response = await axios.get<{ smartRuleLastRan: string }>(
      `/finances/api/v1/admin/transactions/smart_rules/${userId}/last_ran`
    )

    return response.data
  } catch (err) {
    return false
  }
}
