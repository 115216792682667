import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { uniqBy } from 'lodash'

import {
  fetchInvoices,
  InvoiceResponse,
} from '../../../reducers/subscription.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Button, GridRowColumn, Link, Text } from '../../BaseComponents'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../utils/currencyHelpers'

const InvoiceList = () => {
  const dispatch = useAppDispatch()
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([])
  const [hasMore, setHasMore] = useState(false)
  const lastInvoiceId = invoices[0]?.id

  const fetchInvoicePage = useCallback(
    async (startingAfterInvoiceId?: string) => {
      const res = await dispatch(
        fetchInvoices({ starting_after_invoice_id: startingAfterInvoiceId })
      )

      if (res) {
        setInvoices((prevInvoices) =>
          uniqBy([...prevInvoices, ...res.data], 'id')
        )
        setHasMore(res.has_more)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    fetchInvoicePage()
  }, [fetchInvoicePage])

  return (
    <>
      <GridRowColumn>
        <Text as="h3">Invoices</Text>
      </GridRowColumn>
      {invoices.map((invoice) => (
        <Grid.Row key={invoice.id} className="short">
          <Grid.Column computer={4} tablet={6} mobile={8}>
            <Link href={invoice.hosted_invoice_url || undefined}>
              {DateTime.fromSeconds(invoice.created).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_LONG
              )}
            </Link>
          </Grid.Column>
          <Grid.Column computer={4} tablet={6} mobile={8}>
            <Text>
              <CurrencyFormatLabel
                value={centsToDollars(invoice.amount_paid)}
              />
            </Text>
          </Grid.Column>
        </Grid.Row>
      ))}
      {hasMore && (
        <GridRowColumn short>
          <Button
            onClick={() => fetchInvoicePage(lastInvoiceId)}
            variant="secondaryLink"
          >
            Show More
          </Button>
        </GridRowColumn>
      )}
    </>
  )
}

export default InvoiceList
