import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QAUserDetails } from '../../features/Admin/QA/qaUsers.slice'
import {
  TAX_ENTITY_TYPES_TYPE,
  FILING_STATUSES,
} from '../../features/Taxes/taxConstants'
import { UserDocument } from '../../features/UserDocuments/userDocuments.slice'
import { RoleName } from '../admin/allRolesReducer'
import { BookkeepingReport } from '../finances/bookkeepingReportsReducer'
import { TosVersion } from '../../constants/businessConstants'

export type UserMembershipState =
  | 'onboarding'
  | 'active'
  | 'disqualified'
  | 'trial'
  | 'unpaid'
  | 'canceled'
  | 'reactivating'

export enum TrialStatus {
  active = 'active', // user has redeemed their free trial and it has not ended yet
  inactive = 'inactive', // user has redeemed their free trial and it has ended or been cancelled
  unredeemed = 'unredeemed', // user has not redeemed their free trial yet
}

export type MembershipStatus =
  | 'beta'
  | 'trial'
  | 'paid'
  | 'canceled'
  | 'cancelling'
  | 'inactive'
  | 'removed'
  | 'unpaid'
  | 'pending'

//TODO: switch types to use this enum instead of the type above
export enum MEMBERSHIP_STATUS {
  beta = 'beta',
  trial = 'trial',
  paid = 'paid',
  canceled = 'canceled',
  cancelling = 'cancelling',
  inactive = 'inactive',
  removed = 'removed',
  unpaid = 'unpaid',
  pending = 'pending',
}

export enum SCorpRequestStatus {
  INTERESTED = 'interested',
  NOT_INTERESTED = 'not_interested',
  NOT_ELIGIBLE = 'not_eligible',
}

export interface FinancialProfile {
  id: number
  userId: number
  businessEntity?: string | null
  businessName?: string | null
  businessAddress?: string | null
  filingStates?: string[]
  fileQuarterlyTaxes?: boolean | null
  fileAnnualTaxes?: boolean | null
  numberClients?: string | null
  sessionFee?: string | null
  certification?: string | null
  practiceType?: string | null
  signatures?: null
  numberOfDependents?: number | null
  personalExemption?: string | null
  itemizedDeduction?: boolean | null
  taxProfileLastReviewed?: string | null
  qteWizardLastStartedAt?: string | null
  spouseFirstName?: string | null
  spouseLastName?: string | null
  spouseDateOfBirth?: string | null
  spouseEmailAddress?: string | null
  spousePhoneNumber?: string | null
  spouseOccupation?: string | null
  federalTaxRate?: number | null
  stateTaxRate?: number | null
  effectiveTaxRate?: number | null
  effectiveTaxRateLastUpdatedOn?: string | null
  lastStatementAvailableDay?: number | null
  bankAccountReadAccess?: boolean | null
  preventMonthlyStatementNotifications?: boolean | null
  accountsSeparate?: boolean | null
  willingAccountsSeparate?: boolean | null
  fileInternationalTaxes?: boolean | null
  yearPracticeStarted?: string | null
  scorpStatus?: string | null
  outstandingTaxReturns?: boolean | null
  approximateIncome?: string | null
  practiceSize?: string | null
  einNumber?: string | null
  homeState?: string | null
  filingStatus?: FILING_STATUSES | null
  spousePaystubDocumentId?: number | null
  spousePaystub?: UserDocument
  relocatedThisQuarter?: boolean | null
  relocatedPreviousState?: string | null
  relocatedPreviousStateAt?: string | null
  sawMultistateClientsThisQuarter?: boolean | null
  percentageIncomePerState?: PercentageIncomePerState[] | null
  taxEntityType?: TAX_ENTITY_TYPES_TYPE | null
  taxProfileVersion?: string | null
  taxProfileLockedAt?: string | null
  wIncomeInCents: number | null
  estimatedAnnualW2IncomeInCents: number | null
  wIncome: string | null
  federalWithholding: string | null
  stateWithholding: string | null
  deduction: string | null
  spouseFederalWithholding: string | null
  spouseStateWithholding: string | null
  smartRuleLastRan: string | null
  federalWithholdingInCents: number | null
  stateWithholdingInCents: number | null
  deductionInCents: number | null
  spouseWIncomeInCents: number | null
  estimatedAnnualSpouseW2IncomeInCents: number | null
  spouseFederalWithholdingInCents: number | null
  spouseStateWithholdingInCents: number | null
  otherIndividualIncomeInCents: number | null
  spouseIndividualIncomeInCents: number | null
  entityChangeElectionDate: string | null
  scorpRegistrationState: string | null
  scorpPracticeWIncomeInCents: number | null
  estimatedAnnualScorpPracticeW2IncomeInCents: number | null
  createdAt: string
  updatedAt: string
  reports?: BookkeepingReport[]
  scorpRequestStatus?: SCorpRequestStatus | null
  scorpRequestSubmittedAt?: string | null
  hasIncompatibleAccounts?: boolean | null
  limitedBankAccessInterest?: boolean | null
}

export interface PercentageIncomePerState {
  state?: string
  value?: string
}

// There is some partial user information for owner and bookkeeper
export interface PartialAdmin {
  id: number
  firstName: string
  lastName: string
  admin: true
  uuid: string
  roles: UserRoleState[]
}

export interface UserRole {
  avatarUrl: string | null
  checkinUrl: string | null
  isDefaultBookkeeper: boolean | null
  isDefaultManager: boolean | null
  roleId: number
  userId: number
  createdAt: string | null
  updatedAt: string | null
}

export interface UserRoleState {
  id: number
  name: RoleName
  UserRole: UserRole
}

/**
 * Considering the idea of payroll "states"
 * 1. payroll_eligible – user may express interest in our payroll product (via modal form)
 * 2. payroll_interested - user has expressed interest in our payroll product, but Heard hasn't enabled it yet
 *
 * https://linear.app/heard/issue/GROW-339/update-logic-for-displaying-payroll-upsell-card-left-nav-tab-welcome
 */
export enum PayrollState {
  payroll_eligible = 'payroll_eligible',
  payroll_interested = 'payroll_interested',
}

export enum PayrollSetup {
  other = 'other',
  gusto = 'gusto',
  heard = 'heard',
  none = 'none',
  needsPayroll = 'needs_payroll',
  unknown = 'unknown',
}

export enum AcquisitionSource {
  webSearch = 'web_search',
  onlineAds = 'online_ads',
  colleagueOrFriend = 'colleague_or_friend',
  socialMedia = 'social_media',
  events = 'events',
  onlineCommunities = 'online_communities',
  therapyPlatforms = 'therapy_platforms',
  mailer = 'mailer',
  other = 'other',
}

// This is a representation of a user in the database.  Depending on if it's an admin or user call, extra info may be applied
export interface User {
  id: number
  uuid: string
  firstName: string
  lastName: string
  email: string
  dateOfBirth?: string | null
  admin?: boolean
  slug?: string | null
  personalPhone?: string | null
  website?: string | null
  bio?: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  passwordResetToken?: string | null
  passwordLastResetAt?: string | null
  passwordResetExpiresAt?: string | null
  verificationToken?: string
  verificationTokenExpiresAt?: string
  verifiedAt?: string | null
  twoFactorAuthEnabled: boolean | null
  phoneNumber?: string | null
  personalEmail?: string | null
  profileId: number | null
  referrer: string | null
  gustoPayrollTosAcceptedAt: string | null
  ownerId: number
  taxPreparerId: number | null
  taxCoordinatorId: number | null
  bookkeeperId: number
  lastOnboardingScreen: string | null
  state: UserMembershipState
  homeAddress?: string | null
  hadConsult: boolean | null
  referralCode: string
  referralsJoined: number
  zendeskId: string
  onboardingMeetingAt: string
  isFinancesActive?: boolean
  roles?: UserRoleState[]
  financialProfile?: FinancialProfile
  booksLockedForUserYear: string | null
  booksLockedForAdminYear: string | null
  currentTosVersion: TosVersion
  tosVersion?: TosVersion
  tosAcceptedOn?: string | number
  smsLoginModalViewedAt?: string | null
  // Current user only
  owner?: PartialAdmin
  bookkeeper?: PartialAdmin
  taxPreparer?: PartialAdmin | null
  taxCoordinator?: PartialAdmin | null
  payrollEnabledAt: string | null
  payrollEnrollmentDeclinedAt: string | null
  payrollIsActive: boolean | null
  stripeCustomer?: string
  qaDetails?: QAUserDetails | null
  payrollSetup: PayrollSetup
  payrollProvider: string | null
  gustoPlan: string | null
  gustoOnboardedAt: string | null
  interestedInGepAt: string | null
  acquisitionSource: null | AcquisitionSource
  acquisitionSourceComment: string | null
  onboardingItemsCompletedAt: string | null
  gepRequestFormSubmittedAt: string | null
  memberships: Membership[] | null
  userCatchupBookkeepingLogs: CatchupBookkeepingLog[] | null
  mostRecentLoginAt?: string | null
}

export type BillingCycle = 'annually' | 'monthly'
export type MembershipType = 'finances'

export interface Membership {
  id: number
  membershipStatus?: MembershipStatus
  membershipType?: MembershipType
  billingCycle?: BillingCycle
  startDate?: string
  trialEndDate?: string
  trialStartDate?: string
  stripeSubscription?: string
  stripeCustomer?: string
  stripePlanDetails?: object
  stripeSubscriptionDetails?: object
  isBeta?: boolean
  cancellationReason?: string
  cancellationComment?: string
  cancelledAt?: string
  updatedAt?: string
  isPrimary: boolean
  manualCancellationRequestedAt?: string
}

export interface CatchupBookkeepingLog {
  authorId: number
  userId: number
  value: string | null
  createdAt: string
  updatedAt: string
}

// This is helpful for typing components that are shared between admin all users and an auth user
export type BaseUser = Omit<User, 'financialProfile'>

export type UserState = Partial<User>

const initialState: UserState = {}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Misc calls return different information (ie /session and /users) so never set user, just update state
    updateCurrentUser: (state, action: PayloadAction<Partial<User>>) => ({
      ...state,
      ...action.payload,
    }),
    receiveFinancialProfile: (
      state,
      action: PayloadAction<FinancialProfile>
    ) => {
      state.financialProfile = action.payload
    },
  },
})

export default userSlice.reducer

export const { updateCurrentUser, receiveFinancialProfile } = userSlice.actions
