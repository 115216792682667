import { useSearchParams } from 'react-router-dom'
import { Container, Grid, Image } from 'semantic-ui-react'

import SignupHeader from '../SignupHeader'
import { Button, Card, Link, Text } from '../../../components/BaseComponents'
import { filterNulls, useAppDispatch } from '../../../utils/typeHelpers'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { SIGNUP_PATHS } from '../helpers'
import { useAnalyticsView } from '../../Amplitude'
import { useEffect } from 'react'
import {
  ReferralStatus,
  updateUserReferralStatusIfExists,
} from '../../Referrals/referrals.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'

const DisqualifiedSignup = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)
  const { email } = currentUser || {}
  useSignupPageUpdate(SIGNUP_PATHS.disqualified)
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('sign up disqualifed')
  }, [pageView])

  useEffect(() => {
    if (email) {
      updateUserReferralStatusIfExists({
        recipientEmail: email,
        status: ReferralStatus.ineligible,
      })(dispatch)
    }
  }, [email, dispatch])

  const disqReasons = [
    searchParams.get('hasInternationalTaxes') === 'true'
      ? 'International taxes'
      : null,
    searchParams.get('multipleOwners') === 'true'
      ? 'Multiple practice owners'
      : null,
    searchParams.get('ccorp') === 'true' ? 'C-Corp tax entity' : null,
    searchParams.get('sharedAccounts') === 'true'
      ? 'Combined personal and business banking activity'
      : null,
  ]

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader currentStep={1} />
        <Grid.Row>
          <Grid.Column width={1} />

          <Grid.Column width={14}>
            <Card fullWidth backgroundColor="stone40">
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Image
                      src="https://heard-images.s3.us-west-1.amazonaws.com/assets/signup/sorry.png"
                      style={{ height: 201 }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Text as="display" textAlign="center">
                      Sorry!
                    </Text>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Text as="h3" textAlign="center">
                      Based on your answers, we can’t support your practice’s
                      needs:
                    </Text>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5} />
                  <Grid.Column width={11}>
                    {filterNulls(disqReasons).map((reason) => (
                      <Text as="bodyLg" key={reason}>
                        &bull; {reason}
                      </Text>
                    ))}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Text as="bodyLg" textAlign="center">
                      If your circumstances change in the future, please reach
                      out again. Good luck!
                    </Text>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6} />
          <Grid.Column width={4}>
            <Link href="https://www.joinheard.com">
              <Button
                variant="secondary"
                fullWidth
                id="btn-signup-disqualified"
              >
                Return to Site
              </Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default DisqualifiedSignup
