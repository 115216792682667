import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Icon,
  Label,
  Link,
  Text,
} from '../../../../../components/BaseComponents'
import { Colors, Fonts } from '../../../../../styles/theme'
import DeadlineLabel from '../DeadlineLabel'
import { DATE_FORMATS_LUXON } from '../../../../../utils/dateHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectActiveQTEDetailsAsDates } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { splitTaxYearQuarter } from '../../helpers'
import {
  selectAllQTEChecklistItemsCompletedOnDate,
  selectStatusForTaxEstimate,
  selectUserTaxEstimateById,
} from '../../userTaxEstimates.selector'
import { EstimateStatus } from '../../service'
import { LabelColorMap } from '../../../../../components/BaseComponents/Label'
import { selectIsSingleStateCalculationByTaxEstimateId } from '../../../../../selectors/userTaxCalculation.selectors'
import { WhatIsSafeHarborPopup } from '../CalculateSafeHarborFlow/FederalSafeHarborCard'

const HowMuchShouldIPayRow = ({
  readyForPayment,
}: {
  readyForPayment?: boolean
}) => (
  <GridRowColumn short>
    <Link
      href={
        readyForPayment
          ? 'https://support.joinheard.com/hc/en-us/articles/4416196188439-What-do-you-mean-by-estimate-Can-I-pay-more-or-less-than-the-estimate-you-gave-me'
          : 'https://support.joinheard.com/hc/en-us/articles/22050337435415-How-to-manually-calculate-quarterly-estimate-tax'
      }
      newPage
      as="secondaryLink"
      style={{ fontSize: 14 }}
    >
      How much should I pay?
    </Link>
  </GridRowColumn>
)

const LeftColumn = ({ estimateId }: { estimateId?: number }) => {
  const estimate = useReselector(selectUserTaxEstimateById, estimateId)
  const status = useReselector(selectStatusForTaxEstimate, estimateId)
  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)
  const checklistCompletedAt = useReselector(
    selectAllQTEChecklistItemsCompletedOnDate,
    activeQuarterDetails?.taxYear
  )

  const isSingleStateCalc = useReselector(
    selectIsSingleStateCalculationByTaxEstimateId,
    estimateId
  )
  const isFederal = estimate?.type === 'federal'
  const { quarter } = splitTaxYearQuarter(estimate?.taxQuarter)

  const showQ3CaliforniaExplainer =
    quarter === '3' && estimate?.filingState === 'CA'

  const howWasThisCalculatedRow = useMemo(
    () =>
      // Don't show this if the new breakdown will show
      !isFederal || !isSingleStateCalc ? (
        <GridRowColumn short>
          <Link
            to={`/taxes/quarterly/${
              estimate?.taxQuarter
            }/estimate-breakdown?section=${isFederal ? 'federal' : 'state'}`}
            style={{ fontSize: 14 }}
          >
            {isFederal
              ? 'How was this calculated?'
              : 'What does Heard include in state estimate calculations?'}
          </Link>
        </GridRowColumn>
      ) : null,
    [estimate?.taxQuarter, isFederal, isSingleStateCalc]
  )

  const deadlineLabelText = useMemo(() => {
    if (status !== EstimateStatus.awaitingEstimate || !activeQuarterDetails) {
      return ''
    }
    if (checklistCompletedAt) {
      const { withOffset, forDisplay } = activeQuarterDetails
      if (checklistCompletedAt < withOffset.initialCalculationLock) {
        return `Estimate ready by ${forDisplay.clientNotified.toFormat(
          DATE_FORMATS_LUXON.MONTH_DAY_LONG
        )}`
      } else if (checklistCompletedAt < withOffset.clientNotified) {
        return `Estimate ready after ${forDisplay.clientNotified.toFormat(
          DATE_FORMATS_LUXON.MONTH_DAY_LONG
        )}`
      } else if (checklistCompletedAt < withOffset.aFewDaysBeforePaymentDue) {
        return `Estimate ready before ${forDisplay.irsPaymentDue.toFormat(
          DATE_FORMATS_LUXON.MONTH_DAY_LONG
        )}`
      } else {
        return `Estimate ready after ${forDisplay.irsPaymentDue.toFormat(
          DATE_FORMATS_LUXON.MONTH_DAY_LONG
        )}`
      }
    }
    return 'Complete your checklist to receive your estimate'
  }, [activeQuarterDetails, checklistCompletedAt, status])

  const label = useMemo(() => {
    let color: keyof typeof LabelColorMap
    let text

    switch (status) {
      case EstimateStatus.upcoming:
        color = 'neutral'
        text = 'Upcoming'
        break
      case EstimateStatus.unpaidWithoutResponse:
        color = 'red'
        text = 'Not Updated'
        break
      case EstimateStatus.awaitingEstimate:
        color = 'yellow'
        text = 'Awaiting Estimate'
        break
      case EstimateStatus.paid:
        color = 'green'
        text = 'Paid'
        break
      case EstimateStatus.readyForPayment:
        color = 'orange'
        text = 'Ready for Payment'
        break
      case EstimateStatus.unpaidWithResponse:
        color = 'neutral'
        text = 'Unpaid'
        break
      case EstimateStatus.noIncomeTax:
      case EstimateStatus.estimateIsZero:
      case EstimateStatus.joinedTooLate:
        return null

      default:
        return status satisfies never
    }

    return <Label color={color}>{text}</Label>
  }, [status])

  const content = useMemo(() => {
    if (estimate?.safeHarborType) {
      return (
        <GridRowColumn short>
          <WhatIsSafeHarborPopup />
        </GridRowColumn>
      )
    }

    switch (status) {
      case EstimateStatus.awaitingEstimate:
        return (
          <GridRowColumn short>
            <DeadlineLabel
              text={deadlineLabelText}
              prominentText={null}
              style={{
                color: Colors.black,
              }}
            />
          </GridRowColumn>
        )
      case EstimateStatus.readyForPayment:
        return (
          <>
            <HowMuchShouldIPayRow readyForPayment />
            {howWasThisCalculatedRow}
          </>
        )

      case EstimateStatus.noIncomeTax:
        return (
          <GridRowColumn short>
            <Link
              style={{ fontSize: 14 }}
              href="https://support.joinheard.com/hc/en-us/articles/4416208081943-Why-don-t-certain-states-have-to-submit-state-quarterly-tax-payments-Why-not-"
              newPage
              as="secondaryLink"
            >
              Why don&apos;t I owe taxes?
            </Link>
          </GridRowColumn>
        )
      case EstimateStatus.estimateIsZero: {
        if (showQ3CaliforniaExplainer) {
          return (
            <>
              <GridRowColumn short>
                <Text as="bodySm">
                  <b>You&apos;re all set! No action is needed.</b>
                  <br />
                  Nothing is due for Q3 in CA. Please note that the percent per
                  quarter for CA differs from federal.
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Link
                  style={{ display: 'block', fontSize: 14 }}
                  href="https://www.ftb.ca.gov/about-ftb/newsroom/tax-news/august-2018/third-quarter-estimated-tax-due.html"
                  newPage
                  as="secondaryLink"
                >
                  Learn more on CA.gov
                  <Icon
                    style={{ marginLeft: 6 }}
                    icon={regular('square-arrow-up-right')}
                  />
                </Link>
              </GridRowColumn>
              {howWasThisCalculatedRow}
            </>
          )
        }

        return (
          <>
            <GridRowColumn short>
              <Text as="bodySm">
                <b>You&apos;re all set! No action is needed.</b>
              </Text>
            </GridRowColumn>

            <GridRowColumn short>
              <Link
                style={{ fontSize: 14 }}
                href="https://support.joinheard.com/hc/en-us/articles/4416208000791-Why-is-my-quarterly-tax-estimate-0-"
                newPage
                as="secondaryLink"
              >
                Why don&apos;t I owe anything?
              </Link>
            </GridRowColumn>
            {howWasThisCalculatedRow}
          </>
        )
      }

      case EstimateStatus.unpaidWithResponse:
        return (
          <>
            <GridRowColumn short>
              <Text as="bodySm">
                You&apos;ve indicated that you didn&apos;t pay taxes this
                quarter.
              </Text>
            </GridRowColumn>
            {howWasThisCalculatedRow}
          </>
        )

      case EstimateStatus.unpaidWithoutResponse:
        return (
          <>
            <GridRowColumn short>
              <Text as="bodySm">
                <b>
                  Please update your payment, even if you didn&apos;t make any
                  payments in Q{quarter}.
                </b>
                <br />
                We need this to provide accurate estimates for the next quarter.
              </Text>
            </GridRowColumn>
            <HowMuchShouldIPayRow />
            {howWasThisCalculatedRow}
          </>
        )
      case EstimateStatus.joinedTooLate:
        return (
          <GridRowColumn short>
            <Text as="bodySm">
              You joined Heard after this quarter&apos;s cut-off date, so
              we&apos;re unable to provide you with an exact estimate.
            </Text>
          </GridRowColumn>
        )
      case EstimateStatus.paid:
        return howWasThisCalculatedRow
      case EstimateStatus.upcoming:
        return null
      default:
        return status satisfies never
    }
  }, [
    deadlineLabelText,
    estimate?.safeHarborType,
    howWasThisCalculatedRow,
    quarter,
    showQ3CaliforniaExplainer,
    status,
  ])

  const titleText = useMemo(() => {
    if (estimate?.safeHarborType) {
      return 'Federal Safe Harbor'
    } else if (isFederal) {
      return 'Federal'
    }
    return `State, ${estimate?.filingState}`
  }, [estimate?.filingState, estimate?.safeHarborType, isFederal])

  return (
    <Grid.Column computer={12} tablet={12} mobile={16}>
      <Grid>
        <GridRowColumn
          columnStyle={{ display: 'flex', alignItems: 'center' }}
          short
        >
          <Icon
            style={{
              fontSize: Fonts.bodyMd.fontSize,
              padding: 5,
              borderRadius: 4,
              width: 18,
              height: 18,
              color: Colors.white,
              backgroundColor:
                status === EstimateStatus.unpaidWithoutResponse
                  ? Colors.red
                  : isFederal
                    ? Colors.green
                    : Colors.black,
            }}
            icon={isFederal ? regular('landmark') : regular('home')}
          />

          {/* Federal or State text */}
          <Text
            style={{ margin: '0 10px' }}
            color={
              status === EstimateStatus.unpaidWithoutResponse ? 'red' : 'black'
            }
            as="h3"
          >
            {titleText}
          </Text>

          {label}
        </GridRowColumn>
        {content}
      </Grid>
    </Grid.Column>
  )
}

export default LeftColumn
