import {
  SplitTransaction,
  Transaction,
} from '../../../reducers/admin/allTransactions.slice'
import {
  selectFinancialAccountById,
  selectIsUserManualAccount,
} from '../../../selectors/financeSelectors'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import { Icon, Popup, Table, Text } from '../../../components/BaseComponents'
import { getLockedTransactionDescription } from '../copyConstants'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Colors } from '../../../styles/theme'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import EditableTransactionTypeLabel from '../../../components/shared/EditableTransactionTypeLabel'
import { centsToDollars } from '../../../utils/currencyHelpers'
import TransactionCategoryLabel from '../../../components/shared/TransactionCategoryLabel'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import Moment from 'react-moment'
import { formatAccountName } from '../helpers'
import { ReactNode } from 'react'
import {
  selectIsLockedTransaction,
  selectIsSplitTransaction,
} from '../transactions.selectors'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import TransactionDetailsMenu from './TransactionDetailsMenu'

const CellContent = ({
  locked,
  children,
}: {
  locked: boolean
  children: ReactNode
}) => {
  if (locked) {
    return (
      <Popup
        content={
          getLockedTransactionDescription().transactionRowLockIconTooltip
        }
        trigger={<div style={{ pointerEvents: 'auto' }}>{children}</div>}
      />
    )
  } else {
    return children
  }
}

interface SplitTransactionRowProps {
  splitTransaction: SplitTransaction
  locked: boolean
  isLastSplit: boolean
  review?: boolean
  parentId: number
  isManualTransaction: boolean
}

const SplitTransactionRow = ({
  splitTransaction,
  locked,
  isLastSplit,
  review,
  parentId,
  isManualTransaction,
}: SplitTransactionRowProps) => {
  const [fileModalOpen, toggleFileModal] = useToggle(false)

  return (
    <>
      <Table.Row
        disabled={locked}
        className="expandable-transaction-row"
        style={locked ? { backgroundColor: Colors.stone } : undefined}
      >
        {/* DATE */}
        <Table.Cell className={isLastSplit ? undefined : 'no-border'} />
        {/* DESCRIPTION */}
        <Table.Cell className={isLastSplit ? undefined : 'no-border'} />
        {/* AMOUNT */}
        <Table.Cell className="border-top" textAlign="left">
          <CellContent locked={locked}>
            <span
              className={
                splitTransaction.splitTransactions && isLastSplit
                  ? 'half-height'
                  : ''
              }
            />
            <CurrencyFormatLabel
              value={centsToDollars(splitTransaction?.amountInCents)}
            />
          </CellContent>
        </Table.Cell>
        {/* TYPE */}
        <Table.Cell className="border-top" textAlign="center">
          <CellContent locked={locked}>
            <EditableTransactionTypeLabel
              transaction={splitTransaction}
              review={review}
            />
          </CellContent>
        </Table.Cell>
        <Table.Cell className="border-top">
          {/* CATEGORY */}
          <CellContent locked={locked}>
            <TransactionCategoryLabel
              variant="text"
              editable
              transaction={splitTransaction}
              review={review}
              filtered
              useRecategorizationModal
            />
          </CellContent>
        </Table.Cell>
        {/* ACCOUNT */}
        <Table.Cell className="border-top" />
        {/* ICON */}
        <Table.Cell className={isLastSplit ? undefined : 'border-top'} />
        {/* ACTION MENU */}
        <Table.Cell>
          {!locked && (
            <TransactionDetailsMenu
              transaction={splitTransaction}
              review={review}
              toggleFileModal={toggleFileModal}
              isManualTransaction={isManualTransaction}
            />
          )}
        </Table.Cell>
      </Table.Row>
      <FileUploadModal
        open={fileModalOpen}
        close={toggleFileModal}
        documentType={UploadDocumentType.RECEIPT}
        userFacing
        extraPayload={{ transactionId: Number(parentId) }}
      />
    </>
  )
}

interface DisplayRowIconProps {
  isLocked: boolean
  isAutoCategorized: boolean
  isManualTransaction: boolean
}

export const DisplayRowIcon = ({
  isLocked,
  isAutoCategorized,
  isManualTransaction,
}: DisplayRowIconProps) => {
  if (isLocked) {
    return <Icon icon={regular('lock')} color="darkGray" size="lg" />
  } else if (isAutoCategorized) {
    return (
      <Popup
        content="This transaction has been auto-categorized."
        trigger={
          <Icon
            icon={regular('bolt-auto')}
            color="darkGray"
            style={{ pointerEvents: 'auto' }}
          />
        }
      />
    )
  } else if (isManualTransaction) {
    return <Icon color="green" icon={regular('pencil')} />
  } else {
    return null
  }
}

interface TransactionsTableRowProps {
  transaction: Transaction
  review?: boolean
}

const TransactionsTableRow = ({
  transaction,
  review,
}: TransactionsTableRowProps) => {
  const {
    id,
    splitTransactions,
    amountInCents,
    requestedClarificationAt,
    date,
    description,
    nickname,
    autocategorizedAt,
    financialAccountId,
  } = transaction
  const isSplitTransaction = useReselector(
    selectIsSplitTransaction,
    transaction.id
  )
  const account = useReselector(
    selectFinancialAccountById,
    Number(financialAccountId)
  )
  const locked = useReselector(
    selectIsLockedTransaction,
    transaction.splitFrom ?? transaction.id
  )

  const isManualTransaction = useReselector(
    selectIsUserManualAccount,
    financialAccountId
  )

  const [fileModalOpen, toggleFileModal] = useToggle(false)

  return (
    <>
      <Table.Row
        className="userTransactionRow"
        style={locked ? { backgroundColor: Colors.stone } : {}}
        negative={Boolean(requestedClarificationAt)}
        disabled={locked}
      >
        {/* DATE */}
        <Table.Cell>
          <CellContent locked={locked}>
            <Moment format={DATE_FORMATS.DISPLAY_SHORT} utc>
              {date}
            </Moment>
          </CellContent>
        </Table.Cell>
        {/* DESCRIPTION */}
        <Table.Cell>
          <CellContent locked={locked}>
            <Text as="buttonMd">
              <i>{nickname}</i>
            </Text>
            <Text>{description}</Text>
          </CellContent>
        </Table.Cell>
        {/* AMOUNT */}
        <Table.Cell
          textAlign="left"
          color={amountInCents > 0 ? 'green' : undefined}
        >
          <CellContent locked={locked}>
            {isSplitTransaction && (
              <s>
                <CurrencyFormatLabel value={centsToDollars(amountInCents)} />
              </s>
            )}
            {!isSplitTransaction && (
              <CurrencyFormatLabel value={centsToDollars(amountInCents)} />
            )}
          </CellContent>
        </Table.Cell>
        {/* TYPE */}
        <Table.Cell className="transaction-type" textAlign="center">
          <CellContent locked={locked}>
            {!isSplitTransaction && (
              <EditableTransactionTypeLabel
                transaction={transaction}
                review={review}
              />
            )}
            {isSplitTransaction && (
              <Text color="darkGray">Split Transaction</Text>
            )}
          </CellContent>
        </Table.Cell>
        {/* CATEGORY */}
        <Table.Cell>
          <CellContent locked={locked}>
            {!isSplitTransaction && (
              <TransactionCategoryLabel
                variant="text"
                editable
                transaction={transaction}
                review={review}
                filtered
                useRecategorizationModal
              />
            )}
          </CellContent>
        </Table.Cell>
        {/* ACCOUNT */}
        <Table.Cell>
          <CellContent locked={locked}>
            {account?.plaidInstitutionName && (
              <Text as="bodySm" color="darkGray">
                {account.plaidInstitutionName}
              </Text>
            )}
            {formatAccountName(account)}
          </CellContent>
        </Table.Cell>
        {/* ICON */}
        <Table.Cell>
          <CellContent locked={locked}>
            <DisplayRowIcon
              isLocked={locked}
              isAutoCategorized={Boolean(autocategorizedAt)}
              isManualTransaction={isManualTransaction}
            />
          </CellContent>
        </Table.Cell>
        {/* ACTION MENU */}
        <Table.Cell>
          <CellContent locked={locked}>
            {!isSplitTransaction && !locked && (
              <TransactionDetailsMenu
                transaction={transaction}
                review={review}
                toggleFileModal={toggleFileModal}
                isManualTransaction={isManualTransaction}
              />
            )}
          </CellContent>
        </Table.Cell>
      </Table.Row>
      {isSplitTransaction &&
        splitTransactions?.map((splitT, index) => (
          <SplitTransactionRow
            key={splitT.id}
            splitTransaction={splitT}
            locked={locked}
            review={review}
            isLastSplit={index === splitTransactions.length - 1}
            parentId={id}
            isManualTransaction={isManualTransaction}
          />
        ))}
      <FileUploadModal
        open={fileModalOpen}
        close={toggleFileModal}
        documentType={UploadDocumentType.RECEIPT}
        userFacing
        extraPayload={{ transactionId: Number(transaction.id) }}
      />
    </>
  )
}

export default TransactionsTableRow
