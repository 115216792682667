import { useCallback, useMemo, useState } from 'react'
import { Button, Divider, Form, Icon, Step } from 'semantic-ui-react'

// BL
import {
  SplitTransactionModel,
  Transaction,
} from '../../../../reducers/admin/allTransactions.slice'
import {
  formatCurrency,
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import { adminSplitTransaction } from '../../../../actions/adminActions'

// UI
import OriginalTransactionRow from './OriginalTransactionRow'
import SplitTransactionRow from './SplitTransactionRow'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectTransactionCategoriesAsDropdownOptions } from '../../../../features/Reports/reports.selectors'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const STEP_GROUP_STYLE = {
  border: 'none',
  marginTop: '0px',
}

const MAX_NUMBER_ROWS = 10

interface Props {
  onCancel: () => void
  onSaveSuccess: (updatedTransaction?: Transaction) => void
  transaction: Transaction
}

interface FormFields {
  [key: string]: string
}

export interface RowModel {
  deletable: boolean
  fields: FormFields
}

const requiredRows: RowModel[] = [
  {
    deletable: false,
    fields: {
      amount: '',
      categoryId: '',
      expenseType: 'business',
    },
  },
  {
    deletable: false,
    fields: {
      amount: '',
      categoryId: '',
      expenseType: 'business',
    },
  },
]

const SplitTransactionForm = ({
  onCancel,
  onSaveSuccess,
  transaction,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState<RowModel[]>(requiredRows)
  const categoryOptions = useReselector(
    selectTransactionCategoriesAsDropdownOptions
  )
  const dispatch = useAppDispatch()

  const reset = () => setRows(requiredRows)

  const onCancelClicked = () => {
    reset()
    onCancel()
  }

  const allocationRemaining = useMemo(() => {
    // sum amount fields, return transaction amount - sum
    let sum = 0
    rows.forEach((row) => {
      sum += Number(row.fields['amount'])
    })

    sum = Number(sum.toFixed(2))
    const difference = centsToDollars(transaction.amountInCents) - sum

    let overallocated = false
    if (transaction.amountInCents > 0 && difference < 0) {
      overallocated = true
    }

    if (transaction.amountInCents < 0 && difference > 0) {
      overallocated = true
    }

    return overallocated ? 'Over-allocated!' : difference
  }, [rows, transaction.amountInCents])

  const performSave = useCallback(() => {
    try {
      const data: SplitTransactionModel[] = rows.map((row) => ({
        amountInCents: dollarsToCents(Number(row.fields['amount'])),
        type: row.fields['expenseType'],
        categoryId:
          row.fields['expenseType'] === 'personal'
            ? null
            : Number(row.fields['categoryId']),
      }))

      if (allocationRemaining !== 0) {
        data.push({
          amountInCents: dollarsToCents(Number(allocationRemaining)),
          type: 'personal',
          categoryId: null,
        })
      }

      return adminSplitTransaction(transaction.id, data)(dispatch)
    } catch (err) {
      alert(err)
      return null
    }
  }, [dispatch, transaction.id, allocationRemaining, rows])

  const onRowChange = (index: number, key: string, value: string) => {
    const newRowModel = { ...rows[index] }
    const newFields = { ...newRowModel.fields }
    newFields[key] = value
    newRowModel.fields = newFields

    const newRows = [...rows]
    newRows[index] = newRowModel
    setRows(newRows)
  }

  const isFormInvalid = useCallback(
    (alertErrors = false) => {
      /* 
      Invalid if 
        a) Rows are incomplete: row amounts === 0.00 || their categoryIds have been unset
        b) Conflicting signs: the sign (+ / -) of each row's amount does not match the original transaction.amountInCents' sign
        c) We've allocated too much
    */

      for (const [index, value] of rows.entries()) {
        const row = value
        const amount = Number(row.fields['amount'])

        // a
        if (amount === 0) {
          alertErrors &&
            alert(`Row ${index + 1} must have an amount unequal to $0.00`)
          return true
        }

        if (
          !row.fields['categoryId'] &&
          row.fields['expenseType'] !== 'personal'
        ) {
          alertErrors && alert(`Row ${index + 1} must have a category`)
          return true
        }

        // b
        if (
          (transaction.amountInCents < 0 && amount > 0) ||
          (transaction.amountInCents > 0 && amount < 0)
        ) {
          alertErrors &&
            alert(
              `Row ${
                index + 1
              } has a differing sign (+ / -) than the original amount`
            )
          return true
        }
      }

      // c
      if (isNaN(Number(allocationRemaining))) {
        alertErrors &&
          alert(
            "We've over-allocated! Please review the rows and adjust their amounts accordingly"
          )
        return true
      }

      return false
    },
    [rows, allocationRemaining, transaction.amountInCents]
  )

  const onSaveClicked = useCallback(async () => {
    if (isFormInvalid(true)) {
      return
    }

    if (allocationRemaining !== 0) {
      const message = `A portion of this transaction is still unallocated. It will be saved as a lump sum, personal expense in the amount of ${formatCurrency(
        allocationRemaining
      )}.
      
      Do you want to continue?`
      const result = confirm(message)

      if (!result) {
        return
      }
    }

    setLoading(true)
    const updatedTransaction = await performSave()
    reset()
    setLoading(false)
    onSaveSuccess(updatedTransaction ?? undefined)
  }, [
    setLoading,
    onSaveSuccess,
    allocationRemaining,
    isFormInvalid,
    performSave,
  ])

  const onRowDelete = (index: number) => {
    const newRows = [...rows]
    newRows.splice(index, 1)
    setRows(newRows)
  }

  const onRowAdd = () => {
    if (rows.length === MAX_NUMBER_ROWS) {
      alert(`The maximum number of rows we can add is ${MAX_NUMBER_ROWS}.`)
      return
    }
    const newRowModel = { ...requiredRows[0] }
    newRowModel.deletable = true
    newRowModel.fields = { ...requiredRows[0].fields }

    const newRows = [...rows]
    newRows.push(newRowModel)
    setRows(newRows)
  }

  return (
    <Form loading={loading}>
      <Step.Group widths={3} style={STEP_GROUP_STYLE}>
        {/* original transaction */}
        <OriginalTransactionRow
          allocationRemaining={allocationRemaining}
          transaction={transaction}
        />

        {/* split transactions */}
        <Step.Group vertical fluid style={STEP_GROUP_STYLE}>
          {' '}
          {rows.map((model: RowModel, index: number) => (
            <SplitTransactionRow
              categoryOptions={categoryOptions}
              index={index}
              key={index.toString()}
              model={model}
              onRowChange={onRowChange}
              onRowDelete={onRowDelete}
            />
          ))}
        </Step.Group>
      </Step.Group>

      {/* toolbars */}
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onRowAdd} basic>
          <Icon name="add" />
          Add another business expense
        </Button>
      </div>
      <Divider />
      <div style={{ float: 'right', padding: '0px 0px 20px 0px' }}>
        <Button secondary onClick={onCancelClicked}>
          Cancel
        </Button>
        <Button primary onClick={onSaveClicked}>
          Save
        </Button>
      </div>
    </Form>
  )
}

export default SplitTransactionForm
