import { Divider, List, Table } from 'semantic-ui-react'

import CurrencyFormatLabel from '../../../../components/shared/CurrencyFormatLabel'
import AnnualizedAmountsPopup from './AnnualizedAmountsPopup'
import CalculationListItem from './CalculationListItem'
import SummedAmountPopup from './SummedAmountPopup'
import {
  centsToCurrency,
  centsToDollars,
} from '../../../../utils/currencyHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectUsesYTDW2Input,
  selectIsSingleStateCalculation,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import QtePaymentsPopup from './QtePaymentsPopup'
import { Text } from '../../../../components/BaseComponents'

const CalculationInputsCell = ({
  id,
  bookkeepingPeriod,
}: {
  id: number
  bookkeepingPeriod: string
}) => {
  const calc = useReselector(selectUserTaxCalculationById, id)
  const isSingleStateCalc = useReselector(selectIsSingleStateCalculation, id)
  const isAfter2023Q1 = useReselector(selectUsesYTDW2Input, id)

  if (!calc) {
    return null
  }

  const {
    annualizedIncomeInCents,
    periodIncomeInCents,
    otherIndividualIncomeInCents,
    spouseIndividualIncomeInCents,
    annualizedExpensesInCents,
    periodExpensesInCents,
    totalWIncomeInCents,
    wIncomeInCents,
    spouseWIncomeInCents,
    scorpPracticeWIncomeInCents,
    annualizedTotalWIncomeInCents,
    filingStates,
    filingStatus,
    taxEntityType,
    stateWithholdingInCents,
    spouseStateWithholdingInCents,
    federalWithholdingInCents,
    spouseFederalWithholdingInCents,
    estimatedAnnualW2IncomeInCents,
    estimatedAnnualSpouseW2IncomeInCents,
    estimatedAnnualScorpPracticeW2IncomeInCents,
  } = calc

  const totalOther1099IncomeInCents = centsToCurrency(
    otherIndividualIncomeInCents || 0
  ).add(spouseIndividualIncomeInCents || 0).value

  const selfFedWithholding = centsToCurrency(federalWithholdingInCents)
  const spouseFedWithholding = centsToCurrency(spouseFederalWithholdingInCents)
  const selfStateWithholding = centsToCurrency(stateWithholdingInCents)
  const spouseStateWithholding = centsToCurrency(spouseStateWithholdingInCents)

  const totalFederalWithholding =
    spouseFedWithholding.add(selfFedWithholding).value
  const totalStateWithholding =
    spouseStateWithholding.add(selfStateWithholding).value

  return (
    <Table.Cell verticalAlign="top">
      <List>
        {isSingleStateCalc ? (
          <>
            <CalculationListItem
              header="Tax Entity Type"
              value={taxEntityType ?? 'N/A'}
            />
            <CalculationListItem header="Filing Status" value={filingStatus} />
            <CalculationListItem
              header="Filing State(s)"
              value={filingStates.join(', ')}
            />

            <Divider inverted />

            <CalculationListItem
              header="1099 Practice Income"
              value={
                <CurrencyFormatLabel
                  value={centsToDollars(annualizedIncomeInCents)}
                />
              }
              tooltip={
                <AnnualizedAmountsPopup
                  periodTitle="1099 Income"
                  periodAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(periodIncomeInCents)}
                    />
                  }
                  periodDateString={bookkeepingPeriod}
                  annualizedAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(annualizedIncomeInCents)}
                    />
                  }
                />
              }
            />

            <CalculationListItem
              header="Expense Deductions"
              value={
                <CurrencyFormatLabel
                  value={centsToDollars(annualizedExpensesInCents)}
                />
              }
              tooltip={
                <AnnualizedAmountsPopup
                  periodTitle="Expense Deductions"
                  periodAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(periodExpensesInCents)}
                    />
                  }
                  periodDateString={bookkeepingPeriod}
                  annualizedAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(annualizedExpensesInCents)}
                    />
                  }
                />
              }
            />
            <CalculationListItem
              header={
                isAfter2023Q1 ? 'W2 Income YTD' : 'W2 Income (Expected Annual)'
              }
              value={
                <CurrencyFormatLabel
                  value={centsToDollars(totalWIncomeInCents)}
                />
              }
              tooltip={
                <>
                  <SummedAmountPopup
                    title="W2 Income YTD"
                    individualsAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(wIncomeInCents)}
                      />
                    }
                    spousesAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(spouseWIncomeInCents)}
                      />
                    }
                    anyOtherAmountTitle="S-corp Practice Income"
                    anyOtherAmount={
                      scorpPracticeWIncomeInCents ? (
                        <CurrencyFormatLabel
                          value={centsToDollars(scorpPracticeWIncomeInCents)}
                        />
                      ) : null
                    }
                    totalAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(totalWIncomeInCents)}
                      />
                    }
                  />
                  <Divider />
                  <br />
                  <SummedAmountPopup
                    title="W2 Estimated Income"
                    individualsAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(estimatedAnnualW2IncomeInCents)}
                      />
                    }
                    spousesAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(
                          estimatedAnnualSpouseW2IncomeInCents
                        )}
                      />
                    }
                    anyOtherAmountTitle="S-corp Estimated Practice Income"
                    anyOtherAmount={
                      estimatedAnnualScorpPracticeW2IncomeInCents ? (
                        <CurrencyFormatLabel
                          value={centsToDollars(
                            estimatedAnnualScorpPracticeW2IncomeInCents
                          )}
                        />
                      ) : null
                    }
                    totalAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(totalWIncomeInCents)}
                      />
                    }
                  />
                </>
              }
            />
            {isAfter2023Q1 && (
              <CalculationListItem
                header="W2 Income Annualized"
                value={
                  <CurrencyFormatLabel
                    value={centsToDollars(annualizedTotalWIncomeInCents)}
                  />
                }
                tooltip={
                  <AnnualizedAmountsPopup
                    periodTitle="W2 Income"
                    periodAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(totalWIncomeInCents)}
                      />
                    }
                    periodDateString={'YTD'}
                    annualizedAmount={
                      <CurrencyFormatLabel
                        value={centsToDollars(annualizedTotalWIncomeInCents)}
                      />
                    }
                  />
                }
              />
            )}
            <CalculationListItem
              header="YTD Other 1099 Income"
              value={
                <CurrencyFormatLabel value={totalOther1099IncomeInCents} />
              }
              tooltip={
                <SummedAmountPopup
                  title="Other 1099 Income"
                  individualsAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(otherIndividualIncomeInCents)}
                    />
                  }
                  spousesAmount={
                    <CurrencyFormatLabel
                      value={centsToDollars(spouseIndividualIncomeInCents)}
                    />
                  }
                  totalAmount={
                    <CurrencyFormatLabel value={totalOther1099IncomeInCents} />
                  }
                />
              }
            />
            <CalculationListItem
              header="YTD total federal withholding"
              value={<CurrencyFormatLabel value={totalFederalWithholding} />}
              tooltip={
                <>
                  <Text>
                    Individual Federal withholding{' '}
                    <CurrencyFormatLabel value={selfFedWithholding.value} />
                  </Text>
                  <Text>
                    Spouse Federal withholding{' '}
                    <CurrencyFormatLabel value={spouseFedWithholding.value} />
                  </Text>
                </>
              }
            />
            <CalculationListItem
              header="YTD total state withholding"
              value={<CurrencyFormatLabel value={totalStateWithholding} />}
              tooltip={
                <>
                  <Text>
                    Individual State withholding{' '}
                    <CurrencyFormatLabel value={selfStateWithholding.value} />
                  </Text>
                  <Text>
                    Spouse State withholding{' '}
                    <CurrencyFormatLabel value={spouseStateWithholding.value} />
                  </Text>
                </>
              }
            />
            <CalculationListItem
              header="QTE Payments"
              tooltip={<QtePaymentsPopup id={id} />}
            />
          </>
        ) : (
          <i>N/A (Multi-state filer)</i>
        )}
      </List>
    </Table.Cell>
  )
}

export default CalculationInputsCell
